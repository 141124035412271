<template>
  <div class="grid grid-cols-1 px-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">Особенности продуктов</h1>
          </div>
          <div class="flex-shrink-0">
            <a @click.prevent="createFeature" href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">&plus; Добавить</a>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input v-model="checkboxAllFeaturesSelected"
                               @click="selectAllCheckbox"
                               id="checkbox-all" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Иконка
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Название
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Описание (RUS)
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Описание (ENG)
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="feature of featureStore.features" :key="feature.id" :class="{'bg-neutral-100 opacity-50': feature.isDeleted}" class="hover:bg-gray-100">
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        <input v-model="featureIds" :value="feature.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>

                    <td class="p-4">
                      <img v-if="feature.icon" :src="feature.icon" width="74" alt="">
                    </td>
                    <td class="p-4">{{ feature.name }}</td>
                    <td class="p-4">{{ feature.description_ru }}</td>
                    <td class="p-4">{{ feature.description_en }}</td>

                    <td class="text-right">
                      <button @click="openDialog(feature.id)" type="button" class="py-2 px-3 text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !featureStore.features.length" class="text-xl text-center p-7">Не найдено особенностей</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between border-t">
          <div class="flex-col p-7 w-1/2">
            <template v-if="featureIds.length">
              <div>
                <button @click.prevent="deleteFeatures" v-if="!isRestoreFeatures" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>Удалить</span>
                </button>
                <button @click.prevent="restoreFeatures" v-if="isRestoreFeatures" class="flex px-6 py-4 bg-blue-500 rounded-md text-white hover:bg-blue-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>Восстановить</span>
                </button>
              </div>
            </template>
            <template v-else>
              <div v-if="featureStore.pagination">
                <span class="text-gray-500">Найдено</span> {{ featureStore.pagination.total }}
              </div>
            </template>
          </div>
        </div>

      </div>
    </div>
  </div>

  <modal v-if="showDialog && featureStore.feature" :id="featureStore.feature.id" @closeDialog="closeDialog" :load-data="loadDataInModal">
    <template #title>Редактировать особенность</template>
    <template #body>
      <spinner v-if="loadData"></spinner>
      <form v-if="!loadData && featureStore.feature" action="#">
        <input type="hidden" name="id" v-model="featureStore.feature.id">

        <div class="grid gap-4 mb-4 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Иконка</label>
            <template v-if="featureStore.feature.icon">
              <div class="relative inline-block bg-white p-4 mb-3">
                <img :src="featureStore.feature.icon" width="100" alt="">

                <button @click.prevent="deleteIcon" class="flex text-primary-500 absolute left-0 bottom-0 hover:bg-primary-700">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                </button>
              </div>
            </template>
            <drop-file v-model="featureStore.feature.iconNew" name="Загрузить иконку в формате SVG" hint="" accept=".svg"/>
          </div>
          <div class="sm:col-span-2">
            <input-text :v$="v$.name" v-model="featureStore.feature.name" label="Название"/>
          </div>
          <div class="sm:col-span-2">
            <input-text :v$="v$.description_ru" v-model="featureStore.feature.description_ru" label="Описание (RUS)"/>
          </div>
          <div class="sm:col-span-2">
            <input-text :v$="v$.description_en" v-model="featureStore.feature.description_en" label="Описание (ENG)"/>
          </div>
        </div>

        <div v-if="featureStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {{ featureStore.error }}
        </div>

        <div class="flex items-center space-x-4">
          <button @click.prevent="saveFeature" type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Сохранить
          </button>
          <button @click="closeDialog" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Отмена
          </button>
        </div>
      </form>
    </template>
  </modal>

</template>

<script setup>
import {useFeatureStore} from "../../stores/FeatureStore.js";
import Spinner from "../flowbite/spinner.vue";
import Modal from "../flowbite/modal.vue";
import {computed, reactive, ref, watch} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {required} from '../../utilits/custom-validators.js'
import DropFile from "../flowbite/form/dropFile.vue";

const featureStore = useFeatureStore();
const showDialog = ref(false);
const loadData = ref(true);
const loadDataInModal = ref(true);
const checkboxAllFeaturesSelected = ref(false);
const featureIds = ref([]);
const isRestoreFeatures = ref(false);

watch(featureIds, () => {
  isRestoreFeatures.value = false

  let ids = []

  featureIds.value.forEach((featureId) => {
    ids.push(featureId)
  })

  featureStore.features.forEach((feature) => {
    if (ids.indexOf(feature.id) !== -1 && feature.isDeleted) {
      isRestoreFeatures.value = true
    }
  })
})

//Первоначальные данные
featureStore.getFeatures().then(() => {
  loadData.value = false;
});

//Валидация
const rules = computed(() => ({
  name: {required},
  description_ru: {required},
  description_en: {required},
}));

let state = reactive({
  name: "",
  description_ru: "",
  description_en: "",
});

watch(featureStore, (featureStore) => {
  if (featureStore.feature) {
    state.name = featureStore.feature.name;
    state.description_ru = featureStore.feature.description_ru;
    state.description_en = featureStore.feature.description_en;
  }
});

const v$ = useVuelidate(rules, state)

//Модальное окно
const openDialog = (id) => {
  loadDataInModal.value = true;
  showDialog.value = true

  featureStore.getFeatureById(id)
      .then(() => {
        loadDataInModal.value = false;
      });
}

const closeDialog = () => {
  showDialog.value = false;
  v$.value.$reset();
  featureStore.resetFeature();
  featureStore.error = null;
}

//Галочка выбрать всё
const selectAllCheckbox = () => {
  featureIds.value = [];

  if (!checkboxAllFeaturesSelected.value) {
    featureStore.features.forEach((feature, index) => {
      featureIds.value.push(feature.id)
    });
  }
}

// Работа с данными
const saveFeature = () => {
  featureStore.error = null;

  v$.value.$validate()

  let params = {}

  if (!v$.value.$error) {
    loadDataInModal.value = true;
    featureStore.storeFeature(featureStore.feature, params)
        .then(() => {
          loadDataInModal.value = false;
        });

    v$.value.$reset();
    showDialog.value = false;
  }
}

const createFeature = () => {
  loadDataInModal.value = true;
  showDialog.value = true
  featureStore.setEmptyFeature();
  loadDataInModal.value = false;
}

const deleteFeatures = () => {
  featureStore.deleteFeatures(featureIds.value).then(() => {
    featureIds.value = [];
  })
}

const restoreFeatures = () => {
  featureStore.restoreFeatures(featureIds.value).then(() => {
    featureIds.value = [];
  })
}

const deleteIcon = () => {
  featureStore.feature.icon = ''
}

</script>