<template>
  <div class="mb-2 relative">
    <label :class="{'text-red-900': isError()}" class="block mb-2 text-sm font-medium text-gray-900">{{ label }}</label>
    <select @change="changeValue"
            :value="modelValue"
            :class="classInput"
            class="border text-sm rounded-lg block w-full p-2.5">

      <option v-for="option of options" :value="option.id">{{ option.name }}</option>
    </select>
  </div>
  <p v-if="isError()" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ v$.$errors[0].$message }}</p>

</template>

<script setup>
import {toRefs, computed} from "vue";

const props = defineProps({
  label: {type: String, default: ''},
  options: Array|Object,
  modelValue: {default: null},
  v$: Object,
});

const {v$, options, modelValue} = toRefs(props);
const emit = defineEmits(['update:modelValue', 'change'])

const changeValue = (event) => {
  emit('update:modelValue', Number(event.target.value))
  emit('change', Number(event.target.value))

  if (v$.value !== undefined) {
    v$.value.$touch();
  }
}

const isError = () => {
  return v$.value !== undefined && v$.value.$error;
}

const defaultClass = 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500';
const errorClass = 'bg-primary-50 bg-primary-50 border-primary-300 text-primary-900 focus:ring-primary-500';

const classInput = computed(() => ({
  [errorClass]: isError(),
  [defaultClass]: !isError(),
}));

</script>