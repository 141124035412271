import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useTechnicsStore = defineStore("technicsStore", () => {
    const technics = ref([]);
    const technicsTotal = ref(null);
    const technicsPaginateLinks = ref([]);
    const technicsCurrentPage = ref(1);
    const lastPage = ref(null);

    let currentTechnic = ref(null);
    const components = ref([]);
    const searchedComponents = ref([]);
    const selectedComponent = ref([]);

    const categories = ref([]);
    const countries = ref([]);
    const makes = ref([]);
    const fuels = ref([]);

    const getDirectories = async (url = '/admin/technics/directories') => {

        await axios.get(url, )
            .then((r) => {
                categories.value = r.data.data.categories;
                countries.value = r.data.data.countries;
                fuels.value = r.data.data.fuels;
            }).catch((e) => {
                console.log(e);
            })
    }

    const getMakesByCountry = async (params = null, url = '/admin/technics/makesByCountry') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                makes.value = r.data.data.makes;
            }).catch((e) => {
                console.log(e);
            })
    }

    const getTechnics = async (params = null, url = '/admin/technics') => {

        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                technics.value = r.data.data.items;
                technicsTotal.value = r.data.data.paginate.total;
                technicsPaginateLinks.value = r.data.data.paginate.links;
                technicsCurrentPage.value = r.data.data.paginate.current_page;
                lastPage.value = r.data.data.paginate.last_page;

            }).catch((e) => {
                console.log(e);
            })
    }

    const deleteTechnics = async (data) => {

        await axios.post('/admin/technics/delete', data)
            .then((r) => {
                console.log(r.data);
            }).catch((e) => {
                console.log(e);
            })
    }

    const revertTechnics = async (data) => {

        await axios.post('/admin/technics/revert', data)
            .then((r) => {
                console.log(r.data);
            }).catch((e) => {
                console.log(e);
            })
    }

    const getComponentsByTechnicId = async (id) => {

        return await axios.get('/admin/technics/' + id + '/components');
    }

    const getComponents = async (params) => {
        let config = {
            params: params,
        }

        return await axios.get('/admin/technics/components', config)
            .then((r) => {
                searchedComponents.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    }

    const attachComponents = async (data) => {
        return await axios.post('/admin/technics/attach-components/', data);
    }

    const detachComponents = async (data) => {

        await axios.post('/admin/technics/detach-components', data)
            .then((r) => {

            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    console.log(errorMessage.join(' '));
                } else {
                    console.log(e);
                }
            })
        //
    }

    //Редактирование модификации/техники

    const storeType = async (data) => {
        return await axios.post('/admin/technics/type', data);
    }

    const getCategories = async () => {
        return await axios.get('/admin/catalogs/categories');
    }

    const getCarBrands = async (categoryId, name) => {
        return await axios.get('/admin/catalogs/categories/' + categoryId + '/car-brands?name=' + name);
    }

    const getModelsByMake = async (makeId, name) => {
        return await axios.get('/admin/catalogs/make/' + makeId + '/models?name=' + name);
    }

    const getTypesByModel = async (modelId, name) => {
        return await axios.get('/admin/catalogs/model/' + modelId + '/types?name=' + name);
    }

    const getTypeById = async (id) => {
        return await axios.get('/admin/technics/' + id);
    }

    //Редактирование узла/компонента

    const getCompCategories = async () => {
        return await axios.get('/admin/catalogs/component/categories');
    }
    const getAttributes = async () => {
        return await axios.get('/admin/catalogs/component/attributes');
    }

    const getCompById = async (id, technicId) => {
        return await axios.get('/admin/technics/component/' + id + '/' + technicId);
    }

    const getUsesByCompCategoryId = async (id, search) => {
        return await axios.get('/admin/technics/uses/' + id + '?search=' + search);
    }

    const getUsesForCompType = async (compCategoryId, makeId, search) => {
        return await axios.get('/admin/technics/uses/' + compCategoryId + '/' + makeId + '?search=' + search);
    }

    const getCatalogItems = async (code, search) => {
        return await axios.get('/admin/catalogs/' + code + '/items?search=' + search);
    }

    const getManufacturer = async (categoryId) => {
        return await axios.get('/admin/catalogs/manufacturers/items?category_id='+categoryId+'&limit=-1');
    }

    const storeSpecification = async (id, data) => {
        return await axios.post('/admin/technics/component/' + id + '/specification', data);
    }

    const storeCompType = async (data) => {
        return await axios.post('/admin/technics/component/', data);
    }

    const detachSpec = async (data) => {
        return await axios.post('/admin/technics/component/detach-specification/', data);

    }

    return {
        categories,
        countries,
        makes,
        fuels,
        technics,
        technicsTotal,
        technicsPaginateLinks,
        technicsCurrentPage,
        lastPage,
        components,
        currentTechnic,
        searchedComponents,
        selectedComponent,
        storeType,
        getCategories,
        getCarBrands,
        getModelsByMake,
        getTypesByModel,
        getTypeById,
        getCompCategories,
        getCompById,
        getCatalogItems,
        storeSpecification,
        storeCompType,
        attachComponents,
        detachComponents,
        getAttributes,
        getTechnics,
        deleteTechnics,
        revertTechnics,
        getComponentsByTechnicId,
        getComponents,
        detachSpec,
        getUsesByCompCategoryId,
        getUsesForCompType,
        getManufacturer,
        getDirectories,
        getMakesByCountry
    }
})

