import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';
import {useLogStore} from "./LogStore.js";

export const useMarketingStore = defineStore("marketingStore", () => {
    const marketings = ref([]);
    const marketing = ref(null);
    const product = ref(null);
    const categoryGroups = ref([]);
    const makes = ref([]);
    const compCategories = ref([]);
    const fuels = ref([]);
    const brands = ref([]);
    const productOptions = ref([]);
    const productOptionsPopup = ref([]);
    const products = ref([]);
    const productsPopup = ref([]);
    const showNoProductsMessage = ref(false);
    const pagination = ref(null);
    const error = ref(null);
    const isSortUpdated = ref(false);

    const getMarketings = async (params = null, url = '/admin/marketings') => {
        let config = {
            params: params,
        }
        await axios.get(url, config)
            .then((r) => {
                marketings.value = r.data.data.marketings;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getMarketingById = async (id) => {
        await axios.get('/admin/marketings/' + id)
            .then((r) => {
                marketing.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeMarketing = async (data, params) => {
        await axios.post('/admin/marketings/store', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    marketing.value = r.data.data;
                    getMarketings(params);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const storeProduct = async (data, marketingId, brandId) => {
        await axios.post('/admin/marketings/' + marketingId + '/product/store', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getProductsByMarketingId(marketingId, {filter: { 'brand_id': brandId}});
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const setEmptyMarketing = async () => {
        marketing.value = {
            category: '',
            make: '',
            fuel: '',
        }
    };

    const setEmptyProduct = async () => {
        product.value = {
            productPopup: '',
            brand: '',
        }
    };

    const resetMarketing = async () => {
        marketing.value = null;
    }

    const resetProduct = async () => {
        product.value = null;
    }

    const deleteMarketings = async (data) => {

        await axios.post('/admin/marketings/delete', data)
            .then((r) => {
                console.log(r.data);
            }).catch((e) => {
                console.log(e);
            })
    }

    const revertMarketings = async (data) => {

        await axios.post('/admin/marketings/revert', data)
            .then((r) => {
                console.log(r.data);
            }).catch((e) => {
                console.log(e);
            })
    }


    const getDirectoriesOptions = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/marketings/directories', config)
            .then((r) => {
                categoryGroups.value = r.data.data.categoryGroups;
                makes.value = r.data.data.makes;
                compCategories.value = r.data.data.compCategories;
                fuels.value = r.data.data.fuels;
                productOptions.value = r.data.data.products;
                brands.value = r.data.data.brands;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getProducts = async (params) => {
        let config = {
            params : params,
        }

        await axios.get('/admin/marketings/products', config)
            .then((r) => {
                productOptions.value = r.data.data.products;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getProductsPopup = async (params) => {
        let config = {
            params : params,
        }

        productOptionsPopup.value = [];
        showNoProductsMessage.value = false;

        await axios.get('/admin/marketings/products', config)
            .then((r) => {
                productOptionsPopup.value = r.data.data.products;

                if (productOptionsPopup.value.length == 0) {
                    showNoProductsMessage.value = true;
                }

            }).catch((e) => {
                console.log(e);
            })
    };

    const getProductsByMarketingId = async (id, params) => {
        let config = {
            params : params,
        }

        return await axios.get('/admin/marketings/' + id + '/products', config) .then((r) => {
            products.value = r.data.data.products;
        }).catch((e) => {
            console.log(e);
        });
    }

    const deleteProducts = async (data, marketingId, brandId) => {
        let config = {
            params: {
                ids: data,
            },
        }

        await axios.delete('/admin/marketings/' + marketingId + '/products', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getProductsByMarketingId(marketingId, {filter: { 'brand_id': brandId}});
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const sendSortProducts = async ($marketingId, data) => {
        let params = {
            sort: data
        }

        await axios.post('/admin/marketings/' + $marketingId + '/products/sort', params)
            .then((r) => {
                let response = r.data;
                if (response.success) {
                    isSortUpdated.value = response.data.isSortUpdated
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    return {
        marketing,
        marketings,
        categoryGroups,
        makes,
        compCategories,
        fuels,
        brands,
        productOptions,
        productOptionsPopup,
        product,
        products,
        error,
        pagination,
        isSortUpdated,
        showNoProductsMessage,
        getMarketings,
        getMarketingById,
        resetMarketing,
        resetProduct,
        deleteMarketings,
        revertMarketings,
        storeMarketing,
        setEmptyMarketing,
        setEmptyProduct,
        getDirectoriesOptions,
        getProducts,
        getProductsPopup,
        getProductsByMarketingId,
        storeProduct,
        deleteProducts,
        sendSortProducts,
    }
})