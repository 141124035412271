<template>
  <div>
    <select
        @change="updateValue"
        :value="modelValue"
        class="border text-sm rounded-lg block w-full p-2.5 focus:ring-gray-500 focus:border-gray-500">
    >
      <option selected value="">{{title}}</option>
      <option v-for="el in elLists" :value="el.id">{{el.title}}</option>
    </select>
  </div>
</template>

<script setup>
const props = defineProps({
  elLists: Array,
  title: String,
  modelValue: Number,
})

const emit = defineEmits(['update:modelValue'])

function updateValue() {
  emit('update:modelValue', event.target.value)
}
</script>