<template>
  <div class="sm:mb-5">
    <label
        class="block text-gray-600 relative text-center border border-dashed border-2 rounded-lg border-gray-200 p-7 cursor-pointer opacity-80 hover:opacity-100"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        :class="{'bg-white opacity-100': isDragging}"
    >
      <input id="files" type="file" @change="onChange" ref="files" multiple :accept="accept" class="opacity-0 overflow-hidden absolute w-px h-px">

      <span class="flex align-center justify-center text-sm font-medium font-medium mb-3">
        <template v-if="files">
          <div>
            <div v-for="file in files" class="pt-1">{{ file.name }}</div>
          </div>
        </template>
        <template v-else><span class="pt-1">Загрузить файлы в формате {{ accept }}</span></template>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
      accept: String,
      modelValue: {type: [Boolean, Array]},
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isDragging: false,
      files: false,
    };
  },
  methods: {
    onChange() {
      this.files = this.$refs.files.files;
      this.$emit('update:modelValue', this.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
        console.log(111);

      this.$refs.files.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
        console.log(this.files);
    },
  },
};
</script>
