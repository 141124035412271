<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">Типы трансмиссии</h1>
          </div>
          <div class="flex-shrink-0">
            <router-link :to="{name: 'GearOperation', params: {id: 0}}" class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1   border-blue-700 hover:bg-gray-100">&plus; Добавить</router-link>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-1/2">
            <form @submit.prevent="searchEntity()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">Поиск</label>
              <div class="relative mt-1 lg:w-96">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="search"
                       type="text" id="top-bar-search"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
                       placeholder="Поиск по названию">
              </div>
            </form>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input v-model="checkboxAllSelected"
                               @click="selectAllCheckbox"
                               id="checkbox-all" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Наименование (RUS)
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Наименование (ENG)
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Сортировка
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="entity of entityStore.entities" :key="entity.id" :class="{'bg-neutral-100 opacity-50': entity.isDeleted}" class="hover:bg-gray-100">
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        <input v-model="entityIds" :value="entity.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ entity.name }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ entity.name_eng }}</p>
                    </td>
                    <td class="p-4">{{ entity.sort }}</td>
                    <td class="text-right">
                      <router-link :to="{name: 'GearOperation', params: {id: entity.id}}" class="py-2 px-3 text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !entityStore.entities.length" class="text-xl text-center p-7">Не найдено</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <template v-if="entityIds.length">
              <div>
                <button @click.prevent="deleteEntities" v-if="!isRestoreEntity" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>Удалить</span>
                </button>
                <button @click.prevent="restoreEntities" v-if="isRestoreEntity" class="flex px-6 py-4 bg-blue-500 rounded-md text-white hover:bg-blue-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>Восстановить</span>
                </button>
              </div>
            </template>
            <template v-else>
              <div v-if="entityStore.pagination">
                <span class="text-gray-500">Найдено</span> {{ entityStore.pagination.total }}
              </div>
            </template>
          </div>
          <div class="flex-col p-7 w-1/2 text-right">
            <nav v-if="entityStore.pagination && entityStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of entityStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="entityPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useGearOperationStore} from "../../stores/GearOperationStore.js";
import Spinner from "../flowbite/spinner.vue";
import {ref, watch} from "vue";

const entityStore = useGearOperationStore();
const isRestoreEntity = ref(false);
const loadData = ref(true);
const checkboxAllSelected = ref(false);
const entityIds = ref([]);
const search = ref("");

// Первоначальные данные
entityStore.getEntities().then(() => {
  loadData.value = false;
});

// Галочка выбрать всё
const selectAllCheckbox = () => {
  entityIds.value = [];

  if (!checkboxAllSelected.value) {
    entityStore.entities.forEach((entity, index) => {
      entityIds.value.push(entity.id)
    });
  }
}

// Работа с данными
const entityPaginate = (url) => {
  if (url) {
    loadData.value = true;

    let params = {
      search: search.value,
      filter: {
      }
    }

    entityStore.getEntities(params, url).then(() => {
      loadData.value = false;
    });
  }
}

const deleteEntities = () => {
  entityStore.deleteEntities(entityIds.value).then(() => {
    entityIds.value = [];
  })
}

const searchEntity = () => {
  loadData.value = true;

  let params = {
    search: search.value,
    filter: {
    }
  }

  entityStore.getEntities(params).then(() => {
    loadData.value = false;
  });
}

watch(entityIds, () => {
  isRestoreEntity.value = false

  let ids = []

  entityIds.value.forEach((entityId) => {
    ids.push(entityId)
  })

  entityStore.entities.forEach((entity) => {
    if (ids.indexOf(entity.id) !== -1 && entity.isDeleted) {
      isRestoreEntity.value = true
    }
  })
})

const restoreEntities = () => {
  entityStore.restoreEntities(entityIds.value).then(() => {
    entityIds.value = [];
  })
}

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === entityStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>