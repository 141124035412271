import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useApiKeyStore = defineStore("ApiKeyStore", () => {
    const apiKeys = ref([]);
    const apiKey = ref(null);
    const error = ref(null);
    const companies = ref([]);

    const getApiKeys = async (params = null) => {

        let config = {
            params: params,
        }

        await axios.get('/admin/api_keys', config)
            .then((r) => {
                apiKeys.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getApiKeyById = async (id) => {
        await axios.get('/admin/api_keys/' + id)
            .then((r) => {
                apiKey.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeApiKey = async (data, params) => {
        await axios.post('/admin/api_keys', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    apiKey.value = r.data.data;
                    getApiKeys(params);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const storeAccess = async (data, params) => {
        await axios.post('/admin/api_keys/access', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    apiKey.value = r.data.data;
                    getApiKeys(params);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const deleteApiKeys = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/admin/api_keys', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    apiKey.value = r.data.data;
                    getApiKeys();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const getDirectoriesOptions = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/api_keys/directories', config)
            .then((r) => {
                companies.value = r.data.data.companies;
            }).catch((e) => {
                console.log(e);
            })
    };

    const setEmptyApiKey = async () => {
        apiKey.value = {
            active: false,
            name: '',
            domain: '',
            api_key: '',
        }
    };

    const resetApiKey = async () => {
        apiKey.value = null;
    }

    return {
        apiKeys,
        apiKey,
        error,
        companies,
        setEmptyApiKey,
        resetApiKey,
        getDirectoriesOptions,
        getApiKeys,
        getApiKeyById,
        storeApiKey,
        storeAccess,
        deleteApiKeys,
    }
})