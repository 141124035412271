<template>
    <ul class="flex" aria-labelledby="dropdownDefault" @change="changeValue">
        <li v-for="option of options">
            <label class="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                <input
                    v-model="modelValue"
                    :checked="modelValue == option.id"
                    type="radio"
                    :value="option.id"
                    class="w-4 h-4 mr-2 bg-gray-100 border-gray-300 rounded-full dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                {{ option.name }}
            </label>
        </li>
    </ul>
</template>

<script setup>
import {toRefs, computed} from 'vue';

const props = defineProps({
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    options: Array,
    modelValue: {type: [String, Number], default: ''},
    v$: Object,
});

const {v$, modelValue, options, placeholder} = toRefs(props);
const emit = defineEmits(['update:modelValue'])

const changeValue = (event) => {
    emit('update:modelValue', event.target.value)
}

const isError = () => {
    return v$.value !== undefined && v$.value.$error;
}

const defaultClass = 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500';
const errorClass = 'bg-primary-50 bg-primary-50 border-primary-300 text-primary-900 focus:ring-primary-500';

const classInput = computed(() => ({
    [errorClass]: isError(),
    [defaultClass]: !isError(),
}));

</script>

