<template>
  <div class="bg-white rounded-lg overflow-hidden">
    <div class="grid grid-cols-6 bg-gray-200 text-[10px] px-8 py-4">
      <div class="col-span-1"></div>
      <div class="col-span-1">Количество запросов</div>
      <div class="col-span-1">Доля модификаций запросов среди стран</div>
      <div class="col-span-1">Доля модификаций запросов среди модели</div>
      <div class="col-span-1">Доля запросов среди марок</div>
<!--      <div class="col-span-1">Доля запросов среди сайта</div>-->
      <div class="col-span-1">Доля запросов среди всех сайтов</div>
<!--      <div class="col-span-1"></div>-->
    </div>
    <div v-if="coverage.infoFilter.length" class="border border-gray-9">
      <div v-for="item in coverage.infoFilter" class="grid grid-cols-6 bg-white text-[10px] px-8 py-4 border-b border-b-gray-2 last:border-none !text-sm">
        <div class="col-span-1 flex items-center gap-2">
          <div class="w-2 h-2 rounded-full" :style="`background: ${item.color}`"></div>
          <div class="">{{item.name}}</div>
        </div>
        <div class="col-span-1">{{item.count}}</div>
        <div class="col-span-1">{{item?.percentages.country ? item?.percentages.country+'%' : '&mdash;'}}</div>
        <div class="col-span-1">{{item?.percentages.model ? item?.percentages.model+'%' : '&mdash;'}}</div>
        <div class="col-span-1">{{item?.percentages.make ? item?.percentages.make+'%' : '&mdash;'}}</div>
<!--        <div class="col-span-1">{{item?.percentages.site ? item?.percentages.site+'%' : '&mdash;'}}</div>-->
        <div class="col-span-1">{{item?.percentages.sites ? item?.percentages.sites+'%' : '&mdash;'}}</div>
<!--        <div class="col-span-1">&mdash;</div>-->
      </div>
    </div>
    <div class="p-5">
      <a v-if="coverage.file" :href="coverage.file" download>Скачать файл</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import {CoverageFilterStore} from "../../../stores/coverageFilterStore";

const coverage = CoverageFilterStore()
</script>

<style scoped>

</style>