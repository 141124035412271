<template>
  <div class="px-8 py-6">
    <div class="bg-gray-50 border border-gray-2 rounded-lg min-h-[600px]">
      <h1 class="p-8 border-b border-gray-2 text-3xl font-medium">Покрытие узлов</h1>
      <div class="pt-6 px-8 py-24">
        <component-coverage-filter/>
        <component-coverage class="mt-12"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import ComponentCoverageFilter from "../componentCoverage/filter.vue";
import ComponentCoverage from "../componentCoverage/filter/coverage.vue";
import {ComponentCoverageFilterStore} from "../../stores/ComponentCoverageFilterStore.js";
import {onMounted} from "vue";
import {useRoute} from "vue-router";

const route = useRoute()
const coverage = ComponentCoverageFilterStore()

onMounted(() => {
  coverage.getDefaultFilters(route.query)
})
</script>
