import axios from "axios";
import setHeaderAuth from "./utilits/setHeaderAuth.js";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL_API;

if (localStorage.getItem('token')) {
    setHeaderAuth(localStorage.getItem('token'))
} else {
    setHeaderAuth(false);
}