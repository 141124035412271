<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">Новостная лента</h1>
          </div>
        </div>

          <div class="flex w-full justify-between">
              <div class="flex-col p-7 w-1/2">
                  <form @submit.prevent="searchNews()" action="#" method="GET">
                      <div class="relative mt-1 lg:w-96">
                          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                              <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                              </svg>
                          </div>
                          <input v-model="search"
                                 type="text" id="top-bar-search"
                                 class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
                                 placeholder="Поиск по ленте">
                      </div>
                  </form>
              </div>
              <div class="flex-col p-7 w-1/2">
                  <div class="flex w-full">
                      <div class="flex-col w-1/2">
                          <input-select-lite v-model="entityType" :options="entityTypeOptions" :placeholder="'Все'"/>
                      </div>
                  </div>
              </div>
          </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4 px-8 w-1/5 text-left font-light text-gray-400">
                      Дата и время
                    </th>
                    <th scope="col" class="p-4 w-2/5 text-left font-light text-gray-400">
                      Узел/Спецификация
                    </th>
                    <th scope="col" class="p-4 w-1/4 text-left font-light text-gray-400">
                      Комментарий
                    </th>
                    <th scope="col" class="p-4 w-1/4 text-left font-light text-gray-400">
                      Статус
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="entity of entityStore.entities" :key="entity.id" class="hover:bg-gray-100">
                    <td class="p-3 px-8 w-1/6">{{ entity.updated_at }}</td>
                    <td class="p-4 w-1/5">
                        <p class="text-base font-medium text-gray-900">{{ entity.title }}</p>
                        <p class="text-sm text-gray-500">{{ entity.type }}</p>
                    </td>
                    <td v-html="entity.comment" class="p-4 w-1/4"></td>
                    <td class="p-4 flex flex-wrap items-center">
                        <div :class="{'bg-blue-500': entity.status === 'Новый', 'bg-gray-400': entity.status !== 'Новый'}" class="h-2.5 w-2.5 rounded-full mr-2"></div>
                        <div>{{ entity.status }}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !entityStore.entities.length" class="text-xl text-center p-7">Не найдено</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <div v-if="entityStore.pagination">
              <span class="text-gray-500">Найдено</span> {{ entityStore.pagination.total }}
            </div>
          </div>
          <div class="flex-col p-7 w-1/2 text-right">
            <nav v-if="entityStore.pagination && entityStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of entityStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="entityPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useNewsStore} from "../../stores/NewsStore.js";
import Spinner from "../flowbite/spinner.vue";
import {ref, watch} from "vue";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import InputSearch from "../flowbite/form/inputSearch.vue";

const entityStore = useNewsStore();
const loadData = ref(true);
const checkboxAllSelected = ref(false);
const search = ref('');
const entityType = ref('');

const entityTypeOptions = ref([
    {id: 'App\\Models\\Quality', name: 'Спецификация'},
    {id: 'App\\Models\\Type', name: 'Модификация техники'},
    {id: 'App\\Models\\Recommendation', name: 'Связка узла со спецификацией'}
]);

// Первоначальные данные
entityStore.getEntities().then(() => {
  loadData.value = false;
    entityStore.sendWatchedNews(
        entityStore.entities
            .filter(el => el.status == 'Новый')
            .map(el => el.id)
    );
});

// Работа с данными
const entityPaginate = (url) => {
  if (url) {
    loadData.value = true;

    let params = {
      search: search.value,
      filter: {
          entity_type: entityType.value
        }
    }

    entityStore.getEntities(params, url).then(() => {
      loadData.value = false;
    });
  }
}

const searchNews = () => {
    loadData.value = true;

    let params = {
        search: search.value,
        filter: {
            entity_type: entityType.value
          }
    }

    entityStore.getEntities(params).then(() => {
        loadData.value = false;
    });
}

watch([entityType], () => {
    searchNews()
})

let timeout = null;
watch(search, () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
        searchNews()
    }, 400);
})

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === entityStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>