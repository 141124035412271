<template>
  <component :is="layout"/>
</template>

<script setup>
import {computed} from "vue";
import {useRoute} from 'vue-router'
import TheInternal from "./components/layouts/TheInternal.vue";
import TheExternal from "./components/layouts/TheExternal.vue";
import TheDefault from "./components/layouts/TheDefault.vue";

const route = useRoute();

const layout = computed(() => {
  switch (route.meta.layout) {
    case 'TheExternal':
      return TheExternal;
    case 'TheInternal':
      return TheInternal;
    default:
      return TheDefault;
  }
});

</script>