<template>
  <label :class="{'text-red-900': isError()}" class="block mb-2 text-sm font-medium text-gray-900" v-if="label">{{ label }}</label>
  <select v-model="selected"
          @change="changeValue"
          :class="classInput"
          class="border text-sm rounded-lg block w-full p-2.5">

    <option v-for="option of modelValue" :value="option.id">{{ option.name }}</option>
  </select>
</template>

<script setup>
import {ref, onMounted, toRefs, computed} from "vue";

const props = defineProps({
  label: {type: String, default: ''},
  modelValue: Array,
  v$: Object,
});

const selected = ref();
const {v$, modelValue} = toRefs(props);

const changeValue = (event) => {
  modelValue.value.forEach(function (item, index, array) {
    item.selected = Number(item.id) === Number(event.target.value);
  });

  if (v$.value !== undefined) {
    v$.value.$touch();
  }
}

const isError = () => {
  return v$.value !== undefined && v$.value.$error;
}

onMounted(() => {
  props.modelValue.forEach(function (item, index, array) {
    if (item.selected) {
      selected.value = item.id;
    }
  });
})

const defaultClass = 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500';
const errorClass = 'bg-primary-50 bg-primary-50 border-primary-300 text-primary-900 focus:ring-primary-500';

const classInput = computed(() => ({
  [errorClass]: isError(),
  [defaultClass]: !isError(),
}));

</script>
