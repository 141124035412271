<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-2 gap-7 px-7 pt-7">
      <div class="col-span-1 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200"
               :class="{'bg-edit': entityStore.entity.id, 'bg-add': !entityStore.entity.id}">
            <div>
              <h1 class="text-3xl font-medium">Характеристики</h1>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow">
                  <div v-if="entityStore.entity">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Логотип</label>
                            <template v-if="entityStore.entity.image">
                              <div class="relative inline-block bg-white p-4 mb-3">
                                <img :src="entityStore.entity.image" width="100" alt="">

                                <button @click.prevent="deleteImage" class="flex text-primary-500 absolute left-0 bottom-0 hover:bg-primary-700">
                                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                  </svg>
                                </button>
                              </div>
                            </template>
                            <drop-file v-model="entityStore.entity.imageNew"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.name" label="Название (RUS)"/>
                            <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.name_eng" label="Название (ENG)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.additional_name" label="Название (Автостат)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-select-lite :v$="v$.country" :options="countriesOptions" v-model="entityStore.entity.country" label="Страна"/>
                            <p v-if="v$.country.$error" class="mt-2 text-sm text-red-600"> {{ v$.country.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <h3 v-if="manufacturesList.length" class="text-lg font-semibold">Заводы</h3>
                            <p v-for="(m, i) of manufacturesList">
                              <button @click.prevent="deleteManufacture(i)" class="text-red-400">X</button>
                              {{ m.name }}
                            </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-search
                                :v$="v$.manufacturers"
                                v-model="inputTextManufacture"
                                :suggestions="searchSuggestManufacturers"
                                @keyup="searchManufacturers()"
                                @selected="handlerSelectedManufacturers"
                                :clearAfterSelected=true
                                label="Найдите и выберите завод">
                            </input-search>
                          </div>
                        </div>
                        <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ entityStore.error }}
                        </div>
                      </form>
                    </div>
                    <div class="p-7 flex justify-end">
                      <button @click.prevent="saveEntity" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                        <span>Сохранить</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useMakeStore} from "../../stores/MakeStore.js";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import InputSearch from "../flowbite/form/inputSearch.vue";
import DropFile from "../flowbite/form/dropFile.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {required} from '../../utilits/custom-validators.js'
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";

const entityStore = useMakeStore();
entityStore.error = null;

const loadData = ref(true);
const loadDataInModal = ref(false);
const countriesOptions = ref([]);
const inputTextManufacture = ref(null);
let suggestManufacturers = ref([]);
let searchSuggestManufacturers = ref([]);

let manufacturesList = ref([]);

// Первоначальные данные
const route = useRoute()
const router = useRouter()

entityStore.getDirectories().then(() => {
  countriesOptions.value = [...countriesOptions.value, ...entityStore.countries];
})

if (route.params.id > 0) {
  entityStore.getEntityById(route.params.id).then(() => {
    entityStore.entity.manufacturers.forEach((manufacturer) => {
      manufacturesList.value.push({
        id: manufacturer.id,
        name: manufacturer.name
      })
    })

    loadData.value = false
  })
} else {
  entityStore.setEmptyEntity().then(() => {
    loadData.value = false
  })
}

entityStore.getManufacturer(-1).then((r) => {
  suggestManufacturers.value = r.data.data
  searchSuggestManufacturers.value = r.data.data
})

// Валидация
const rules = computed(() => ({
  name: {required},
  country: {required},
}));

let state = reactive({
  name: '',
  manufacturers: [],
  country: '',
});

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.country = entityStore.entity.country;
    state.manufacturers = manufacturesList.value;
  }
});

const v$ = useVuelidate(rules, state)

// Работа с данными
const saveEntity = () => {
  entityStore.error = null;

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true;

    let formData = new FormData();

    for (let key in entityStore.entity) {
      formData.append(key, entityStore.entity[key]);
    }

    let isNew = entityStore.entity.id === undefined;

    entityStore.storeEntity(formData).then(() => {
      if (isNew) {
        router.push({name: 'MakeEdit', params: {id: entityStore.entity.id}})
      }

      manufacturesList.value = []
      entityStore.entity.manufacturers.forEach((manufacturer) => {
        manufacturesList.value.push({
          id: manufacturer.id,
          name: manufacturer.name
        })
      })

      entityStore.entity.manufacturers = [];
      manufacturesList.value.forEach(function (item) {
        entityStore.entity.manufacturers.push(item.id)
      });

      loadData.value = false;
    });

    v$.value.$reset();
  }
}

const deleteImage = () => {
  entityStore.entity.image = ''
}

const deleteManufacture = (index) => {
  manufacturesList.value.splice(index, 1)
}

const handlerSelectedManufacturers = (id, name) => {
  manufacturesList.value.push({
    id: id,
    name: name
  })
}

const searchManufacturers = () => {
  searchSuggestManufacturers.value = []

  suggestManufacturers.value.forEach((manufacturer) => {
    if (manufacturer.name.toLowerCase().indexOf(inputTextManufacture.value.toLowerCase()) !== -1) {
      searchSuggestManufacturers.value.push(manufacturer);
    }
  })
}

watch(manufacturesList.value, (ml) => {
  entityStore.entity.manufacturers = [];

  ml.forEach(function (item) {
    entityStore.entity.manufacturers.push(item.id)
  });
});
</script>