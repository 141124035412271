<template>
  <div class="bg-white rounded-lg shadow-[0px_1px_8px_0px_rgba(0,0,0,0.12)] min-h-[512px] flex flex-col">
    <graph-head>
      <span>
        Общее количество запросов
      </span>
    </graph-head>
    <div class="py-4 flex flex-col justify-between gap-6 flex-grow">
      <div id="labels-chart"></div>
      <div v-if="coverageFilter.fullInfoFilter && coverageFilter.fullInfoFilter.length" class="px-10 flex flex-wrap gap-x-10 gap-y-3">
        <div v-for="item in coverageFilter.fullInfoFilter" class="text-sm flex gap-2">
          <div class="flex items-center gap-1">
            <div class="w-3 h-3" :style="`background: ${item.color}`"></div>
            <div>{{item.name}}</div>
          </div>
          <div v-if="getPercent(item.byDay[0].count, item.byDay[item.byDay.length - 1].count)">&mdash;</div>
          <div v-if="getPercent(item.byDay[0].count, item.byDay[item.byDay.length - 1].count)" class="flex gap-1">
            <div>{{item.count}}</div>
            <div class="text-red-500 flex" :class="{'text-green-400': getPercent(item.byDay[0].count, item.byDay[item.byDay.length - 1].count) > 0}">
              (
                <div v-if="getPercent(item.byDay[0].count, item.byDay[item.byDay.length - 1].count) > 0">+</div>
                {{getPercent(item.byDay[0].count, item.byDay[item.byDay.length - 1].count)}}%)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ApexCharts from 'apexcharts'
import {computed, onMounted, ref, watch} from "vue";
import GraphHead from "./components/graphHead.vue";
import {CoverageFilterStore} from "../../../stores/coverageFilterStore";

const chart = ref()
const infoCoverage = ref([])
const tmpInfoCoverage = ref([])
const displayDateCoverage = ref([])
const coverageFilter = CoverageFilterStore()
const coverageDetail = computed(() => coverageFilter.detail)
const isLoaded = computed(() => coverageFilter.isLoaded)

const stepCharts = 10
const monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const getPercent = (startCount = 0, endCount = 0) => {
  return Math.floor((endCount-startCount)/(startCount ?? 1) * 100)
}

const initCharts = () => {
  if (document.getElementById("labels-chart") && typeof ApexCharts !== 'undefined') {
    chart.value = new ApexCharts(document.getElementById("labels-chart"), getOptions());
    chart.value.render();
  }
}

const updateChart = () => {
  chart.value.updateOptions(getOptions())
}

const getOptions = () => {
  return {
    // set the labels option to true to show the labels on the X and Y axis
    xaxis: {
      show: true,
      categories: displayDateCoverage.value,
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
        }
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
        }
      }
    },
    series: getSeries(),
    chart: {
      sparkline: {
        enabled: false
      },
      height: "85%",
      width: "100%",
      type: "area",
      fontFamily: "Inter, sans-serif",
      dropShadow: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0,
        opacityTo: 0,
        shade: "#1C64F2",
        gradientToColors: ["#1C64F2"],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    legend: {
      show: false
    },
    grid: {
      show: false,
    },
  }
}

const getSeries = () => {
  if (infoCoverage.value && infoCoverage.value.length) {
    return infoCoverage.value.map(coverage => {
      return {
        name: coverage.name,
        color: coverage.color,
        data: coverage.data.map(data => data.count)
      }
    })
  }
  return []
}

watch([coverageDetail, isLoaded], () => {
  if (isLoaded.value) {
    infoCoverage.value = []
    tmpInfoCoverage.value = []
    displayDateCoverage.value = []
    initInfoCoverage()
  }
})

//Обнуляем ранеее заполненные данные и идём инициализиовать новые( initActualDays )
const initInfoCoverage = () => {
  if (coverageFilter.fullInfoFilter.length) initActualDays()
  else updateChart()
}

//Основная функция для заполнения актуальных даных для графиков
const initActualDays = () => {
  infoCoverage.value = coverageFilter.fullInfoFilter.map(el => {
    return {
      name: el?.name ?? '',
      color: el?.color ?? '#FFFFFF',
      data: addActualByDay(el?.byDay)
    }
  })
  //Вычисляем общий шаг для записей и шаг с учетом детализации
  let generalStep = 1 //Шаг для того что бы не было переполнения на графике
  if(tmpInfoCoverage.value.length) generalStep = Math.ceil(tmpInfoCoverage.value.length / stepCharts )
  //Заполнение с учётом шага( что бы не было куча записей, а ровно кол-во с шагом (stepCharts))
  if (tmpInfoCoverage.value.length > generalStep) {
    tmpInfoCoverage.value.forEach((data,idx) => {
      if (idx % generalStep === 0 || idx === tmpInfoCoverage.value.length - 1) {
        const day = new Date(data.date)
        displayDateCoverage.value.push(day.getDate() + ' ' + monthsArray[day.getMonth()])
      }
      else displayDateCoverage.value.push('')
    })
  }
  updateChart()
}

//Заполнение актуального массива с датами
const addActualByDay = (days = []) => {
  //Заполняем массив с учетом шага и детализации и определяем шаги
  tmpInfoCoverage.value = []
  let curStep = 1 //Шаг с учётом детализации

  if (coverageDetail.value)
    curStep = details.find(el => el.id == coverageDetail.value)?.step

  //Заполнение с учётом детализации
  tmpInfoCoverage.value = days.filter((day,idx) => {
    if (idx % curStep === 0 || idx === (days.length - 1) ) return day
  })

  if (tmpInfoCoverage.value.length === 1) tmpInfoCoverage.value[1] = tmpInfoCoverage.value[0]

  return tmpInfoCoverage.value
}

onMounted(() => {
  initCharts()
})

const details = [
  {id: 1, step: 1},
  {id: 2, step: 7},
  {id: 3, step: 30},
  {id: 4, step: 365},
  {id: 5, step: 90},
]
</script>

<style scoped>

</style>