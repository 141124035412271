import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useProductStore = defineStore("productStore", () => {
    const qualities = ref([]);
    const viscosity = ref([]);
    const brands = ref([]);
    const features = ref([]);
    const qualityGroups = ref([]);
    const products = ref([]);
    const pagination = ref(null);
    const product = ref(null);
    const quality = ref(null);
    const viscosityModel = ref(null);
    const qualityAddId = ref(0);
    const error = ref(null);
    const qvOptions = ref([]);

    const getDirectories = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/products/directories', config)
            .then((r) => {
                brands.value = r.data.data.brands;
                features.value = r.data.data.features;
                viscosity.value = r.data.data.viscosity;
                qualities.value = r.data.data.qualities;
                qualityGroups.value = r.data.data.qualityGroups;
                qvOptions.value = r.data.data.qvs;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getProducts = async (params = null, url = '/admin/products') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                products.value = r.data.data.products;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getQualities = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/products/qualities', config)
            .then((r) => {
                qualities.value = r.data.data.qualities;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getProductById = async (id) => {
        await axios.get('/admin/product/' + id)
            .then((r) => {
                product.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeProduct = async (data) => {
        await axios.post('/admin/product', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    product.value = r.data.data;
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const storeViscosity = async (product_id, data) => {
        data.product_id = product_id

        await axios.post('/admin/products/viscosity', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    viscosity.value = response.data.viscosity
                    product.value.viscosity = response.data.id
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const storeQuality = async (product_id, data) => {
        data.product_id = product_id

        await axios.post('/admin/products/quality', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    qualityAddId.value = response.data.id

                    getProductById(product_id).then(() => {
                        setTimeout(() => {
                            qualityAddId.value = 0
                        }, 500)
                    });
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const deleteProducts = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/admin/products', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getProducts();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const deleteQualities = async (product, data) => {
        let config = {
            params: {
                product_id: product.id,
                ids: data,
            },
        }

        await axios.delete('/admin/products/qualities', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getProductById(product.id);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const toggleQualities = async (product, qvId) => {
        let params = {
            product_id: product.id,
            qv_id: qvId,
        }

        await axios.post('/admin/products/toggleQuality', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getProductById(product.id);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const restoreProducts = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/admin/products/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    product.value = r.data.data;
                    getProducts();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const getQvsOptions = async (params) => {
        let config = {
            params : params,
        }

        await axios.get('/admin/qvs/options', config)
            .then((r) => {
                qvOptions.value = r.data.data.qvs;
            }).catch((e) => {
                console.log(e);
            })
    };

    const setEmptyProduct = async () => {
        product.value = {
            name: '',
            imageSrc: '',
            qualities: '',
            viscosity: '',
            brand: '',
            linkLube: '',
            linkShop: '',
            isDeleted: '',
        }
    };

    const setEmptyQuality = async () => {
        quality.value = {
            title: '',
            group: '',
        }
    };

    const setEmptyViscosity = async () => {
        viscosityModel.value = {
            title: '',
        }
    };

    const resetProduct = async () => {
        product.value = null;
    }

    const resetViscosity = () => {
        quality.value = null
    }

    const resetQuality = () => {
        quality.value = null
    }

    return {
        qualityGroups,
        brands,
        features,
        viscosity,
        viscosityModel,
        qualities,
        pagination,
        products,
        product,
        quality,
        qualityAddId,
        qvOptions,
        error,
        setEmptyProduct,
        setEmptyViscosity,
        setEmptyQuality,
        resetProduct,
        getDirectories,
        getQualities,
        getProducts,
        getProductById,
        getQvsOptions,
        storeProduct,
        storeQuality,
        storeViscosity,
        deleteProducts,
        deleteQualities,
        restoreProducts,
        resetViscosity,
        resetQuality,
        toggleQualities,
    }
})