import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useNewsStore = defineStore('newsStore', () => {
    const entities = ref([]);
    const pagination = ref(null);
    const newsCount = ref(null);
    const error = ref(null);

    const getEntities = async (params = null, url = '/admin/news') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getNewsCount = async (params = null, url = '/admin/news/count') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                newsCount.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const sendWatchedNews = async (data, url = '/admin/news/watch') => {
        if (data.length === 0) {
            return;
        }

        let params = {
            ids: data
        }

        await axios.post(url, params)
            .catch((e) => {
                console.log(e);
            })
    };

    return {
        pagination,
        entities,
        newsCount,
        error,
        getEntities,
        getNewsCount,
        sendWatchedNews,
    }
})