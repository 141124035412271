import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useFeatureStore = defineStore("featureStore", () => {
    const features = ref([]);
    const feature = ref(null);
    const error = ref(null);

    const getFeatures = async (params = null) => {

        let config = {
            params: params,
        }

        await axios.get('/admin/products/features', config)
            .then((r) => {
                features.value = r.data.data.features;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getFeatureById = async (id) => {
        await axios.get('/admin/products/feature/' + id)
            .then((r) => {
                feature.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeFeature = async (data, params) => {
        await axios.post('/admin/products/feature', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    feature.value = r.data.data;
                    getFeatures(params);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const deleteFeatures = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/admin/products/features', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    feature.value = r.data.data;
                    getFeatures();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const setEmptyFeature = async () => {
        feature.value = {
            icon: '',
            description_ru: '',
            description_en: '',
        }
    };

    const resetFeature = async () => {
        feature.value = null;
    }

    const restoreFeatures = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/admin/products/features/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getFeatures();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    return {
        features,
        feature,
        error,
        setEmptyFeature,
        resetFeature,
        getFeatures,
        getFeatureById,
        storeFeature,
        deleteFeatures,
        restoreFeatures,
    }
})