<template>
  <div class="flex flex-col gap-5">
    <div class="flex gap-3">

      <div class="flex gap-3 w-[80%]" v-if="AutoCoverageFilter?.reactiveFilter && AutoCoverageFilter?.reactiveFilter.length">
        <multiple-select v-for="filter in AutoCoverageFilter.reactiveFilter" :filter="filter" class="flex-1"/>
      </div>

      <button class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100 w-fit" @click="AutoCoverageFilter.applyParamFilter">Применить</button>
      <button class="p-2 text-sm font-medium w-fit text-gray-500" @click="resetFilter">Сбросить фильтры</button>
    </div>

  </div>
</template>

<script setup>
import MultipleSelect from "./filter/multipleSelect.vue";
import {AutoCoverageFilterStore} from "../../stores/AutoCoverageFilterStore.js";
import {useRoute} from "vue-router";

const AutoCoverageFilter = AutoCoverageFilterStore()

const route = useRoute()

const resetFilter = () => {
  AutoCoverageFilter.resetFilter()
}

</script>