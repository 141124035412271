<template>
  <div class="px-8 py-6">
    <div class="bg-gray-50 border border-gray-2 rounded-lg min-h-[600px]">
      <div class="w-full flex justify-between border-b border-gray-2">
          <h1 class="p-8 text-3xl font-medium">Покрытие автопарка</h1>
          <div class="p-8 flex gap-2 items-center text-sm text-link">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9007 4.40625C11.4536 4.40625 11.0933 4.76484 11.0933 5.21117V18.7892C11.0933 19.2347 11.4536 19.5942 11.9007 19.5942H21.1927C21.6399 19.5942 22.0002 19.2347 22.0002 18.7892V5.21117C22.0002 4.76484 21.6399 4.40625 21.1927 4.40625H11.9007ZM11.7389 5.07318H21.3477V18.9196H11.7389V5.07318Z" fill="#1C64F2"/>
                  <path d="M19.9251 6.4043H16.7358V8.1263H19.9251V6.4043Z" fill="#1C64F2"/>
                  <path d="M16.0593 6.4043H12.8701V8.1263H16.0593V6.4043Z" fill="#1C64F2"/>
                  <path d="M19.9251 8.75586H16.7358V10.4779H19.9251V8.75586Z" fill="#1C64F2"/>
                  <path d="M16.0593 8.74609H12.8701V10.4681H16.0593V8.74609Z" fill="#1C64F2"/>
                  <path d="M19.9251 11.1406H16.7358V12.8626H19.9251V11.1406Z" fill="#1C64F2"/>
                  <path d="M16.0593 11.1406H12.8701V12.8626H16.0593V11.1406Z" fill="#1C64F2"/>
                  <path d="M19.9251 13.5508H16.7358V15.2728H19.9251V13.5508Z" fill="#1C64F2"/>
                  <path d="M16.0593 13.5352H12.8701V15.2572H16.0593V13.5352Z" fill="#1C64F2"/>
                  <path d="M19.9251 15.9219H16.7358V17.6439H19.9251V15.9219Z" fill="#1C64F2"/>
                  <path d="M16.0593 15.9219H12.8701V17.6439H16.0593V15.9219Z" fill="#1C64F2"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.378 2.55859V21.4389L2 19.5506V4.44612L13.378 2.55859ZM7.24042 10.8898L6.45253 8.84214L5.12207 8.92136L6.50194 12.0431L4.96875 15.1555L6.29496 15.2347L7.24042 13.0422C7.27593 12.9457 7.28132 12.8968 7.28706 12.8447C7.28768 12.839 7.28831 12.8333 7.28897 12.8276C7.29249 12.8473 7.29437 12.8637 7.29618 12.8795C7.30199 12.93 7.30706 12.9742 7.36308 13.1036L8.30258 15.3539L9.84854 15.4459L8.10156 11.9971C8.63758 10.8685 9.19617 9.75075 9.77699 8.64453L8.34261 8.72971L7.40907 10.8898C7.35125 11.0466 7.32981 11.1261 7.33587 11.228C7.3228 11.1406 7.29723 11.0107 7.24042 10.8898Z" fill="#1C64F2"/>
              </svg>
              <button @click="openDialog">Импорт данных автостата</button>
              <div @click="openHintDialog" class="cursor-pointer">
                  <svg class="w-6 h-6 text-link" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                  </svg>
              </div>
          </div>
      </div>

      <modal v-if="showHintDialog" @closeDialog="closeHintDialog">
          <template #title>Инструкция по загрузке файлов</template>
          <template #body>
                <div class="text-base font-normal text-gray-900">
                    <div class="p-2">
                        Перед загрузкой файлов выполните следующие шаги:
                    </div>
                    <div class="p-2">
                        1. Удалите из файлов лишние колонки. Необходимо оставить только следующие колонки:
                        марка, модель, год выпуска, тип топлива, мощность, страна происхождения марки, количество.
                    </div>
                    <div class="p-2">
                        2. Удалите первую строку с названиями колонок.
                    </div>
                    <div class="p-2">
                        3. Если внутри файла существует несколько листов, то необходимо для каждого листа сделать отдельный файл, иначе, при конвертации в CSV,
                        данные со всех листов, кроме 1, потеряются.
                    </div>
                    <div class="p-2">
                        4. Конвертируйте файлы в формат CSV. Для этого можно воспользоваться онлайн сервисами.
                    </div>
                    <div class="p-2 mb-2">
                        5. При загрузке файлов выбирайте все файлы сразу.
                    </div>
                    <div class="p-2 mb-2">
                        В зависимости от размера и количества файлов, загрузка может происходить длительное время.
                        После загрузки, в течение 24 часов, данные актуализируются.
                    </div>
                    <div class="p-2 mb-2">
                        Один раз в 15 минут вы можете запускать индексацию вручную, нажав соответствующую кнопку в попапе.
                    </div>
                </div>
                <div class="flex items-center space-x-4">
                    <button @click="closeHintDialog" type="button"
                            class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Закрыть
                    </button>
                </div>
          </template>
      </modal>

      <modal v-if="showDialog" @closeDialog="closeDialog">
          <template #title>Загрузить файлы из автостата</template>
          <template #body>
              <form action="#">
                  <div class="sm:col-span-2">
                    <label for="files" class="block mb-2 text-sm font-medium text-gray-900">Файлы</label>
                    <div v-if="showLoadingMessage" class="mb-6 text-xl text-black">Загрузка файлов на сервер...</div>
                    <drop-files v-else v-model="files" accept=".csv"/>
                  </div>
                  <div class="flex items-center space-x-4">
                    <button @click.prevent="runIndexing" :disabled="isIndexingDisabled"
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-blue-400">
                      {{ isIndexingDisabled ? 'Индексация доступна через ~' + nextIndexingTime + ' мин' : 'Запустить индексацию'}}
                    </button>
                    <button @click.prevent="saveFiles" type="submit"
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Сохранить
                    </button>
                    <button @click="closeDialog" type="button"
                            class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Отмена
                    </button>
                  </div>
              </form>
          </template>
      </modal>

      <div class="pt-6 px-8 py-24">
        <auto-coverage-filter/>
        <auto-coverage class="mt-12"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import AutoCoverageFilter from "../autoCoverage/filter.vue";
import AutoCoverage from "../autoCoverage/filter/coverage.vue";
import {AutoCoverageFilterStore} from "../../stores/AutoCoverageFilterStore.js";
import {onMounted} from "vue";
import {useRoute} from "vue-router";
import Modal from "../flowbite/modal.vue";
import {ref} from "vue";
import DropFiles from "../flowbite/form/dropFiles.vue";

const route = useRoute()
const coverage = AutoCoverageFilterStore()
const showDialog = ref(false);
const showHintDialog = ref(false);
const showLoadingMessage = ref(false);
const files = ref([]);
const isIndexingDisabled = ref(false);
const nextIndexingTime = ref(0);

onMounted(() => {
  coverage.getDefaultFilters(route.query)
})

//Модальное окно
const openDialog = () => {
  showLoadingMessage.value = false
  showDialog.value = true

  checkIndexing()
}

const closeDialog = () => {
  showLoadingMessage.value = false
  showDialog.value = false
}

const saveFiles = () => {
  showLoadingMessage.value = true

  let formData = new FormData()

  for (let i = 0; i < files.value.length; i++) {
      formData.append("files[]", files.value[i])
  }

  coverage.storeFiles(formData).then(() => {
      showDialog.value = false
  });
}

//Модальное окно с подсказкой
const openHintDialog = () => {
  showHintDialog.value = true
}

const closeHintDialog = () => {
  showHintDialog.value = false
}

//Запустить индексацию данных
const runIndexing = () => {
  localStorage.setItem('AutoCoverageLastClickTime', new Date().getTime());

  coverage.runIndexing()

  showDialog.value = false
}

const checkIndexing = () => {
  const lastClickTime = localStorage.getItem('AutoCoverageLastClickTime');
  const currentTime = new Date().getTime();

  if (currentTime - lastClickTime < 30 * 60 * 1000) {
    isIndexingDisabled.value = true
    nextIndexingTime.value = 30 - Math.round((currentTime - lastClickTime) / 60 / 1000);
  } else {
    isIndexingDisabled.value = false
  }
}

</script>
