<template>
  <div class="grid grid-cols-1 px-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">Доступы для сайтов и приложений</h1>
          </div>
          <div class="flex-shrink-0">
            <a @click.prevent="createApiKey" href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">&plus; Добавить</a>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-1/2">
            <form @submit.prevent="searchApiKey()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">Поиск</label>
              <div class="relative mt-1 lg:w-96">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="search"
                       type="text" id="top-bar-search"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
                       placeholder="Поиск">
              </div>
            </form>
          </div>
          <div class="flex-col p-7 w-1/4">
            <input-select-lite :options="companyOptions" v-model="company" placeholder="Бренд"/>
          </div>
          <div class="flex-col p-7 w-1/4">
            <input-select v-model="activeOptions"/>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input v-model="checkboxAllApiKeysSelected"
                               @click="selectAllCheckbox"
                               id="checkbox-all" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                        Бренд
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Наименование
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Домен/Ключ доступа
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Способ передачи данных
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Активность
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="apiKey of apiKeyStore.apiKeys" :key="apiKey.id" class="hover:bg-gray-100">
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        <input v-model="apiKeysIds" :value="apiKey.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="p-4"><p class="text-base font-medium text-gray-900">{{ apiKey.company }}</p></td>
                    <td class="p-4">
                      {{ apiKey.name }}
                    </td>
                    <td class="p-4">{{ apiKey.domain }}{{ apiKey.access_key }}</td>
                    <td class="p-4">{{ apiKey.is_api ? 'API' : "Виджет" }}</td>
                    <td class="p-4 text-base font-normal text-gray-900 whitespace-nowrap">
                      <div class="flex items-center">
                        <div :class="{'bg-red-500': !apiKey.is_active, 'bg-green-400': apiKey.is_active}" class="h-2.5 w-2.5 rounded-full mr-2"></div>
                      </div>
                    </td>
                    <td class="p-4">
                      <div class="flex">
                        <button @click="openDialog(apiKey.id)" type="button" class="py-2 px-3 text-sm font-medium text-center">
                          <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                            <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                          </svg>
                        </button>
                        <button @click="openAccess(apiKey.id)" type="button"
                                class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm mx-2.5 px-5 py-2.5 text-center">
                          Доступы
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !apiKeyStore.apiKeys.length" class="text-xl text-center p-7">Не найдено доступов</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between border-t">
          <div class="flex-col p-7 w-1/2">
            <span class="text-gray-500">Найдено</span> {{ apiKeyStore.apiKeys.length }}
          </div>
          <div class="flex-col p-7 w-1/4 text-right text-gray-400">
            <button @click.prevent="deleteApiKeys">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
              </svg>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <modal v-if="showDialog && apiKeyStore.apiKey" :id="apiKeyStore.apiKey.id" @closeDialog="closeDialog" :load-data="loadDataInModal"
         classes="!overflow-auto h-[700px]">
    <template v-if="apiKeyStore.apiKey.id" #title>Редактировать доступ</template>
    <template v-else #title>Добавить доступ</template>
    <template #body>
      <spinner v-if="loadData"></spinner>
      <form v-if="!loadData && apiKeyStore.apiKey" action="#">
        <input type="hidden" name="id" v-model="apiKeyStore.apiKey.id">

        <div class="grid gap-4 mb-4 sm:grid-cols-1">
          <div class="sm:col-span-1 flex justify-between pb-4 border-b border-gray-200">
            <div>
              <div class="flex items-center">
                <input-toggle-switch v-model="apiKeyStore.apiKey.is_active" label="Активность"/>
              </div>
              <div class="mt-4">
                  <input-radio :options="widgetOptions" v-model="apiKeyStore.apiKey.is_api"/>
              </div>
            </div>
            <div class="flex items-end">
              <div>
                <input-radio :options="mobileOptions" v-model="apiKeyStore.apiKey.is_mobile"/>
              </div>
            </div>
          </div>
          <div>
              <label class="block mb-2 text-sm font-medium text-gray-900" for="file_input">Скрыть кнопки и блоки</label>
              <div class="flex items-center space-x-4">
                  <div class="flex items-center w-2/4">
                      <input-checkbox v-model="apiKeyStore.apiKey.hide_button_buy" id="hide_button_buy" label="Где купить?"/>
                  </div>
                  <div class="flex items-center w-2/4">
                      <input-checkbox v-model="apiKeyStore.apiKey.hide_button_where" id="hide_button_where" label="Купить в интернет магазине"/>
                  </div>
                  <div class="flex items-center w-2/4">
                      <input-checkbox v-model="apiKeyStore.apiKey.hide_button_link" id="hide_button_link" label="Оставить заявку"/>
                  </div>
                  <div class="flex items-center w-2/4">
                      <input-checkbox v-model="apiKeyStore.apiKey.hide_intervals" id="hide_button_link" label="Интервал смены"/>
                  </div>
              </div>
          </div>
          <div class="grid gap-4 sm:grid-cols-2">
            <div>
              <input-text :v$="v$.name" v-model="apiKeyStore.apiKey.name" label="Наименование"/>
            </div>
            <div v-if="apiKeyStore.apiKey.is_api == 1 || apiKeyStore.apiKey.is_mobile == 1" class="inline-flex">
              <input-text :class-name="'w-full'" :v$="v$.access_key" v-model="apiKeyStore.apiKey.access_key" label="Ключ доступа"/>
              <button v-if="showGenerateIcon" @click.prevent="generateApiKey" class="pt-4 px-3">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <path d="M 25 4.03125 C 24.234835 4.03125 23.483027 4.3294733 22.90625 4.90625 L 13 14.78125 L 12.78125 15 L 12.71875 15.3125 L 12.03125 18.8125 L 11.71875 20.28125 L 13.1875 19.96875 L 16.6875 19.28125 L 17 19.21875 L 17.21875 19 L 27.09375 9.09375 C 28.247303 7.9401966 28.247303 6.0598034 27.09375 4.90625 C 26.516973 4.3294733 25.765165 4.03125 25 4.03125 z M 25 5.96875 C 25.234835 5.96875 25.464277 6.0892767 25.6875 6.3125 C 26.133947 6.7589466 26.133947 7.2410534 25.6875 7.6875 L 16 17.375 L 14.28125 17.71875 L 14.625 16 L 24.3125 6.3125 C 24.535723 6.0892767 24.765165 5.96875 25 5.96875 z M 4 8 L 4 28 L 24 28 L 24 14.8125 L 22 16.8125 L 22 26 L 6 26 L 6 10 L 15.1875 10 L 17.1875 8 L 4 8 z"/>
                </svg>
              </button>
            </div>
            <div v-else>
              <input-text :v$="v$.domain" v-model="apiKeyStore.apiKey.domain" label="Домен"/>
            </div>
          </div>
          <div class="grid gap-4 sm:grid-cols-2">
              <div>
                  <input-select-lite :v$="v$.lang" :options="langOptions" v-model="apiKeyStore.apiKey.language" label="Язык"/>
                  <p v-if="v$.lang.$error" class="mt-2 text-sm text-red-600"> {{ v$.lang.$errors[0].$message }} </p>
              </div>
              <div>
                  <input-select-lite :v$="v$.company" :options="companyOptions" v-model="apiKeyStore.apiKey.company" label="Бренд"/>
                  <p v-if="v$.company.$error" class="mt-2 text-sm text-red-600"> {{ v$.company.$errors[0].$message }} </p>
              </div>
          </div>
          <div v-if="apiKeyStore.apiKey.is_api == 1" class="inline-flex">
            <input-text :class-name="'w-full'" :v$="v$.api_key" v-model="apiKeyStore.apiKey.api_key" label="Ключ шифрования"/>
            <button @click.prevent="copyApiKey" class="pt-4 px-3">
              <svg width="20" height="20" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"/><path fill="#000000" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"/></svg>
            </button>
          </div>
          <div v-if="typeof apiKeyStore.apiKey.is_api == 'undefined' || apiKeyStore.apiKey.is_api == 0" class="inline-flex">
              <input-text-area :class-name="'w-full'" v-model="widget" :disabled="true" label="Код виджета"/>
              <button @click.prevent="copyWidget()" class="pt-4 px-3">
                  <svg width="20" height="20" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"/><path fill="#000000" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"/></svg>
              </button>
          </div>
          <div>
            <input-text :class-name="'w-full'" :v$="v$.search_count" v-model="apiKeyStore.apiKey.search_count" label="Количество результатов в поиске"/>
          </div>
          <div>
            <input-text :class-name="'w-full'" :v$="v$.copyright" v-model="apiKeyStore.apiKey.copyright" label="Копирайт на виджете"/>
          </div>
          <div class="flex flex-wrap flex-col">
            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Картинка для категории "Легковые машины"</label>
            <template v-if="apiKeyStore.apiKey.logo_category_1">
              <div class="relative inline-block bg-white p-4 mb-3">
                <img :src="apiKeyStore.apiKey.logo_category_1" width="100" alt="">

                <button @click.prevent="deleteCategoryImage1" class="flex text-primary-500 absolute left-0 bottom-0 hover:bg-primary-700">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                </button>
              </div>
            </template>
            <drop-file v-model="apiKeyStore.apiKey.new_logo_category_1"/>
          </div>
          <div class="flex flex-wrap flex-col">
            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Картинка для категории "Малые коммерческие"</label>
            <template v-if="apiKeyStore.apiKey.logo_category_2">
              <div class="relative inline-block bg-white p-4 mb-3">
                <img :src="apiKeyStore.apiKey.logo_category_2" width="100" alt="">

                <button @click.prevent="deleteCategoryImage2" class="flex text-primary-500 absolute left-0 bottom-0 hover:bg-primary-700">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                </button>
              </div>
            </template>
            <drop-file v-model="apiKeyStore.apiKey.new_logo_category_2"/>
          </div>
          <div class="flex flex-wrap flex-col">
            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Картинка для категории "Грузовые автомобили"</label>
            <template v-if="apiKeyStore.apiKey.logo_category_3">
              <div class="relative inline-block bg-white p-4 mb-3">
                <img :src="apiKeyStore.apiKey.logo_category_3" width="100" alt="">

                <button @click.prevent="deleteCategoryImage3" class="flex text-primary-500 absolute left-0 bottom-0 hover:bg-primary-700">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                </button>
              </div>
            </template>
            <drop-file v-model="apiKeyStore.apiKey.new_logo_category_3"/>
          </div>
          <div class="flex flex-wrap flex-col">
            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Картинка для категории "Малоразмерная техника"</label>
            <template v-if="apiKeyStore.apiKey.logo_category_4">
              <div class="relative inline-block bg-white p-4 mb-3">
                <img :src="apiKeyStore.apiKey.logo_category_4" width="100" alt="">

                <button @click.prevent="deleteCategoryImage4" class="flex text-primary-500 absolute left-0 bottom-0 hover:bg-primary-700">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                </button>
              </div>
            </template>
            <drop-file v-model="apiKeyStore.apiKey.new_logo_category_4"/>
          </div>
        </div>
        <div v-if="apiKeyStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {{ apiKeyStore.error }}
        </div>
        <div class="flex items-center space-x-4">
          <button @click.prevent="saveApiKey" type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Сохранить
          </button>
          <button @click="closeDialog" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            <svg class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"></path>
            </svg>
            Отмена
          </button>
        </div>
      </form>
    </template>
  </modal>

  <modal v-if="showAccessDialog && apiKeyStore.apiKey" :id="apiKeyStore.apiKey.id" @closeDialog="closeAccessDialog"
         :load-data="loadDataInAccessModal" :disableBg="true">
    <template #title>Запрещённые категории и марки</template>
    <template #body>
      <spinner v-if="loadData"></spinner>
      <form v-if="!loadData && apiKeyStore.apiKey" action="#">
        <input type="hidden" name="id" v-model="apiKeyStore.apiKey.id">
        <div class="grid gap-4 mb-4 sm:grid-cols-1">
          <div>
            <label class="block mb-2 text-base font-medium text-gray-900" for="file_input">Категории</label>
            <div class="grid  grid-cols-2 gap-4 items-center">
              <div v-for="(category, i) of apiKeyStore.apiKey.categories" :key="category.id" class="items-center">
                <input-checkbox v-model="apiKeyStore.apiKey.categories[i].attached" :id="category.id" :label="category.name"/>
              </div>
            </div>
          </div>
          <div class="mb-52" @click="toggleMakesDropdown(true)" v-click-outside="() => toggleMakesDropdown(false)">
            <div>
              <label class="block mb-2 text-base font-medium text-gray-900" for="file_input">Марки</label>
              <input
                     v-on:input="updateMakes($event.target.value)"
                     class="flex bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5 disabled:bg-gray-200"
                     type="text"
                     placeholder="Выбрать марки"
              >
              <div id="makesDropdown" class="absolute w-1/2 z-50 top-100 border bg-white shadow-xl rounded hidden">
                <ul class="divide-y max-h-52 overflow-y-auto">
                  <li id="allMakesCheckbox" class="flex items-center">
                    <input id="all" type="checkbox" value="true" @change="selectAllMakes()" v-model="allMakes"
                           class="w-4 ml-2.5 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 checked:bg-blue-700 focus:ring-blue-500 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label for="all" class="w-full py-2.5 pr-2.5 ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">Выбрать все</label>
                  </li>
                  <li v-for="(make, i) of makeOptions" :key="make.id" class="flex items-center">
                    <input :id="'make-' + make.id" type="checkbox" value="true" v-model="makeOptions[i].attached"
                           class="w-4 ml-2.5 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 checked:bg-blue-700 focus:ring-blue-500 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label :for="'make-' + make.id" class="w-full p-2.5 text-sm font-medium text-gray-900 dark:text-gray-100">{{ make.name }}</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center space-x-4">
          <button @click.prevent="saveAccess" type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Сохранить
          </button>
          <button @click="closeAccessDialog" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            <svg class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"></path>
            </svg>
            Отмена
          </button>
        </div>
      </form>
    </template>
  </modal>

</template>

<script setup>

import Spinner from "../flowbite/spinner.vue";
import Modal from "../flowbite/modal.vue";
import {computed, reactive, ref, watch} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import InputTextArea from "../flowbite/form/inputTextArea.vue";
import InputCheckbox from "../flowbite/form/inputCheckbox.vue";
import InputRadio from "../flowbite/form/inputRadio.vue";
import InputSelect from "../flowbite/form/inputSelect.vue";
import InputToggleSwitch from "../flowbite/form/inputToggleSwitch.vue";
import useVuelidate from '@vuelidate/core'
import {required, email, minLength, maxLength, selectInList, helpers, sameAs, numeric, minValue, maxValue} from '../../utilits/custom-validators.js'
import {useApiKeyStore} from "../../stores/ApiKeyStore.js";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import randomString from "randomstring";
import DropFile from "../flowbite/form/dropFile.vue";

const apiKeyStore = useApiKeyStore();
const showDialog = ref(false);
const showAccessDialog = ref(false);
const loadData = ref(true);
const loadDataInModal = ref(true);
const loadDataInAccessModal = ref(true);
const allMakes = ref(false);
const checkboxAllApiKeysSelected = ref(false);
const apiKeysIds = ref([]);
const search = ref("");
const widget = ref("");
const company = ref("");
const showGenerateIcon = ref(true);

const companyOptions = ref([]);
const makeOptions = ref([]);

const activeOptions = ref([
  {id: -1, name: 'Все', selected: true},
  {id: 1, name: 'Активен', selected: false},
  {id: 0, name: 'Неактивен', selected: false}
]);

const langOptions = ref([
    {id: 'ru', name: 'Русский', selected: false},
    {id: 'en', name: 'Английский', selected: false}
]);

const widgetOptions = ref([
    {id: 0, name: 'Виджет'},
    {id: 1, name: 'API'},
]);

const mobileOptions = ref([
    {id: 0, name: 'Сайт'},
    {id: 1, name: 'Моб. приложение'}
]);

apiKeyStore.getDirectoriesOptions().then(() => {
    companyOptions.value = [...companyOptions.value, ...apiKeyStore.companies];
});

const getApiKeys = () => {
  loadData.value = true;

  let activeOption = activeOptions.value.filter(item => item.selected);

  let params = {
      search: search.value,
      filter: {
        active: activeOption[0].id,
        company_id: company.value,
      }
  }
  apiKeyStore.getApiKeys(params).then(() => {
      loadData.value = false;
  });
}

//Первоначальные данные
apiKeyStore.getApiKeys().then(() => {
  loadData.value = false;
});

//Валидация
const rules = computed(() => ({
  name: {required},
  lang: {required},
  company: {required},
  api_key: {},
  domain: state.is_api || state.is_mobile ? {} : {required},
  access_key: state.is_api ? {required} : {},
  search_count: {numeric, maxValue: maxValue(65535)},
}));

let state = reactive({
  name: "",
  lang: "",
  company: "",
  api_key: "",
  domain: "",
  access_key: "",
  state: "",
  search_count: "",
});

watch(apiKeyStore, (apiKeyStore) => {
  if (apiKeyStore.apiKey) {
    state.name = apiKeyStore.apiKey.name;
    state.api_key = apiKeyStore.apiKey.api_key;
    state.lang = apiKeyStore.apiKey.language;
    state.company = apiKeyStore.apiKey.company;
    state.domain = apiKeyStore.apiKey.domain;
    state.access_key = apiKeyStore.apiKey.access_key;
    state.is_api = apiKeyStore.apiKey.is_api;
    state.is_mobile = apiKeyStore.apiKey.is_mobile;
    state.search_count = apiKeyStore.apiKey.search_count;

    widget.value = getWidgetCode()
  }
});

const v$ = useVuelidate(rules, state)

const getWidgetCode = () => {

  let lang = apiKeyStore.apiKey.language ?? 'ru';

  let company = companyOptions.value.find(company => company.id === Number(apiKeyStore.apiKey.company));
  let companyCode = company ? company.code : 'lukoil';

  let accessKey = (Number(apiKeyStore.apiKey.is_mobile) === 1 && apiKeyStore.apiKey.access_key != null) ? ' data-access-key="' + apiKeyStore.apiKey.access_key + '"': '';

  return '<div id="lukapp" data-locale="'+ lang +'" data-type="' + companyCode + '"' + accessKey +'></div>' +
      '<script type="module" src="https://lubricantadvisor.lukoil-masla.ru/assets/main.js"><\/script>';
}

//Модальное окно
const openDialog = (id) => {
  loadDataInModal.value = true;
  showDialog.value = true

  apiKeyStore.getApiKeyById(id)
      .then(() => {
        loadDataInModal.value = false;
        showGenerateIcon.value = apiKeyStore.apiKey.access_key === null;
      });
}

const closeDialog = () => {
  showDialog.value = false;
  v$.value.$reset();
  apiKeyStore.resetApiKey();
  apiKeyStore.error = null;
  showGenerateIcon.value = true;
}

//Модальное окно с доступами
const openAccess = (id) => {
  loadDataInAccessModal.value = true;
  showAccessDialog.value = true;

  apiKeyStore.getApiKeyById(id)
      .then(() => {
        loadDataInAccessModal.value = false;

        allMakes.value = true;
        apiKeyStore.apiKey.makes.forEach((apiKey) => {
          if (apiKey.name !== null && apiKey.attached === false) {
            makeOptions.value = apiKeyStore.apiKey.makes;
            allMakes.value = false;
          }
        });
      });
}

const closeAccessDialog = () => {
  showAccessDialog.value = false;
  apiKeyStore.resetApiKey();
}

//Галочка выбрать всё
const selectAllCheckbox = () => {
  apiKeysIds.value = [];

  if (!checkboxAllApiKeysSelected.value) {
    apiKeyStore.apiKeys.forEach((apiKey, index) => {
      apiKeysIds.value.push(apiKey.id)
    });
  }
}

//Галочка выбрать все марки
const selectAllMakes = () => {
    apiKeyStore.apiKey.makes.forEach((apiKey) => {
      apiKey.attached = allMakes.value === true;
    });
}

//иконка копировать ключ
const copyApiKey = () => {
  navigator.clipboard.writeText(apiKeyStore.apiKey.api_key)
}

//генерация ключа
const generateApiKey = () => {
  apiKeyStore.apiKey.access_key = randomString.generate(32);
}

//иконка копировать виджет
const copyWidget = () => {
  navigator.clipboard.writeText(widget.value)
}

// Работа с данными
const saveApiKey = () => {

  apiKeyStore.error = null;

  v$.value.$validate()

  let activeOption = activeOptions.value.filter(item => item.selected);

  let params = {
    search: search.value,
    filter: {
      active: activeOption[0].id,
      company_id: company.value,
    }
  }

  if (!v$.value.$error) {

    let formData = new FormData();

    for (let key in apiKeyStore.apiKey) {
      if (key != 'categories' && key != 'makes') {
        formData.append(key, apiKeyStore.apiKey[key]);
      }
    }

    formData.append('new_logo_category_1', apiKeyStore.apiKey.new_logo_category_1);
    formData.append('new_logo_category_2', apiKeyStore.apiKey.new_logo_category_2);
    formData.append('new_logo_category_3', apiKeyStore.apiKey.new_logo_category_3);
    formData.append('new_logo_category_4', apiKeyStore.apiKey.new_logo_category_4);

    loadDataInModal.value = true;
    apiKeyStore.storeApiKey(formData, params)
        .then(() => {
          loadDataInModal.value = false;
        });

    v$.value.$reset();
    showDialog.value = false;
    showGenerateIcon.value = true;
  }
}

// Работа с данными
const saveAccess = () => {

  let activeOption = activeOptions.value.filter(item => item.selected);

  let params = {
    search: search.value,
    filter: {
      active: activeOption[0].id,
      company_id: company.value,
    }
  }

  loadDataInModal.value = true;
  apiKeyStore.storeAccess(apiKeyStore.apiKey, params)
      .then(() => {
        loadDataInModal.value = false;
      });

  apiKeyStore.resetApiKey();
  showAccessDialog.value = false;
}

const createApiKey = () => {
  loadDataInModal.value = true;
  showDialog.value = true
  apiKeyStore.setEmptyApiKey();
  loadDataInModal.value = false;
}

const deleteApiKeys = () => {
  apiKeyStore.deleteApiKeys(apiKeysIds.value).then(() => {
  })
}

const searchApiKey = () => {
    getApiKeys()
}

const deleteCategoryImage1 = () => {
  apiKeyStore.apiKey.logo_category_1 = ''
}

const deleteCategoryImage2 = () => {
  apiKeyStore.apiKey.logo_category_2 = ''
}

const deleteCategoryImage3 = () => {
  apiKeyStore.apiKey.logo_category_3 = ''
}

const deleteCategoryImage4 = () => {
  apiKeyStore.apiKey.logo_category_4 = ''
}

watch([activeOptions.value, company], () => {
    getApiKeys()
})

const toggleMakesDropdown = (focus) => {
  if (focus) {
    document.getElementById('makesDropdown')?.classList.remove('hidden');
  } else {
    document.getElementById('makesDropdown')?.classList.add('hidden');
  }
}

const updateMakes = (value) => {
  if (value !== '') {
    document.getElementById('allMakesCheckbox').classList.add('hidden');
  } else {
    document.getElementById('allMakesCheckbox').classList.remove('hidden');
  }

  makeOptions.value = apiKeyStore.apiKey.makes.filter(function(el) {
    return el.name.toUpperCase().includes(value.toUpperCase())
  })
}

</script>