import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useLogStore = defineStore('logStore', () => {
    const entities = ref([]);
    const pagination = ref(null);
    const error = ref(null);
    const users = ref([]);

    const getEntities = async (params = null, url = '/admin/logs') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getDirectories = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/logs/directories', config)
            .then((r) => {
                users.value = r.data.data.users;
            }).catch((e) => {
                console.log(e);
            })
    };

    return {
        pagination,
        entities,
        error,
        users,
        getEntities,
        getDirectories,
    }
})