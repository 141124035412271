<template>
  <div class="mb-2 relative">
    <div :class="{ 'z-50': showSuggestions }" class="relative">
      <label v-if="!placeholder" class="block mb-2 text-sm font-medium text-gray-900">{{ label }}</label>
      <input
          @focus="checkInputFocus"
          @keyup="changeValue"
          @click="isShowSuggestionsOnClick ? showSuggestions = true : null"
          :value="modelValue"
          :class="[classInput, load ? 'load' : '']"
          :disabled="disabled"
          type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  disabled:bg-gray-200"
          :placeholder="placeholder">
    </div>
    <!-- Подсказка -->
    <div v-if="showSuggestions" class="absolute w-full z-50 top-100 border bg-white shadow-xl rounded">
      <div class="divide-y max-h-40 overflow-y-auto" :class="{'max-h-64': hugeDropdownHeight}">
        <template v-for="item of suggestions" :key="item.id">
          <p class="p-2" 
            :class="{ 'pointer-events-none opacity-70' : selected === item.id }"
            @click.prevent="setSelected(item)"
            style="cursor: pointer"
          >
            {{ item.name }}
          </p>
        </template>
      </div>
      <div v-if="$slots.addButton || btnNewLabel" class="w- p-2 pt-4 pb-4 text-gray-500 border-t border-gray-300 flex justify-between">
        <div class="flex-shrink-0">
          <template v-if="$slots.addButton">
            <slot name="addButton"/>
          </template>
          <template v-else-if="btnNewLabel">
            <a href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">&plus;{{ btnNewLabel }}</a>
          </template>
        </div>
      </div>
    </div>
    <div v-if="showSuggestions" @click="closeSuggestion" tabindex="-1" class="z-40 flex bg-gray-900 bg-opacity-50 overflow-hidden fixed top-0 right-0 left-0 w-full h-full"></div>
    <p v-if="isError()" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ v$.$errors[0].$message }}</p>
  </div>
</template>

<script setup>
import {computed, ref, toRefs, watch} from "vue";
import {useSlots} from 'vue'

const slots = useSlots()

const props = defineProps({
  isShowSuggestionsOnClick: {type: Boolean, default: false},
  label: {type: String, default: ''},
  btnNewLabel: {type: String, default: null},
  suggestions: {type: Array, default: []},
  modelValue: {type: String, default: ''},
  disabled: {type: Boolean, default: false},
  hugeDropdownHeight: {default: false},
  placeholder: {type: String, default: ''},
  isOpenOther: {type: Boolean, default: true},
  v$: Object,
  load: {type: Boolean, default: false}
});

const {v$, modelValue, isOpenOther, load} = toRefs(props);
const emit = defineEmits(['update:modelValue', 'selected', 'keyup'])
const selected = ref(null)

let inputFocus = ref(false);
let showSuggestions = ref(false);
let changeOption = ref(false);

const checkInputFocus = () => {
  inputFocus.value = true;
  showSuggestions.value = (props.suggestions.length > 0) && inputFocus.value && isOpenOther.value
}

watch(() => props.suggestions, (value) => {
  showSuggestions.value = (value.length > 0) && inputFocus.value && isOpenOther.value
});


const changeValue = (event) => {
  emit('update:modelValue', event.target.value);
  emit('keyup', event.target.value);
  changeOption.value = false;
}

const setSelected = (item) => {
  const {id, name} = item
  emit('selected', id, name, item)
  selected.value = id
  emit('update:modelValue', name)
  showSuggestions.value = false;
  changeOption.value = true;
}

const closeSuggestion = () => {
  showSuggestions.value = false;
  // if (!changeOption.value) {
  //   emit('update:modelValue', null)
  // }
}

// const checkValue = () => {
//   console.log('checkValue')
//   // if (!changeOption.value) {
//   //   emit('update:modelValue', null)
//   // }
// }

const isError = () => {
  return v$.value !== undefined && v$.value.$error;
}

const defaultClass = 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500';
const errorClass = 'bg-primary-50 bg-primary-50 border-primary-300 text-primary-900 focus:ring-primary-500';

const classInput = computed(() => ({
  [errorClass]: isError(),
  [defaultClass]: !isError()
}));

</script>

<style scoped>
input.load {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath fill='%236B7280' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E%0A");
}
input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
</style>