import {defineStore} from 'pinia'
import axios from "axios";
import router from '../router'
import setHeaderAuth from "../utilits/setHeaderAuth.js";
import {ref} from 'vue';

export const useAuthStore = defineStore("authStore", () => {
    const authUser = ref(JSON.parse(localStorage.getItem('authUser')));

    const error = ref(null);

    const getProfile = async () => {
        return await axios.get('/admin/user/profile');
    };

    const login = async (email, password) => {
        error.value = null;

        await axios.post('/admin/login', {
            email: email,
            password: password
        }).then((r) => {
            let response = r.data;
            if (response.success) {
                authUser.value = response.data.user;
                console.log(authUser.value)
                localStorage.setItem('authUser', JSON.stringify(response.data.user));
                localStorage.setItem('token', response.data.token);
                setHeaderAuth(response.data.token)
                router.push({name: 'Dashboard'})
            } else {
                error.value = response.error.message
            }

        }).catch((e) => {
            console.log(e);
        });
    };

    const logout = () => {
        authUser.value = null;
        localStorage.removeItem('authUser');
        localStorage.removeItem('token');

        router.push({name: 'Login'}).then();
    }

    const clearAuthUser = () => {
        authUser.value = null;
        localStorage.removeItem('authUser');
        localStorage.removeItem('token');
    }

    const hasRole = (role) => {

        let show = false;

        if (authUser) {
            authUser.value.roles.forEach(function (item, index) {
                if (item.key === 'admin') {
                    show = true;
                } else if (Array.isArray(role) && role.includes(item.key)) {
                    show = true;
                } else if (item.key === role) {
                    show = true;
                }
            });
        }

        return show;
    }

    return {
        authUser,
        error,
        login,
        logout,
        getProfile,
        clearAuthUser,
        hasRole
    }
})