<template>
  <spinner v-if="loadData && !loadDataQualities"></spinner>
  <div v-if="!loadData || loadDataQualities">
    <div class="grid grid-cols-2 gap-7 px-7 pt-7">
      <div class="col-span-1 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200"
               :class="{'bg-edit': entityStore.entity.id, 'bg-add': !entityStore.entity.id}">
            <div>
              <h1 class="text-3xl font-medium">Характеристики</h1>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow">
                  <div v-if="entityStore.entity">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.name" label="Название (RUS)"/>
                            <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.name_eng" label="Название (ENG)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-select-lite :v$="v$.qualityGroup" :options="qualityGroupOptions" v-model="entityStore.entity.qualityGroup" label="Группа спецификации"/>
                            <p v-if="v$.qualityGroup.$error" class="mt-2 text-sm text-red-600"> {{ v$.qualityGroup.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-select-lite :v$="v$.segment" :options="segmentOptions" v-model="entityStore.entity.segment" label="Сегмент"/>
                            <p v-if="v$.segment.$error" class="mt-2 text-sm text-red-600"> {{ v$.segment.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-select-lite :v$="v$.standard" :options="standardOptions" v-model="entityStore.entity.standard" label="Тип спецификации"/>
                            <p v-if="v$.standard.$error" class="mt-2 text-sm text-red-600"> {{ v$.standard.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-select-lite :v$="v$.group" :options="groupOptions" v-model="entityStore.entity.group" label="Группа"/>
                            <p v-if="v$.group.$error" class="mt-2 text-sm text-red-600"> {{ v$.group.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2 relative" v-bind:class="{ hidden : route.params.id == 0}">
                            <a @click.prevent="openDialog" href="#" class="absolute left-72 -top-2 p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">Изменить</a>
                            <input-text v-model="entityStore.entity.priority" disabled label='Приоритет в рамках выбранных значений'
                            />
                            <p v-if="v$.priority.$error" class="mt-2 text-sm text-red-600"> {{ v$.priority.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text-area v-model="entityStore.entity.description" label="Описание спецификации"/>
                            <p v-if="v$.description.$error" class="mt-2 text-sm text-red-600"> {{ v$.description.$errors[0].$message }} </p>
                          </div>
                        </div>
                        <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ entityStore.error }}
                        </div>
                      </form>
                    </div>
                    <div class="p-7 flex justify-end">
                      <button @click.prevent="saveEntity" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                        <span>Сохранить</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="viscosity" class="col-span-1 mb-4 xl:mb-2" v-if="entityStore.entity && entityStore.entity.id">
        <div class="mb-5 bg-white rounded-lg border border-gray-200">
          <div class="flex justify-between items-center p-7">
            <div>
              <h1 class="text-3xl font-medium">Вязкость</h1>
            </div>
            <div class="flex-shrink-0">
              <button @click.prevent="showAddViscosity" type="button" class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1   border-blue-700 hover:bg-gray-100">&plus; Добавить</button>
            </div>
          </div>

          <div v-if="entityStore.entity.viscosity.length" class="border-t border-gray-200">
            <div class="flex flex-col">
              <div class="w-5/6 text-sm p-8 font-light text-gray-400">Для установки приоритетов расставьте вязкости в нужном порядке методом Drag-and-Drop</div>
              <div class="min-w-full divide-y divide-gray-200 table-fixed">
                <div class="bg-gray-100">
                  <div class="grid grid-cols-viscosity">
                    <div class="p-4 h-full flex items-center" scope="col">
                      <div class="flex items-center">
                        <input v-model="checkboxAllViscositySelected"
                              @click="viscositySelectAllCheckbox"
                              id="checkbox-all" type="checkbox"
                              class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </div>
                    <div class="p-4 text-left font-light text-gray-400">
                      Название
                    </div>
                    <div class="p-4 text-right font-light text-gray-400">
                      Приоритет
                    </div>
                    <div class=""></div>
                  </div>
                </div>
                <div class="bg-white divide-y divide-gray-50">
                  <draggable v-model="viscosityOptions" item-key="id" class="transition-colors" @choose="deactivateDragViscositiesMessage">
                    <template #item="{element, index}">
                      <div class="grid grid-cols-viscosity active:!bg-gray-100">
                        <div class="p-4 w-4 h-full flex items-center">
                          <div class="flex items-center">
                            <input v-model="viscosityIds" :value="element.id" aria-describedby="checkbox-1" type="checkbox"
                                  class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                          </div>
                        </div>
                        <div class="p-4">
                          <span>{{element.name}}</span>
                        </div>
                        <div class="text-right p-4">{{index + 1}}</div>
                        <div class="text-right flex justify-end items-center">
                          <router-link :to="{name: 'ViscosityEdit', params: {id: element.viscosity_id}}" class="py-2 px-3 text-sm font-medium text-center">
                            <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                              <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                            </svg>
                          </router-link>
                        </div>
                      </div>
                    </template>
                  </draggable>
                </div>
              </div>
            </div>

            <div class="flex w-full justify-between border-t">
              <div class="flex-col py-7 px-4">
                <template v-if="viscosityIds.length">
                  <div>
                    <button @click.prevent="deleteViscosity" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                      <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                      <span>Удалить</span>
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <span class="text-gray-500">Привязано</span> {{ entityStore.entity.viscosity.length }}
                    <span class="ml-24 text-green-400 text-sm" v-if="isShowAddViscosityMessage">Вязкость добавлена в общий список</span>
                    <span class="ml-24 text-red-400 text-sm" v-if="isShowDeleteViscosityMessage">Вязкость удалена из списка</span>
                    <span class="ml-24 text-green-400 text-sm" v-if="isShowUpdateSortViscositiesMessage">Изменения сохранены</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div id="add-viscosity" class="mb-5 bg-white rounded-lg border border-gray-200" v-show="isShowAddViscosity">
          <div class="flex justify-between items-center p-7">
            <div>
              <h1 class="text-3xl font-medium">Добавить вязкость</h1>
            </div>
          </div>

          <div class="px-7 mb-3">
            <form @submit.prevent="searchViscosity()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">Поиск</label>
              <div class="relative w-100">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="searchViscosityModel"
                       type="text" id="top-bar-search"
                       class="border border-gray-200 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                       placeholder="Поиск по названию">
              </div>
            </form>
          </div>

          <div class="px-7">
            <div class="flex flex-col">
              <spinner v-if="isViscosityLoading && !loadDataQualities"></spinner>
              <table class="min-w-full table-fixed mb-7" v-if="!isViscosityLoading">
                <tbody class="bg-white">
                <tr v-for="viscosityItem of entityStore.viscosity" :key="viscosityItem.id" @click="viscosityCheckbox(viscosityItem.id)" class="border border-gray-200 cursor-pointer hover:bg-gray-100">
                  <td class="p-4">{{ viscosityItem.name }}</td>
                  <td class="text-right pr-2">
                    <input v-model="addViscosityIds" :value="viscosityItem.id" aria-describedby="checkbox-1" type="checkbox"
                           class="opacity-0 overflow-hidden absolute w-px h-pxopacity-0 overflow-hidden absolute w-px h-px">

                    <div class="py-2 px-3 text-sm font-medium text-center inline-block">
                      <template v-if="addViscosityIds.includes(viscosityItem.id)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.38807 12L11.3881 14L15.3881 10M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#31C48D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </template>
                      <template v-else>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </template>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex w-full justify-between border-t border-b border-gray-200">
            <div class="flex-col p-7 w-1/2">
              <div>
                <span class="text-gray-500">Добавлено</span> {{ addViscosityIds.length }}
              </div>
            </div>
          </div>

          <div class="p-7 flex justify-between">
            <div class="flex justify-end">
              <button @click.prevent="isShowAddViscosity = false" class="flex px-7 py-4 bg-gray-100 rounded-md text-black font-medium hover:bg-blue-500 hover:text-white">
                <span>Отменить</span>
              </button>

              <button @click.prevent="addViscosity" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium ml-4 hover:bg-blue-500">
                <span>Сохранить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal-drag v-if="showDialog" :id="entityStore.entity.id" @closeDialog="closeDialog">
    <template #title>Приоритет в рамках группы и сегмента</template>
    <template #body>
      <spinner v-if="loadDataQualities"></spinner>
      <form v-if="!loadDataQualities && entityStore.entity" action="#">
        <div class="w-5/6 text-sm font-light text-gray-400 pt-0 p-7">Для установки приоритетов расставьте спецификации в&nbsp;нужном порядке методом Drag-and-Drop</div>
        <div class="flex justify-between bg-gray-50 border border-gray-200 px-11 py-3.5">
          <span class="text-left font-light text-gray-400">Наименование</span>
          <span class="text-left font-light text-gray-400">Приоритет</span>
        </div>
        <div class="bg-white divide-y divide-gray-50 max-h-96 overflow-y-auto">
          <draggable v-model="qualitiesOptions" item-key="id" class="transition-colors" @choose="deactivateDragQualitiesMessage">
            <template #item="{element, index}">
              <div class="grid grid-cols-2 px-7 py-3.5 border border-gray-200 border-t-0 active:!bg-gray-100"
                   :class="classDraggableElement(element.id, route.params.id)">
                <div class="p-4">
                  <span>{{element.name}}</span>
                </div>
                <div class="text-right p-4">{{index + 1}}</div>
              </div>
            </template>
          </draggable>
        </div>
        <div class="w-2/3 p-7">
          Спецификаций в группе: {{ qualitiesOptions.length }}
          <span class="ml-4 text-green-400 text-sm" v-if="isShowUpdateQualitiesSortMessage">Изменения сохранены</span>
        </div>

      </form>
    </template>
  </modal-drag>
</template>

<style>
  .sortable-ghost {
    @apply bg-gray-100
  }
</style>

<script setup>
import draggable from 'vuedraggable'
import {useSpecificationStore} from "../../stores/SpecificationStore.js";
import {useNewsStore} from "../../stores/NewsStore.js";
import Spinner from "../flowbite/spinner.vue";
import ModalDrag from "../flowbite/modalDrag.vue";
import {computed, reactive, ref, watch} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import InputTextArea from "../flowbite/form/inputTextArea.vue";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {required} from '../../utilits/custom-validators.js'
import {scrollToElement} from "../../utilits/functions.js";

const newsStore = useNewsStore();
const entityStore = useSpecificationStore();
entityStore.error = null;

const showDialog = ref(false);
const loadData = ref(true);

const isViscosityLoading = ref(false);
const searchViscosityModel = ref('');

const qualityGroupOptions = ref([]);
const groupOptions = ref([]);
const segmentOptions = ref([]);
const viscosityOptions = ref([]);
const qualitiesOptions = ref([]);

const loadDataQualities = ref(false);

const isShowAddViscosity = ref(false);

const isShowAddViscosityMessage = ref(false);
const isShowDeleteViscosityMessage = ref(false);
const isShowUpdateSortViscositiesMessage = ref(false);
const isShowUpdateQualitiesSortMessage = ref(false);

const addViscosityIds = ref([]);

const viscosityIds = ref([]);
const checkboxAllViscositySelected = ref(false);

// Первоначальные данные
const route = useRoute()
const router = useRouter()

const standardOptions = ref([
  {id: 'STANDARD', name: 'STANDARD'},
  {id: 'OEM', name: 'OEM'}
]);

const closeDialog = () => {
  showDialog.value = false;
  entityStore.getEntityById(route.params.id)
}

const openDialog = () => {
  isShowUpdateQualitiesSortMessage.value = false;
  loadDataQualities.value = true;
  showDialog.value = true
  saveEntity();
}

entityStore.getDirectories().then(() => {
  qualityGroupOptions.value = [...qualityGroupOptions.value, ...entityStore.qualityGroups];
  groupOptions.value = [...groupOptions.value, ...entityStore.group];
  segmentOptions.value = [...segmentOptions.value, ...entityStore.segment];
  if (route.params.id > 0) {
    entityStore.getEntityById(route.params.id).then(() => {
      viscosityOptions.value = entityStore.entity.viscosity
      loadData.value = false;
    })
  } else {
    entityStore.setEmptyEntity().then(() => {
      loadData.value = false;
    })
  }
})

// Валидация
const rules = computed(() => ({
  name: {required},
  qualityGroup: {required},
  group: {required},
  segment: {required},
  standard: {required},
  priority: {},
  description: {},
}));

let state = reactive({
  name: '',
  qualityGroup: '',
  group: '',
  segment: '',
  standard: '',
  priority: '',
  description: '',
});

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.qualityGroup = entityStore.entity.qualityGroup;
    state.priority = entityStore.entity.priority;
    state.group = entityStore.entity.group;
    state.segment = entityStore.entity.segment;
    state.standard = entityStore.entity.standard;
    state.description = entityStore.entity.description;

    viscosityOptions.value = entityStore.entity.viscosity
    qualitiesOptions.value = entityStore.qualities
  }
});

watch(viscosityOptions, (arr) => {
  entityStore.entity.viscosity = viscosityOptions.value
  entityStore.sendSortViscosity(viscosityOptions.value).then(() => {
    isShowAddViscosity.value = false;

    if (entityStore.isSortUpdated) {
      isShowUpdateSortViscositiesMessage.value = true;
      isShowAddViscosityMessage.value = false;
      isShowDeleteViscosityMessage.value = false;
    }

  })
})

watch(qualitiesOptions, (arr) => {
  entityStore.qualities = qualitiesOptions.value
  entityStore.sendSortQualities(qualitiesOptions.value).then(() => {

    if (entityStore.isQualitiesSortUpdated) {
      isShowUpdateQualitiesSortMessage.value = true;
    }

  })
})

const v$ = useVuelidate(rules, state)

// Работа с данными
const saveEntity = () => {
  entityStore.error = null;

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true;

    let formData = new FormData();

    for (let key in entityStore.entity) {

      if (entityStore.entity[key] === null) {
        formData.append(key, '');
      } else {
        formData.append(key, entityStore.entity[key]);
      }

    }

    let isNew = entityStore.entity.id === undefined;

    entityStore.storeEntity(formData).then(() => {

      if (route.params.id > 0) {
        let params = {
          segment: entityStore.entity.segment,
          standard: entityStore.entity.standard,
          group: entityStore.entity.group,
        }

        entityStore.getQualitiesToSort(params).then(() => {
          loadDataQualities.value = false;
        })
      }

      if (!entityStore.error && isNew) {
        newsStore.getNewsCount();
        router.push({name: 'Specification', params: {id: entityStore.entity.id}})
      }

      loadData.value = false;
    });

    v$.value.$reset();
  }
}

const searchViscosity = () => {
  isViscosityLoading.value = true;

  let params = {
    search: searchViscosityModel.value,
  }

  entityStore.getViscosity(params).then(() => {
    isViscosityLoading.value = false;
  });
}

const viscosityCheckbox = (id) => {
  if (addViscosityIds.value.includes(id)) {
    addViscosityIds.value = addViscosityIds.value.filter(item => (item !== id))
  } else {
    addViscosityIds.value.push(id)
  }
}

const showAddViscosity = () => {
  isShowAddViscosity.value = true;

  setTimeout(() => {
    scrollToElement('add-viscosity')
  }, 100)
}

const addViscosity = () => {
  entityStore.addViscosity(entityStore.entity, addViscosityIds.value).then(() => {
    addViscosityIds.value = [];

    isShowAddViscosity.value = false;
    isShowAddViscosityMessage.value = true;
    isShowDeleteViscosityMessage.value = false;
    isShowUpdateSortViscositiesMessage.value = false;

    scrollToElement('viscosity')
  })
}

const deleteViscosity = () => {
  entityStore.deleteViscosity(entityStore.entity, viscosityIds.value).then(() => {
    viscosityIds.value = []

    isShowAddViscosityMessage.value = false;
    isShowDeleteViscosityMessage.value = true;
    isShowUpdateSortViscositiesMessage.value = false;
  })
}

// Галочка выбрать всё
const viscositySelectAllCheckbox = () => {
  viscosityIds.value = [];

  if (!checkboxAllViscositySelected.value) {
    entityStore.entity.viscosity.forEach((viscosity, index) => {
      viscosityIds.value.push(viscosity.id)
    });
  }
}

const classDraggableElement = (elementId, routeId) => ({
  'bg-[#FFF9E9]' : elementId == routeId,
});

const deactivateDragQualitiesMessage = () => {
  isShowUpdateQualitiesSortMessage.value = false;
}

const deactivateDragViscositiesMessage = () => {
  isShowUpdateSortViscositiesMessage.value = false;
}

</script>