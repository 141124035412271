<template>
  <div class="px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- Техника -->
      <div class="mb-4 h-full bg-gray-50 border border-gray-300 rounded-lg flex flex-col">
        <!-- Header -->
        <div class="flex justify-between items-center mb-4 p-2 border-b border-gray-300">
          <div>
            <h3 class="mb-2 text-xl font-bold text-gray-900">Реестр техники</h3>
          </div>
          <div class="flex-shrink-0" v-if="authStore.hasRole('technic')">
            <router-link :to="{name: 'TechnicCreate'}" class="p-2 text-sm font-medium rounded-lg text-blue-700 border border-blue-700 hover:bg-gray-100">
              &plus;Добавить технику
            </router-link>
          </div>
        </div>
        <!-- Search -->
        <div class="p-2 pl-4 border-b border-gray-300">
          <div class="items-center mb-3 flex flex-col w-full">
            <div class="flex mb-3 pl-4 pr-4 w-full">
              <div class="flex-col w-1/3 mr-4">
                <input-select-lite v-model="category" :options="categoryOptions" :placeholder="'Категория'"/>
              </div>
              <div class="flex flex-row w-1/3 mr-4">
                <VueDatePicker class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg"
                               v-model="start" locale="ru" auto-apply year-picker model-type="yyyy" :format="'yyyy'" placeholder="Начало производства" :enable-time-picker="false"></VueDatePicker>
              </div>
              <div class="flex flex-row w-1/3 mr-4">
                <VueDatePicker class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg"
                               v-model="end" locale="ru" auto-apply year-picker model-type="yyyy" :format="'yyyy'" placeholder="Конец производства" :enable-time-picker="false"></VueDatePicker>
              </div>
            </div>
            <div class="flex mb-3 pl-4 pr-4 w-full">
              <div class="flex-col w-1/3 mr-4">
                <input-select-lite v-model="country" :options="countryOptions" :placeholder="'Страна'"/>
              </div>
              <div class="flex-col w-1/3 mr-4">
                <input-select-lite v-model="make" :options="makeOptions" :placeholder="'Марка'"/>
              </div>
              <div class="flex-col w-1/3 mr-4">
                <input-select-lite v-model="fuel" :options="fuelOptions" :placeholder="'Тип топлива'"/>
              </div>
            </div>
            <form method="GET" class="lg:block pl-4 pr-4 w-full">
              <div class="relative">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor"
                       viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="searchInput"
                       type="text"
                       placeholder="Поиск техники"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5">
              </div>
            </form>
          </div>
        </div>
        <!-- Table -->
        <div class="overflow-x-auto border-b">
          <div class="inline-block min-w-full align-middle height-rows-area">
            <table class="min-w-full divide-y divide-gray-300 table-fixed">
              <thead class="bg-gray-100 sticky top-0">
              <tr>
                <th scope="col" class="p-4">
                  <div class="flex items-center">
                    <input v-model="checkboxAllSelected"
                           @click="selectAllCheckbox"
                           id="checkbox-all" type="checkbox"
                           class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                    <label for="checkbox-all" class="sr-only">checkbox</label>
                  </div>
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Наименование
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Кол-во узлов
                </th>
                <th scope="col" class="p-4"></th>
              </tr>
              </thead>

              <tbody v-if="loadData" class="divide-y divide-gray-200">
              <tr>
                <td class="pl-4 w-4 tr-btn" colspan="4">
                  <spinner></spinner>
                </td>
              </tr>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200">
              <tr v-for="technic of technicsStore.technics" :key="technic.id"
                  :class="{ 'selected-row': isActiveTechnic(technic.id), 'selected-for-delete': isSelectForDelete(technic.id), 'deleted': technic.deleted }">
                <td class="pl-4 w-4 tr-btn">
                  <div class="flex items-center">
                    <input v-if="technic.deleted" v-model="checkboxDeletedIds" :value="technic.id" type="checkbox"
                           class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                    <input v-else v-model="checkboxIds" :value="technic.id" type="checkbox"
                           class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                  </div>
                </td>
                <td @click.prevent="getDataComponents(technic)" class="text-base text-gray-900  tr-btn">
                  <p class="text-base font-medium text-gray-900">{{ technic.make }} {{ technic.name }}</p>
                  <p class="text-sm text-gray-500">{{ technic.model }} {{ technic.model_years }}</p>
                </td>
                <td @click.prevent="getDataComponents(technic)" class="tr-btn">
                  <p class="text-base font-medium text-gray-900">{{ technic.comp_types_count }}</p>
                </td>
                <td class="space-x-2 whitespace-nowrap text-center">
                  <router-link :to="{name: 'TechnicCreate', params: {id: technic.id}, query: {search: route.query.search, page: route.query.page}}">
                    <svg v-if="authStore.hasRole('technic')" class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                      <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                    </svg>
                    <svg v-else class="mr-2 w-5 h-5" fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 512 512" xml:space="preserve">
                      <g><path d="M497.938,430.063l-126.914-126.91C389.287,272.988,400,237.762,400,200C400,89.719,310.281,0,200,0
                        C89.719,0,0,89.719,0,200c0,110.281,89.719,200,200,200c37.762,0,72.984-10.711,103.148-28.973l126.914,126.91
                          C439.438,507.313,451.719,512,464,512c12.281,0,24.563-4.688,33.938-14.063C516.688,479.195,516.688,448.805,497.938,430.063z
                           M64,200c0-74.992,61.016-136,136-136s136,61.008,136,136s-61.016,136-136,136S64,274.992,64,200z"/></g>
                    </svg>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Footer -->
        <div class="flex justify-between items-center pt-3">
          <div class="flex-col p-3 w-1/3">
            <template v-if="searchInput && !loadData">
              <span class="text-gray-500 leading-10">Найдено</span> {{ technicsStore.technicsTotal }}
            </template>
          </div>
          <div class="flex-col p-3 w-2/3 text-right">
            <button v-if="checkboxDeletedIds.length" @click="revertTechnics()" type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blu-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center ">
              <svg class="mr-2 -ml-1 w-5 h-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"></path>
              </svg>
              Восстановить
            </button>
            <button v-if="checkboxIds.length" @click="deleteTechnics()" type="button"
                    class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center ">
              <svg class="mr-2 -ml-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
              </svg>
              Удалить
            </button>

            <nav v-if="!checkboxIds.length && !checkboxDeletedIds.length && technicsStore.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of technicsStore.technicsPaginateLinks">
                  <a v-if="link.url" href="#"
                     @click.prevent="technicsPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <!-- Узлы -->
      <div class="mb-4 h-full bg-white border border-gray-200 rounded-lg">
        <!-- Header -->
        <div class="flex justify-between items-center mb-4 p-2 border-b border-gray-300">
          <div>
            <h3 class="mb-2 text-xl font-bold text-gray-900">
              {{ technicsStore.currentTechnic ? 'Узлы ' + technicsStore.currentTechnic.name : '...' }}
            </h3>
          </div>
          <div v-if="technicsStore.currentTechnic && authStore.hasRole('technic')" class="flex-shrink-0">
            <router-link :to="{name: 'ComponentCreate', params: {technicId: technicsStore.currentTechnic.id}}" href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 border border-blue-700 hover:bg-gray-100">
              &plus;Добавить узел
            </router-link>
          </div>
        </div>
        <!-- Search -->
        <div class="p-2 border-b border-gray-300">
          <div class="items-center mb-3 flex w-full">
            <form @keyup="searchComponents" action="#" method="GET" class="lg:block pl-4 pr-4 w-full">
              <div class="relative">
                <div class="relative" :class="{ 'z-50': suggestionsComponents }">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500" fill="currentColor"
                         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <input :disabled="!technicsStore.currentTechnic" v-model="searchInputComponents"
                         type="text"
                         placeholder="Поиск узлов"
                         class="bg-gray-50 border border-gray-300 text-gray-800 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5">
                </div>

                <!-- Подсказка для поиска -->
                <div v-if="suggestionsComponents" class="absolute z-50 top-100 w-full border bg-white shadow-xl rounded">
                  <div class="divide-y min-h-fit max-h-96 overflow-y-auto">
                    <table class="min-w-full divide-y divide-gray-300 table-fixed">
                      <tbody class="divide-y divide-gray-200">
                      <tr v-for="component of technicsStore.searchedComponents" :key="component.id">
                        <td class="p-4 text-base text-gray-900">
                          {{ component.name }} <span v-if="component.code">({{ component.code }})</span><br/>
                          <span class="text-gray-400 text-sm">{{ component.category }}</span>
                        </td>
                        <td class="p-4 space-x-2 whitespace-nowrap text-right">
                          <button @click="attachComp(technicsStore.currentTechnic.id, component.id)" type="button" class="py-2 px-3 text-sm font-medium text-center">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="p-2 pt-4 pb-4 text-gray-500 border-t border-gray-300 flex justify-between">
                    <div>
                      Найдено {{ technicsStore.searchedComponents.length }}
                    </div>
                    <div class="flex-shrink-0">
                      <router-link :to="{name: 'ComponentCreate', params: {technicId: technicsStore.currentTechnic.id}}" href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">
                        &plus;Добавить узел
                      </router-link>
                    </div>
                  </div>
                </div>
                <div v-if="suggestionsComponents" @click="closeSuggestion" tabindex="-1" class="z-40 flex bg-gray-900 bg-opacity-50 overflow-hidden fixed top-0 right-0 left-0 w-full h-full"></div>
                <!-- -->
              </div>
            </form>
          </div>
        </div>
        <!-- Table -->
        <div class="overflow-x-auto border-b">
          <div class="inline-block min-w-full align-middle height-rows-area">
            <table class="min-w-full divide-y divide-gray-300 table-fixed">
              <thead class="bg-gray-100 sticky top-0">
              <tr>
                <th scope="col" class="p-4">
                  <div class="flex items-center">
                    <input v-model="checkboxAllComponentsSelected"
                           @click="selectAllComponentsCheckbox"
                           type="checkbox"
                           class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                  </div>
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Наименование
                </th>
                <th scope="col" colspan="2" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Кол-во&nbsp;спецификаций
                </th>
              </tr>
              </thead>
              <tbody v-if="technicsStore.currentTechnic" class="divide-y divide-gray-200">
              <tr v-for="component of components" :key="component.id">
                <td class="pl-4 w-4">
                  <div class="flex items-center">
                    <input v-model="technicsStore.selectedComponent" :value="component.id" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300">
                  </div>
                </td>
                <td class="text-base text-gray-900">
                  <p class="text-base font-medium text-gray-900">{{ component.name }} <span v-if="component.code">({{ component.code }})</span></p>
                  <p class="text-sm text-gray-500">{{ component.category }}</p>
                </td>
                <td class="text-base text-gray-900">{{ component.recommendations_count }}</td>
                <td class="space-x-2 whitespace-nowrap text-center">
                  <router-link :to="{name: 'ComponentCreate', params: {id: component.id, technicId: technicsStore.currentTechnic.id}}" type="button" class="py-2 px-3 text-sm font-medium text-center">
                    <svg v-if="authStore.hasRole('technic')" class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                      <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                    </svg>
                    <svg v-else class="mr-2 w-5 h-5" fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 512 512" xml:space="preserve">
                      <g><path d="M497.938,430.063l-126.914-126.91C389.287,272.988,400,237.762,400,200C400,89.719,310.281,0,200,0
                        C89.719,0,0,89.719,0,200c0,110.281,89.719,200,200,200c37.762,0,72.984-10.711,103.148-28.973l126.914,126.91
                          C439.438,507.313,451.719,512,464,512c12.281,0,24.563-4.688,33.938-14.063C516.688,479.195,516.688,448.805,497.938,430.063z
                           M64,200c0-74.992,61.016-136,136-136s136,61.008,136,136s-61.016,136-136,136S64,274.992,64,200z"/></g>
                    </svg>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Footer -->
        <div class="flex justify-between items-center pt-3">
          <div class="flex-col p-7 w-1/3">
            <span class="text-gray-500">Найдено</span> {{ technicsStore.components.length }}
          </div>
          <div class="flex-col p-7 w-2/3 text-right">
            <button @click="detachComp(technicsStore.currentTechnic.id)" v-if="technicsStore.selectedComponent.length" type="button"
                    class="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              <svg class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"></path>
              </svg>
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>

import {useTechnicsStore} from "../../stores/TechnicsStore.js";
import {ref, watch} from "vue";
import Spinner from "../flowbite/spinner.vue";
import {useRoute, useRouter} from 'vue-router'
import {useAuthStore} from "../../stores/AuthStore.js";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import VueDatePicker from '@vuepic/vue-datepicker';

const authStore = useAuthStore();
const technicsStore = useTechnicsStore();
const loadData = ref(true);
const loadDataComponents = ref(false);
const searchInput = ref(null);
const checkboxAllSelected = ref(false);
const checkboxIds = ref([]);
const checkboxDeletedIds = ref([]);
const checkboxAllComponentsSelected = ref(false);

const category = ref('');
const start = ref('');
const end = ref('');
const country = ref('');
const make = ref('');
const fuel = ref('');

const categoryOptions = ref([]);
const countryOptions = ref([]);
const makeOptions = ref([]);
const fuelOptions = ref([]);

const searchInputComponents = ref(null);
const suggestionsComponents = ref(false);
const components = ref([]);
const page = ref('');

const route = useRoute()
const router = useRouter()

const getTechnics = (url) => {
  loadData.value = true;

  let params = {
    category: category.value,
    start:  start.value,
    end:  end.value,
    country:  country.value,
    make:  make.value,
    fuel:  fuel.value,
    search: searchInput.value,
    page: page.value,
  }

  technicsStore.getTechnics(params, url).then(() => {
    loadData.value = false;
    checkboxIds.value = [];
    checkboxAllSelected.value = false;

    router.push({query: {
        category: category.value,
        start: start.value,
        end: end.value,
        country: country.value,
        make: make.value,
        fuel: fuel.value,
        page: technicsStore.technicsCurrentPage,
        search: searchInput.value,
      }});
  });
}

//Первоначальные данные
technicsStore.getDirectories().then(() => {
  categoryOptions.value = [...categoryOptions.value, ...technicsStore.categories];
  countryOptions.value = [...countryOptions.value, ...technicsStore.countries];
  fuelOptions.value = [...fuelOptions.value, ...technicsStore.fuels];
});

watch([searchInput, category, start, end, country, make, fuel], () => {
  search()
})

category.value = route.query.category;
start.value = route.query.start;
end.value = route.query.end;
country.value = route.query.country;
make.value = route.query.make;
fuel.value = route.query.fuel;
page.value = route.query.page;
searchInput.value = route.query.search;

//Марки в фильтре зависят от страны
technicsStore.getMakesByCountry({country: country.value}).then(() => {
  makeOptions.value = [];
  makeOptions.value = [...makeOptions.value, ...technicsStore.makes];
});

watch([country], () => {
  make.value = ''

  let params = {
    country:  country.value,
  }

  technicsStore.getMakesByCountry(params).then(() => {
    makeOptions.value = [];
    makeOptions.value = [...makeOptions.value, ...technicsStore.makes];
  });
})


if (technicsStore.currentTechnic !== null) {
  technicsStore.getComponentsByTechnicId(technicsStore.currentTechnic.id).then((r) => {
    components.value = r.data.data.items;
  });
}


//Галочки выбрать всё
const selectAllCheckbox = () => {
  checkboxIds.value = [];

  if (!checkboxAllSelected.value) {
    technicsStore.technics.forEach((item, index) => {
      if (!item.deleted) {
        checkboxIds.value.push(item.id)
      }
    });
  }
}

const selectAllComponentsCheckbox = () => {
  technicsStore.selectedComponent = [];

  if (!checkboxAllComponentsSelected.value) {
    technicsStore.components.forEach((item) => {
      technicsStore.selectedComponent.push(item.id)
    });
  }
}

//Работа с данными
const technicsPaginate = (url) => {
  if (url) {
    page.value = null;
    getTechnics(url);
  }
}


let timeoutSearch = null;
const search = () => {
  clearTimeout(timeoutSearch);

  //Очищаем правую вкладку
  searchInputComponents.value = null;
  suggestionsComponents.value = false;
  technicsStore.currentTechnic = null;
  technicsStore.components = [];
  technicsStore.searchedComponents = [];

  timeoutSearch = setTimeout(() => {
    page.value = null;
    getTechnics();
  }, 800);
};

let selectComponent = ref(null);

const getDataComponents = (item) => {
  selectComponent.value = item.id;
  loadDataComponents.value = true;
  technicsStore.getComponentsByTechnicId(item.id).then((r) => {
    components.value = r.data.data.items;
    loadDataComponents.value = false;
    technicsStore.currentTechnic = item;
    technicsStore.selectedComponent = [];
  });
}

let timeoutSearchComponents = null;
const searchComponents = () => {
  clearTimeout(timeoutSearchComponents);

  suggestionsComponents.value = false;

  let params = {
    search: searchInputComponents.value,
  }

  timeoutSearchComponents = setTimeout(() => {

    technicsStore.getComponents(params).then(async (r) => {
      suggestionsComponents.value = true;
    })

  }, 800);

}

const deleteTechnics = () => {
  technicsStore.deleteTechnics({ids: checkboxIds.value}).then(() => {
    technicsStore.getTechnics(queryParams);
    checkboxIds.value = [];
    technicsStore.currentTechnic = null;
  })


}

const revertTechnics = () => {
  technicsStore.revertTechnics({ids: checkboxDeletedIds.value}).then(() => {
    technicsStore.getTechnics(queryParams);
    checkboxDeletedIds.value = [];
    technicsStore.currentTechnic = null;
  })


}

const closeSuggestion = () => {
  searchInputComponents.value = null;
  suggestionsComponents.value = false;
  technicsStore.searchedComponents = [];

}

//Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === technicsStore.technicsPaginateLinks.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});

const isActiveTechnic = (id) => {
  return technicsStore.currentTechnic && id === technicsStore.currentTechnic.id;
};

const isSelectForDelete = (id) => {

  let res = false;

  checkboxIds.value.forEach((item) => {
    if (item === id) {
      res = true;
    }
  })

  return res;
};


const attachComp = (type_id, comp_type_id) => {
  technicsStore.attachComponents({
    type_id: type_id,
    comp_type_id: comp_type_id,
  }).then((r) => {
    technicsStore.searchedComponents = [];
    suggestionsComponents.value = false;
    technicsStore.getComponentsByTechnicId(selectComponent.value).then((r) => {
      components.value = r.data.data.items;
      loadData.value = true;
      technicsStore.getTechnics(queryParams).then(() => {
        loadData.value = false;
      });
    });
  })
};

const detachComp = (type_id) => {
  technicsStore.detachComponents({
    type: type_id,
    ids: technicsStore.selectedComponent
  }).then((r) => {
    technicsStore.searchedComponents = [];
    suggestionsComponents.value = false;
    technicsStore.getComponentsByTechnicId(selectComponent.value).then((r) => {
      components.value = r.data.data.items;
      loadData.value = true;
      technicsStore.getTechnics(queryParams).then(() => {
        loadData.value = false;
      });
    });
  })
}
</script>

<style scoped>
.tr-btn {
  cursor: pointer;
}
</style>