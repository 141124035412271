import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useCoolTypeStore = defineStore('coolTypeStore', () => {
    const entities = ref([]);
    const entity = ref(null);
    const pagination = ref(null);
    const error = ref(null);

    const getEntities = async (params = null, url = '/admin/cool-types') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getEntityById = async (id) => {
        await axios.get('/admin/cool-types/' + id)
            .then((r) => {
                entity.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeEntity = async (data) => {
        await axios.post('/admin/cool-types/store', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const deleteEntities = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/admin/cool-types', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const setEmptyEntity = async () => {
        entity.value = {
            name: '',
            group: '',
            priority: '',
            isDeleted: '',
        }
    };

    const resetEntity = async () => {
        entity.value = null;
    }

    const restoreEntities = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/admin/cool-types/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    return {
        pagination,
        entities,
        entity,
        error,
        setEmptyEntity,
        getEntities,
        getEntityById,
        storeEntity,
        deleteEntities,
        resetEntity,
        restoreEntities,
    }
})