<template>
  <label class="inline-flex relative items-center cursor-pointer">
    <input v-model="model" type="checkbox" value="" class="sr-only peer">
    <div
        class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
    <span class="ml-3 text-sm font-medium text-gray-900">{{ label }}</span>
  </label>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {type: [Boolean, Array]}
})

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const emit = defineEmits(['update:modelValue'])

</script>