const toggleSidebar = (expend) => {

    const sidebarEl = document.getElementById('sidebar');
    const mainContentEl = document.getElementById('main-content');
    const bottomMenuEl = document.querySelector('[sidebar-bottom-menu]');
    const sidebarDropdowns = document.querySelectorAll('[sidebar-dropdown]');

    if (expend === true) {
        sidebarEl.classList.add('lg:w-64');
        sidebarEl.classList.remove('lg:w-16');
        mainContentEl.classList.add('lg:ml-64');
        mainContentEl.classList.remove('lg:ml-16');

        document.querySelectorAll('#' + sidebarEl.getAttribute('id') + ' [sidebar-toggle-item]').forEach(sidebarToggleEl => {
            sidebarToggleEl.classList.remove('lg:hidden');
            sidebarToggleEl.classList.remove('lg:absolute');
        });

        document.querySelectorAll('#' + sidebar.getAttribute('id') + ' ul > li > a > span').forEach(e => {
            e.classList.remove('hidden')
        });

        sidebarDropdowns.forEach(e => {
            e.childNodes[0].childNodes[1].classList.remove('hidden')
            e.childNodes[0].childNodes[2].classList.remove('hidden')
        });

        bottomMenuEl.classList.remove('flex-col', 'space-y-4', 'p-2');
        bottomMenuEl.classList.add('space-x-4', 'p-4');
    } else {
        sidebarEl.classList.remove('lg:w-64');
        sidebarEl.classList.add('lg:w-16');
        mainContentEl.classList.remove('lg:ml-64');
        mainContentEl.classList.add('lg:ml-16');

        document.querySelectorAll('#' + sidebarEl.getAttribute('id') + ' [sidebar-toggle-item]').forEach(sidebarToggleEl => {
            sidebarToggleEl.classList.add('lg:hidden');
            sidebarToggleEl.classList.add('lg:absolute');
        });

        // toggle multi level menu item initial and full text
        document.querySelectorAll('#' + sidebar.getAttribute('id') + ' ul > li > ul').forEach(e => {
            e.classList.add('hidden');
        });

        document.querySelectorAll('#' + sidebar.getAttribute('id') + ' ul > li > a > span').forEach(e => {
            e.classList.add('hidden')
        });

        sidebarDropdowns.forEach(e => {
            e.childNodes[0].childNodes[1].classList.add('hidden')
            e.childNodes[0].childNodes[2].classList.add('hidden')
        });

        bottomMenuEl.classList.add('flex-col', 'space-y-4', 'p-2');
        bottomMenuEl.classList.remove('space-x-4', 'p-4');
    }
}

const toggleSidebarMobile = (expend) => {

    const sidebarEl = document.getElementById('sidebar');
    const sidebarBackdrop = document.getElementById('sidebarBackdrop');
    const toggleSidebarMobileHamburger = document.getElementById('toggleSidebarMobileHamburger');
    const toggleSidebarMobileClose = document.getElementById('toggleSidebarMobileClose');

    sidebarEl.classList.add('lg:w-64');
    sidebarEl.classList.remove('lg:w-16');
    mainContentEl.classList.add('lg:ml-64');
    mainContentEl.classList.remove('lg:ml-16');
}

export {toggleSidebar, toggleSidebarMobile};