<template>
  <label :class="{'text-red-900': isError()}" class="block mb-2 text-sm font-medium text-gray-900" v-if="label">{{ label }}</label>
  <select @change="changeValue"
          :value="modelValue"
          :class="classInput"
          class="border text-sm rounded-lg block w-full p-2.5">

    <option v-if="placeholder" value="">{{ placeholder }}</option>
    <option v-for="option of options" :disabled="typeof (option.id) == 'undefined'" :value="option.id">{{ option.name }}</option>
  </select>
</template>

<script setup>
import {toRefs, computed} from 'vue';

const props = defineProps({
  label: {type: String, default: ''},
  placeholder: {type: String, default: ''},
  options: Array,
  modelValue: {type: [String, Number], default: ''},
  v$: Object,
});

const {v$, modelValue, options, placeholder} = toRefs(props);
const emit = defineEmits(['update:modelValue'])

const changeValue = (event) => {
  emit('update:modelValue', event.target.value)
}

const isError = () => {
  return v$.value !== undefined && v$.value.$error;
}

const defaultClass = 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500';
const errorClass = 'bg-primary-50 bg-primary-50 border-primary-300 text-primary-900 focus:ring-primary-500';

const classInput = computed(() => ({
  [errorClass]: isError(),
  [defaultClass]: !isError(),
}));

</script>
