import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useUserStore = defineStore("userStore", () => {
    const users = ref([]);
    const roles = ref([]);
    const companies = ref([]);
    const user = ref(null);
    const error = ref(null);

    const getUsers = async (params = null) => {

        let config = {
            params: params,
        }

        await axios.get('/admin/users', config)
            .then((r) => {
                users.value = r.data.data.users;
                roles.value = r.data.data.roles;
                companies.value = r.data.data.companies;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getUserById = async (id) => {
        await axios.get('/admin/user/' + id)
            .then((r) => {
                user.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeUser = async (data, params) => {
        await axios.post('/admin/user', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    user.value = r.data.data;
                    getUsers(params);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const deleteUsers = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/admin/users', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    user.value = r.data.data;
                    getUsers();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const setEmptyUser = async () => {
        user.value = {
            active: false,
            name: '',
            surname: '',
            comment: '',
            email: '',
            phone: '',
            companies: companies.value,
            roles: roles.value
        }
    };

    const resetUser = async () => {
        user.value = null;
    }

    const restoreUsers = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/admin/users/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getUsers();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    return {
        users,
        user,
        companies,
        roles,
        error,
        setEmptyUser,
        resetUser,
        getUsers,
        getUserById,
        storeUser,
        deleteUsers,
        restoreUsers,
    }
})