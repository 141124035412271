<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">Логи изменений</h1>
          </div>
        </div>

        <div class="flex w-full justify-between">
            <div class="flex-col p-7 w-full">
                <div class="flex w-full">
                    <div class="flex-col w-1/4 mr-4">
                        <input-select-lite v-model="user" :options="userOptions" :placeholder="'Пользователь'"/>
                    </div>
                    <div class="flex-col w-1/4 mr-4">
                        <input-select-lite v-model="revisionType" :options="revisionTypeOptions" :placeholder="'Тип объекта'"/>
                    </div>
                    <div class="flex flex-row w-1/4 mr-4">
                        <VueDatePicker class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg"
                                       v-model="start" locale="ru" auto-apply model-type="yyyy-MM-dd" :format="'dd.MM.yyyy'" placeholder="Дата начала" :enable-time-picker="false"></VueDatePicker>
                    </div>
                    <div class="flex flex-row w-1/4 mr-4">
                        <VueDatePicker class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg"
                                       v-model="end" locale="ru" auto-apply model-type="yyyy-MM-dd" :format="'dd.MM.yyyy'" placeholder="Дата конца" :enable-time-picker="false"></VueDatePicker>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Дата и время
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Объект
                    </th>

                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Действие
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="entity of entityStore.entities" :key="entity.id" :class="{'bg-neutral-100 opacity-50': entity.isDeleted}" class="hover:bg-gray-100">
                    <td class="p-4">{{ entity.updated_at }}</td>
                    <td class="p-4">
                        <p class="text-base font-medium text-gray-900">{{ entity.revisionable_name }}</p>
                        <p class="text-sm text-gray-500">{{ entity.revisionable_type }} #{{ entity.revisionable_id }}</p>
                    </td>
                    <td class="p-4"><span v-if="entity.user">"{{ entity.user.name }} {{ entity.user.surname }}"</span> изменил "{{ entity.field }}" с "{{ entity.old_value }}" на "{{ entity.new_value }}"</td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !entityStore.entities.length" class="text-xl text-center p-7">Не найдено</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <div v-if="entityStore.pagination">
              <span class="text-gray-500">Найдено</span> {{ entityStore.pagination.total }}
            </div>
          </div>
          <div class="flex-col p-7 w-1/2 text-right">
            <nav v-if="entityStore.pagination && entityStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of entityStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="entityPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useLogStore} from "../../stores/LogStore.js";
import Spinner from "../flowbite/spinner.vue";
import {ref, watch} from "vue";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const entityStore = useLogStore();
const loadData = ref(true);
const checkboxAllSelected = ref(false);
const user = ref('');
const revisionType = ref('');
const start = ref('');
const end = ref('');
const userOptions = ref([]);
const revisionTypeOptions = ref([
    {id: 'App\\Models\\Product', name: 'Продукты'},
    {id: 'App\\Models\\Quality', name: 'Спецификации'},
    {id: 'App\\Models\\Make', name: 'Марки'},
    {id: 'App\\Models\\CarModel', name: 'Модели'},
    {id: 'App\\Models\\Type', name: 'Модификации'},
    {id: 'App\\Models\\Company', name: 'Компании'},
    {id: 'App\\Models\\ApiKey', name: 'Ключи АПИ'},
    {id: 'App\\Models\\User', name: 'Пользователи'},
    {id: 'App\\Models\\UserCompany', name: 'Связки пользователей с компаниями'},
    {id: 'App\\Models\\UserRole', name: 'Роли пользователей'},
    {name: '----------------------------'},
    {id: 'App\\Models\\Attribute', name: 'Аттрибуты'},
    {id: 'App\\Models\\Viscosity', name: 'Вязкости'},
    {id: 'App\\Models\\CategoryGroup', name: 'Группы категорий'},
    {id: 'App\\Models\\QualityGroup', name: 'Группы спецификаций'},
    {id: 'App\\Models\\Measure', name: 'Единицы измерения'},
    {id: 'App\\Models\\Manufacturer', name: 'Заводы'},
    {id: 'App\\Models\\Note', name: 'Заметки'},
    {id: 'App\\Models\\Interval', name: 'Интервалы'},
    {id: 'App\\Models\\Category', name: 'Категории'},
    {id: 'App\\Models\\CompCategory', name: 'Категории узлов'},
    {id: 'App\\Models\\Marketing', name: 'Маркетинговые предложения'},
    {id: 'App\\Models\\EngineBuild', name: 'Модификации двигателя'},
    {id: 'App\\Models\\Recommendation', name: 'Рекомендации'},
    {id: 'App\\Models\\MarketingProduct', name: 'Связи маркет. предл. и продукта'},
    {id: 'App\\Models\\TypeCompType', name: 'Связки модификаций и узлов'},
    {id: 'App\\Models\\Sector', name: 'Секторы'},
    {id: 'App\\Models\\QualityViscosity', name: 'Сцепки'},
    {id: 'App\\Models\\IntervalType', name: 'Типы интервалов'},
    {id: 'App\\Models\\CoolType', name: 'Типы охлаждения'},
    {id: 'App\\Models\\GearOperation', name: 'Типы привода'},
    {id: 'App\\Models\\DriveType', name: 'Типы трансмиссии'},
    {id: 'App\\Models\\CompType', name: 'Типы узлов'},
    {id: 'App\\Models\\Fuel', name: 'Топливо'},
    {id: 'App\\Models\\Uses', name: 'Условия эксплуатации'},

]);

// Первоначальные данные
entityStore.getDirectories().then(() => {
    userOptions.value = [...userOptions.value, ...entityStore.users];
    getLogs();
});

watch([user, revisionType, start, end], () => {
    getLogs()
})

const getLogs = () => {

    let params = {
        filter: {
            user: user.value,
            revisionType: revisionType.value,
            end: end.value,
            start: start.value,
        }
    }

    entityStore.getEntities(params).then(() => {
        loadData.value = false;
    });
}

// Работа с данными
const entityPaginate = (url) => {
  if (url) {
    loadData.value = true;

    let params = {
        filter: {
            user: user.value,
            revisionType: revisionType.value,
            end: end.value,
            start: start.value,
        }
    }

    entityStore.getEntities(params, url).then(() => {
      loadData.value = false;
    });
  }
}

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === entityStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>