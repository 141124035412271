<template>
  <div class="px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- Техника -->
      <div class="mb-4 h-full bg-gray-50 border border-gray-300 rounded-lg flex flex-col">
        <!-- Header -->
        <div class="flex justify-between items-center mb-4 p-4 border-b border-gray-300">
          <div>
            <h3 class="mb-2 text-xl font-bold text-gray-900">Сцепки для технического позиционирования</h3>
          </div>
        </div>
        <!-- Search -->
        <div class="p-4 py-0 px-6">
          <div class="items-center mb-3 w-full">
            <input-search
                v-model="qv"
                :suggestions="qvStore.qvOptions"
                @selected="handlerSelectedQv"
                @keyup="searchQV"
                label="Спецификация"/>
          </div>
        </div>
        <div class="p-4 py-0 px-6">
          <div class="items-center mb-3 w-full">
            <input-select-lite :options="brandOptions" v-model="brand" label="Бренд"/>
          </div>
        </div>
        <div class="p-4 py-0 px-6">
          <div class="items-center mb-3 w-full">
            <input-search
                v-model="product"
                :suggestions="qvStore.productOptions"
                @selected="handlerSelectedProduct"
                @keyup="searchProduct"
                label="Продукт"/>
          </div>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto border-b">
          <div class="inline-block min-w-full align-middle height-rows-area">
            <table class="min-w-full divide-y divide-gray-300 table-fixed">
              <thead class="bg-gray-100 sticky top-0">
              <tr>
                <th scope="col" class="p-4"></th>
                <th  @click.prevent="tableSort('name')" scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400 cursor-pointer">
                  Наименование
                  <svg class="h-4 w-4 ml-1 inline-block" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" />
                  </svg>
                </th>
                <th @click.prevent="tableSort('products_all_count')"  scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400 cursor-pointer">
                  Продуктов
                  <svg class="h-4 w-4 ml-1 inline-block" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" />
                  </svg>
                </th>
                <th scope="col" class="p-4"></th>
              </tr>
              </thead>
              <tbody v-if="loadData" class="divide-y divide-gray-200" >
              <tr>
                <td class="pl-4 w-4 tr-btn" colspan="4">
                  <spinner></spinner>
                </td>
              </tr>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200">
              <tr v-for="qv of qvStore.qvs" :key="qv.id"
                  :class="{ 'selected-row': isActiveQv(qv.id) }">
                <td scope="col" class="p-4"></td>
                <td @click.prevent="getDataProducts(qv)" class="tr-btn">
                  <p class="text-base font-medium text-gray-700">{{ qv.name }}</p>
                </td>
                <td @click.prevent="getDataProducts(qv)" class="tr-btn">
                  <p class="text-base font-medium text-gray-700">{{ qv.products_count }}</p>
                </td>
                <td scope="col" class="p-4"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Footer -->
        <div class="flex justify-between items-center pt-3">
          <div class="flex-col p-3 w-1/3">
            <template v-if="!loadData">
              <span class="text-gray-500 leading-10">Найдено</span> {{ qvStore.pagination.total }}
            </template>
          </div>
          <div class="flex-col p-3 w-2/3 text-right">
            <nav v-if="qvStore.pagination && qvStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of qvStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="qvsPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <!-- Продукты - правая часть страницы -->
      <div class="mb-4 h-full bg-white border border-gray-200 rounded-lg">
        <!-- Header -->
        <div class="flex justify-between items-center p-4 border-b border-gray-300">
          <div>
            <h3 class="mb-2 text-xl font-bold text-gray-900">Приоритезация продуктов</h3>
          </div>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto border-b h-4/5">
          <div class="inline-block min-w-full align-middle height-rows-area">
            <div class="min-w-full">
              <div class="w-5/6 text-sm font-light text-gray-400 p-7">Для установки приоритетов расставьте продукты в&nbsp;нужном порядке методом Drag-and-Drop</div>
              <div class="border-y border-gray-300 bg-gray-100">
                <div class="grid grid-cols-viscosity">
                  <div class="p-4"></div>
                  <div class="p-4 text-left font-light text-gray-400">
                    Наименование
                  </div>
                  <div class="p-4 text-right font-light text-gray-400">
                    Приоритет
                  </div>
                  <div class=""></div>
                </div>
              </div>
              <div v-if="currentQv" class="divide-y divide-gray-200">
                <draggable v-model="products" item-key="id" class="transition-colors" @choose="deactivateProductMessage">
                  <template #item="{element, index}">
                    <div class="grid grid-cols-viscosity border-t-0 border-y border-gray-300 active:!bg-gray-100"
                         :class="{'bg-neutral-100 opacity-50': !element.status}">
                      <div class="p-4"></div>
                      <div class="p-4">
                        <span>{{element.name}}</span>
                      </div>
                      <div class="text-right p-4">{{index + 1}}</div>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- Footer -->
        <div class="flex justify-between items-center pt-3 h-20">
          <div class="flex-col p-7 w-2/3">
            <span class="text-gray-500">Найдено</span> {{ products.length }}
            <span class="ml-4 text-green-400 text-sm" v-if="isShowUpdateSortMessage">Изменения сохранены</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {useQvsStore} from "../../stores/QvsStore.js";
import Spinner from "../flowbite/spinner.vue";
import {ref, watch} from "vue";
import InputSearch from "../flowbite/form/inputSearch.vue";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import draggable from 'vuedraggable'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

const qvStore = useQvsStore();

const loadData = ref(true);

const brandOptions = ref([]);

const brand = ref('');
const product = ref('');
const qv = ref('');
const qvId = ref('');
const productId = ref('');
const products = ref([]);

const currentQv = ref(null);

const isShowUpdateSortMessage = ref(false);

const sortField = ref('name');
const sortOrder = ref(true);

//Первоначальные данные
qvStore.getDirectoriesOptions().then(() => {
  brandOptions.value = [...brandOptions.value, ...qvStore.brands];

  brand.value = brandOptions.value[0].id

  if (route.query.brand_id) {
    brand.value = route.query.brand_id
  }
});

const getQvs = (url) => {
  loadData.value = true;

  let params = {
    sortField: sortField.value,
    sortOrder: sortOrder.value,
    filter: {
      qv_id: qvId.value,
      product_id: productId.value,
      brand_id: brand.value,
    }
  }

  qvStore.getQvs(params, url).then(() => {
      loadData.value = false;
  });
}

watch(products, () => {
  if (currentQv.value !== null) {
    qvStore.products = products.value
    qvStore.sendSortProducts(currentQv.value, products.value).then(() => {
      if (qvStore.isSortUpdated) {
        isShowUpdateSortMessage.value = true;
      }
    })
  }
})

watch(brand, () => {
  product.value = '';
  currentQv.value = null;
  qvStore.products = [];
  isShowUpdateSortMessage.value = false;

  let params = {
    filter: {
      brand_id: brand.value,
    }
  }
  qvStore.getProducts(params)

  if (route.query.id) {
    productId.value = route.query.id
    product.value = qvStore.productOptions.find(elem => elem.id == productId.value).name
    router.replace({ query: {} })
  }

  getQvs();
})


watch(product, () => {
  if (product.value == '') {
    productId.value = '';
    getQvs();
  }
})

watch(qv, () => {
  if (qv.value == '') {
    qvId.value = '';
    getQvs();
  }
})

watch(qvStore, (qvStore) => {
  products.value = qvStore.products
});


// Работа с данными
const qvsPaginate = (url) => {
  if (url) {
    getQvs(url);
  }
}

const getDataProducts = (item) => {
  currentQv.value = item.id;

  let params = {
    filter: {
      brand_id: brand.value,
    }
  }

  qvStore.getProductsByQvId(item.id, params).then(() => {
    products.value = qvStore.products
  })
}

let timeoutProduct = null;
const searchProduct = (text) => {
  clearTimeout(timeoutProduct);

  let params = {
    search: text,
    filter: {
      brand_id: brand.value,
    }
  }

  timeoutProduct = setTimeout(() => {
    qvStore.getProducts(params)
  }, 400);
};

let timeoutQV = null;
const searchQV = (text) => {
  clearTimeout(timeoutQV);

  let params = {
    search: text,
  }

  timeoutQV = setTimeout(() => {
    qvStore.getQvsOptions(params)
  }, 400);
};


const handlerSelectedProduct = (id) => {
  productId.value = id;
  getQvs();
}

const handlerSelectedQv = (id) => {
  qvId.value = id;
  getQvs();
}

const deactivateProductMessage = () => {
  isShowUpdateSortMessage.value = false;
}

const isActiveQv = (id) => {
  return currentQv.value && id === currentQv.value;
};

const tableSort = (sortFieldItem) => {

    if (sortFieldItem == sortField.value) {
        sortOrder.value = !sortOrder.value;
    } else {
        sortField.value = sortFieldItem;
        sortOrder.value = true;
    }

    getQvs()
};

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === qvStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>