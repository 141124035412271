<template>
  <div class="flex flex-col gap-5">
    <div class="flex gap-3 w-2/3">
      <single-select @change="changePeriod()" v-model="period" title="Период" :elLists="periods" class="w-[20%]"/>

      <VueDatePicker
        class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg w-[30%]"
        v-model="dateStart"
        @update:model-value="changeDate()"
        locale="ru"
        auto-apply
        model-type="yyyy-MM-dd"
        :format="'dd.MM.yyyy'"
        placeholder="Дата начала"
        :enable-time-picker="false">
      </VueDatePicker>

      <VueDatePicker
        class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg w-[30%]"
        v-model="dateEnd"
        @update:model-value="changeDate()"
        locale="ru"
        auto-apply
        model-type="yyyy-MM-dd"
        :format="'dd.MM.yyyy'"
        placeholder="Дата конца"
        :enable-time-picker="false">
      </VueDatePicker>

      <single-select @change="changeDetail()" v-model="detail" title="Детализация" :elLists="details" class="w-[20%]"/>
    </div>

    <div class="flex gap-3">

      <div class="flex gap-3 w-[80%]" v-if="coverageFilter?.reactiveFilter && coverageFilter?.reactiveFilter.length">
        <multiple-select v-for="filter in coverageFilter.reactiveFilter" :filter="filter" class="flex-1"/>
      </div>

      <button class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100 w-fit" @click="coverageFilter.applyParamFilter">Применить</button>
      <button class="p-2 text-sm font-medium w-fit text-gray-500" @click="resetFilter">Сбросить фильтры</button>

    </div>

  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import SingleSelect from "./filter/singleSelect.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import MultipleSelect from "./filter/multipleSelect.vue";
import {CoverageFilterStore} from "../../stores/coverageFilterStore.js";
import {useRoute} from "vue-router";

const coverageFilter = CoverageFilterStore()

const route = useRoute()
const period = ref(null)
const detail = ref(null)
const dateStart = ref('')
const dateEnd = ref('')

const changeDetail = () => {
  coverageFilter.changeDetail(detail.value)
}

const changeDate = () => {
  period.value = ''
}

//Функция для отслеживания одиночных параметров
const changePeriod = () => {
  if (period.value) {
    let curPeriod = periods.find(el => Number(el.id) === Number(period.value))
    dateStart.value = curPeriod.dateStart;
    dateEnd.value = curPeriod.dateEnd;
  }
  else {
    dateStart.value = '';
    dateEnd.value = '';
  }
}

//Функция для получения даты
const getCurrentDate = (dayBack = 0, monthBack = 0, yearBack = 0) => {
  let date = new Date()
  date.setDate(date.getDate() - dayBack);
  date.setMonth(date.getMonth() - monthBack);
  date.setFullYear(date.getFullYear() - yearBack);

  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let year = date.getFullYear();
  return year + '-' + month + '-' + day
}

const resetFilter = () => {
  dateStart.value = ''
  dateEnd.value = ''
  coverageFilter.resetFilter()
}


watch([dateStart, dateEnd], () => {
  coverageFilter.changeDateFilter(dateStart.value, dateEnd.value)
})

onMounted(() => {
  Object.keys(route.query).forEach(key => {
    if (key === 'dateStart') dateStart.value = route.query[key]
    else if (key === 'dateEnd') dateEnd.value = route.query[key]
  })
})
// Данные которые зашиты на фронте и меняться не будут
const periods = [
  {
    id: 1,
    title: "День",
    dateEnd: getCurrentDate(1, 0, 0),
    dateStart: getCurrentDate(1, 0, 0)
  },
  {
    id: 2,
    title: "Неделю",
    dateEnd: getCurrentDate(1, 0, 0),
    dateStart: getCurrentDate(8, 0, 0)
  },
  {
    id: 3,
    title: "Месяц",
    dateEnd: getCurrentDate(1, 0, 0),
    dateStart: getCurrentDate(0, 1, 0)
  },
  {
    id: 4,
    title: "Год",
    dateEnd: getCurrentDate(1, 0, 0),
    dateStart: getCurrentDate(0, 0, 1)
  },
  {
    id: 5,
    title: "1 квартал",
    dateEnd: new Date().getFullYear()+'-03-31',
    dateStart: new Date().getFullYear()+'-01-01',
  },
  {
    id: 6,
    title: "2 квартал",
    dateEnd: new Date().getFullYear()+'-06-30',
    dateStart: new Date().getFullYear()+'-04-01',
  },
  {
    id: 7,
    title: "3 квартал",
    dateEnd: new Date().getFullYear()+'-09-30',
    dateStart: new Date().getFullYear()+'-07-01',
  },
  {
    id: 8,
    title: "4 квартал",
    dateEnd: new Date().getFullYear()+'-12-31',
    dateStart: new Date().getFullYear()+'-10-01',
  },
]
const details = [
  {
    id: 1,
    title: "День"
  },
  {
    id: 2,
    title: "Неделя"
  },
  {
    id: 3,
    title: "Месяц"
  },
  {
    id: 4,
    title: "Год"
  },
  {
    id: 5,
    title: "Квартал"
  }
]
</script>