import {createApp} from 'vue'
import {createPinia} from 'pinia'
import router from './router'
import './axios.js'
import i18n from './i18n.js';
import vClickOutside from "click-outside-vue3"

import './style.css'
import 'flowbite';

import {fakeBackend} from './utilits/fake-backend.js';

const DEMO_DATA = import.meta.env.VITE_DEMO_DATA;

if (DEMO_DATA !== undefined && DEMO_DATA === 'true') {
    fakeBackend();
}

import App from './App.vue'

createApp(App)
    .use(createPinia())
    .use(i18n)
    .use(router)
    .use(vClickOutside)
    .mount('#app')
