<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData" class="px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="mb-4 bg-gray-50 border border-gray-300 rounded-lg flex flex-col">
        <!-- Header -->
        <div class="flex justify-between items-center mb-4 p-2 border-b border-gray-300">
          <div>
            <h3 class="mb-2 text-xl font-bold text-gray-900">Характеристики узла</h3>
          </div>
        </div>
        <div class="p-2 h-full">
          <div class="">
            <input-select-default :v$="v$.selectedCategory" v-model="basicInput.selectedCategory" :options="categories" label="Категория узла"/>
          </div>
          <div class="">
            <input-text :v$="v$.inputComponentName" v-model="basicInput.inputComponentName" label="Наименование узла (RUS)"/>
          </div>
          <div class="">
            <input-text :v$="v$.inputComponentNameEng" v-model="basicInput.inputComponentNameEng" label="Наименование узла (ENG)"/>
          </div>
          <div class="">
            <input-text :v$="v$.inputComponentCode" v-model="basicInput.inputComponentCode" label="Код узла"/>
          </div>
          <div>
            <input-text type="number" v-model="attributeValues.lube_cap" :label="attributes.lube_cap.name"/>
          </div>
          <template v-if="basicInput.selectedCategory === 1">
            <div class="">
              <input-select-default v-model="attributeValues.fuel" :options="attributes.fuel.options" :label="attributes.fuel.name"/>
            </div>
            <div class="grid gap-4 mb-4 sm:grid-cols-2">
              <div class="">
                <input-select-default v-model="attributeValues.cool_type" :options="attributes.cool_type.options" label="Тип охлаждения"/>
              </div>
              <div class="">
                <input-select-default v-model="attributeValues.engine_build" :options="attributes.engine_build.options" :label="attributes.engine_build.name"/>
              </div>
              <div>
                <input-text v-model="attributeValues.cylinder_cap" :label="attributes.cylinder_cap.name"/>
              </div>
              <div>
                <input-text v-model="attributeValues.cylinder" :label="attributes.cylinder.name"/>
              </div>
              <div>
                <input-text v-model="attributeValues.valve" :label="attributes.valve.name"/>
              </div>
              <div>
                <input-text v-model="attributeValues.power_kw" :label="attributes.power_kw.name"/>
              </div>
              <div>
                <input-text v-model="attributeValues.power_hp" :label="attributes.power_hp.name"/>
              </div>
            </div>
          </template>
          <template v-if="basicInput.selectedCategory === 5">

            <div class="grid gap-4 mb-4 sm:grid-cols-2">
              <div class="col-span-2">
                <input-select-default v-model="attributeValues.gear_operation" :options="attributes.gear_operation.options" label="Тип трансмиссии"/>
              </div>
              <div>
                <input-text v-model="attributeValues.gears_fw" label="Количество передних передач"/>
              </div>
              <div>
                <input-text v-model="attributeValues.gears_rev" label="Количество задних передач"/>
              </div>
            </div>
          </template>
        </div>
        <div v-if="authStore.hasRole('technic')" class="flex justify-end items-center p-2 border-t border-gray-300">
          <div>
            <button @click.prevent="storeComp" type="submit"
                    class="text-white bg-blue-600 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Сохранить
              <spinner-mini v-if="storeCompLoaded"></spinner-mini>
            </button>
          </div>
        </div>
      </div>

      <!-- Спецификации -->
      <div v-if="id">
        <div class="mb-0 bg-white border border-gray-200 rounded-lg">
          <!-- Header -->
          <div class="flex justify-between items-center p-2 border-b border-gray-300">
            <div>
              <h3 class="mb-2 pl-4 text-xl font-bold text-gray-900">Спецификации к узлу</h3>
            </div>
            <div v-if="authStore.hasRole('technic')" class="flex-shrink-0">
              <a @click.prevent="createSpecification()" href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 border border-blue-700 hover:bg-gray-100">
                &plus;Добавить спецификацию
              </a>
            </div>
          </div>
          <!-- Table -->
          <div class="overflow-x-auto border-b">
            <div class="inline-block min-w-full align-middle h-80">
              <table class="min-w-full divide-y divide-gray-300 table-fixed">
                <thead class="bg-gray-100 sticky top-0">
                <tr>
                  <th scope="col" class="p-1 pl-4">
                    <div class="flex items-center">
                      <input v-model="checkboxAllSelected"
                             @click="selectAllCheckbox"
                             type="checkbox"
                             class="w-4 h-4 bg-gray-50 rounded border-gray-300">
                    </div>
                  </th>
                  <th scope="col" class="p-2 text-xs font-medium text-left text-gray-400">
                    Спецификация
                  </th>
                  <th scope="col" class="p-2 text-xs font-medium text-left text-gray-400">
                    Вязкость
                  </th>
                  <th scope="col" class="p-2 text-xs font-medium text-left text-gray-400">
                    Условия эксплуатации
                  </th>
                  <th scope="col" class="p-2 text-xs font-medium text-left text-gray-400">
                    Интервал замены
                  </th>
                  <th scope="col" class="p-2"></th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <tr v-for="spec of specifications" :key="spec.recommendation_id">
                  <td class="p-1 pl-4 w-4">
                    <div class="flex items-center">
                      <input v-model="checkboxIds" :value="spec.recommendation_id" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300">
                    </div>
                  </td>
                  <td class="p-1 text-base text-gray-900">
                    {{ spec.quality }}
                  </td>
                  <td class="p-1 text-base text-gray-900">
                    {{ spec.viscosity }}
                  </td>
                  <td class="p-1 text-base text-gray-900">
                    {{ spec.use }}
                  </td>
                  <td class="p-1 text-base text-gray-900">
                    <template v-for="interval of  spec.intervals" :key="interval.id">
                      {{ interval.value }} {{ interval.measure.name }}<br>
                    </template>
                  </td>
                  <td v-if="authStore.hasRole('technic')" class="p-1 space-x-2 whitespace-nowrap text-center">
                    <button @click.prevent="editSpec(spec)" type="button">
                      <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                        <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                      </svg>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Footer -->
          <div class="flex justify-between items-center">
            <div class="flex-col p-2 pl-4 w-1/3">
              <span class="text-gray-500">Привязано:</span> {{ specifications.length }}
            </div>
            <div v-if="authStore.hasRole('technic')" class="flex-col p-7 w-2/3 text-right">
              <button @click="detachSpec()" v-if="checkboxIds.length" type="button"
                      class="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                <svg class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"></path>
                </svg>
                Удалить
              </button>
            </div>
          </div>
        </div>

        <!-- Добавить/изменить Спецификацию -->
        <div v-if="showEditForm" class="mt-4 mb-4 bg-white border border-gray-200 rounded-lg">
          <!-- Header -->
          <div class="flex justify-between items-center p-2 border-b border-gray-300">
            <div>
              <h3 v-if="!currentSpec.recommendation_id" class="text-xl font-bold text-gray-900">Добавление спецификации</h3>
              <h3 v-else class="text-xl font-bold text-gray-900">Редактирование спецификации</h3>
            </div>
          </div>

          <div class="p-3 pt-2 border-b">
            <div class="grid gap-4 mb-2">
              <div class="">
                <input-search
                    :v$="v2$.quality_viscosity_id"
                    v-model="currentSpec.quality_viscosity"
                    :suggestions="suggestQualityViscosity"
                    @selected="handlerSelectedQualityViscosity"
                    @keyup="searchQualityViscosity"
                    label="Спецификация"/>
              </div>
            </div>
            <div class="grid gap-4 mb-2">
              <div class="">
                <input-search
                    :v$="v2$.use_id"
                    v-model="currentSpec.use"
                    :suggestions="suggestUse"
                    @selected="handlerSelectedUse"
                    @keyup="searchUse"
                    label="Условия эксплуатации"/>
              </div>
            </div>
            <div class="grid mb-2 sm:grid-cols-2">
              <div class="grid gap-1 mb-4 ">
                <div class="grid grid-cols-4 gap-2 ">
                  <div class="col-span-2">
                    <input-text
                        :v$="v2$.interval"
                        v-model="currentSpec.interval" label="Интервал замены 1" type="number"/>
                  </div>
                  <div>
                    <input-select-default
                        :v$="v2$.interval_measure"
                        v-model="currentSpec.interval_measure"
                        :options="specOptions.measures"
                        label="Ед. изм.">
                    </input-select-default>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-2  mb-4 ">
                  <div class="col-span-2">
                    <input-text
                        v-model="currentSpec.interval2" label="Интервал замены 2" type="number"/>
                  </div>
                  <div>
                    <input-select-default
                        v-model="currentSpec.interval_measure2"
                        :options="specOptions.measures"
                        label="Ед. изм.">
                    </input-select-default>
                  </div>
                </div>
              </div>
              <div class="grid sm:grid-cols-1">
                <div class="grid gap-4 sm:grid-cols-2 h-2">
                  <div v-if="isRange" class="">
                    <input-text v-model="currentSpec.temp1" type="number" label="Температура, от"/>
                  </div>
                  <div v-if="isRange" class="">
                    <input-text v-model="currentSpec.temp2" type="number" label="Температура, до"/>
                  </div>
                  <div v-if="!isRange" class="">
                    <input-text v-model="currentSpec.temp1" type="number" label="Температура"/>
                  </div>
                  <div v-if="!isRange">&nbsp;</div>
                </div>
                <div>
                  <label class="relative inline-flex items-center cursor-pointer">
                    <input v-model="isRange" type="checkbox" value="" class="sr-only peer">
                    <div
                        class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900">Диапазон</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="flex justify-end items-center p-2 border-t border-gray-300">
            <button @click.prevent="storeSpecification()" type="submit"
                    class="text-white bg-blue-600 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Сохранить
              <spinner-mini v-if="storeSpecLoaded"></spinner-mini>
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script setup>
import {ref, computed, reactive} from "vue";
import {useRoute, useRouter} from 'vue-router'
import {useTechnicsStore} from "../../stores/TechnicsStore.js";
import {useNewsStore} from "../../stores/NewsStore.js";
import InputSelectDefault from "../flowbite/form/inputSelectDefault.vue";
import InputText from "../flowbite/form/inputText.vue";
import SpinnerMini from "../flowbite/spinnerMini.vue";
import Spinner from "../flowbite/spinner.vue";
import InputSearch from "../flowbite/form/inputSearch.vue";
import useVuelidate from '@vuelidate/core'
import {required} from '../../utilits/custom-validators.js'
import {useAuthStore} from "../../stores/AuthStore.js";

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const technicsStore = useTechnicsStore();
const newsStore = useNewsStore();
let loaded = ref(false);
const loadData = ref(true);

// Комплектации машины types
let currentType = ref(null);

//ID Модификации
let id = ref(null);

let basicInput = ref({
  inputComponentName: null,
  inputComponentNameEng: null,
  inputComponentCode: null,
  selectedCategory: null
});

let attributeValues = ref({
  fuel: null,
  cylinder_cap: null,
  engine_build: null,
  cylinder: null,
  valve: null,
  power_kw: null,
  power_hp: null,
  gears_rev: null,
  gears_fw: null,
  cool_type: null,
  drive_type: null,
  gear_operation: null,
});

//Категории
const categories = ref([]);
const attributes = ref(null);

//Получаем категории
technicsStore.getCompCategories().then((r) => {
  categories.value = r.data.data;
})

//Получаем список атрибутов
technicsStore
    .getAttributes()
    .then((r) => {
      attributes.value = r.data.data;
    })
    .then(() => {
      if (route.params.id) {
        //Данные для редактирования
        updateData(route.params.id, route.params.technicId);
      } else {
        technicsStore.getTypeById(route.params.technicId).then((r) => {
          currentType.value = r.data.data;
          loadData.value = false;
        });

      }
    });


//Валидация
const rules = computed(() => ({
  selectedCategory: {required},
  inputComponentName: {required},
  inputComponentNameEng: {required},
}));


const v$ = useVuelidate(rules, basicInput)


//Сохраняем узел
let storeCompLoaded = ref(false);
const storeComp = () => {
  v$.value.$validate()

  if (!v$.value.$error) {
    storeCompLoaded.value = true;

    currentSpec.value.type_id = currentType.value.id;
    currentSpec.value.isRange = isRange.value;

    let data = {
      id: route.params.id ?? null,
      type_id: currentType.value.id,
      comp_category_id: basicInput.value.selectedCategory,
      name: basicInput.value.inputComponentName,
      name_eng: basicInput.value.inputComponentNameEng,
      code: basicInput.value.inputComponentCode,
      attributes: attributeValues.value,
    }

    technicsStore.storeCompType(data)
        .then((r) => {
          router.push({name: 'ComponentCreate', params: {technicId: route.params.technicId, id: r.data.data.id}})
          updateData(r.data.data.id, route.params.technicId)
          storeCompLoaded.value = false;
          searchUse('');
        });
  }
}


//Спецификации

let isRange = ref(true);
let showEditForm = ref(false);
let storeSpecLoaded = ref(false);

let specifications = ref([]);

const checkboxAllSelected = ref(false);
const checkboxIds = ref([]);

const selectAllCheckbox = () => {
  checkboxIds.value = [];

  if (!checkboxAllSelected.value) {
    specifications.value.forEach((item, index) => {
      checkboxIds.value.push(item.recommendation_id)
    });
  }
}

const createSpecification = () => {
  editSpec(null);
}

let currentSpec = ref({
  recommendation_id: null,
  quality_viscosity: null,
  quality_viscosity_id: null,
  type_id: null,
  use: null,
  use_id: null,
  interval: null,
  interval_measure: null,
  interval_measure_name: null,
  interval_type: null,
  interval2: null,
  interval_measure2: null,
  interval_measure_name2: null,
  interval_type2: null,
  temp1: null,
  temp2: null,
  isRange: null
});

let specOptions = ref({
  measures: [],
  use: []
});


//Валидация
const rules2 = computed(() => ({
  quality_viscosity_id: {required},
  use_id: {required},
  interval: {required},
  interval_measure: {required}
}));

const v2$ = useVuelidate(rules2, currentSpec)

const storeSpecification = () => {

  v2$.value.$validate()

  if (!v2$.value.$error) {
    storeSpecLoaded.value = true;

    currentSpec.value.type_id = currentType.value.id;
    currentSpec.value.isRange = isRange.value;

    technicsStore.storeSpecification(route.params.id, currentSpec.value)
        .then((r) => {
          updateSpec()
          newsStore.getNewsCount();
        });
  }
}


const editSpec = (spec) => {

  showEditForm.value = true;

  if (spec) {
    Object.keys(currentSpec.value).forEach(function (index) {
      currentSpec.value[index] = spec[index];
    });
  } else {
    Object.keys(currentSpec.value).forEach(function (index) {
      currentSpec.value[index] = null;
    });
  }

}

//Поле для поиска Спецификация
let suggestQualityViscosity = ref([]);
const handlerSelectedQualityViscosity = (id) => {
  currentSpec.value.quality_viscosity_id = id;
}
let timeoutModel = null;
const searchQualityViscosity = (text) => {
  clearTimeout(timeoutModel);
  timeoutModel = setTimeout(() => {

    technicsStore.getCatalogItems('quality-viscosity', text).then((r) => {
      suggestQualityViscosity.value = r.data.data;
    })

  }, 800);
};

searchQualityViscosity('');

//Поиск условий эксплуатации
let suggestUse = ref([]);
const handlerSelectedUse = (id) => {
  currentSpec.value.use_id = id;
}

let timeoutUse = null;
const searchUse = (text) => {
  clearTimeout(timeoutUse);
  timeoutUse = setTimeout(() => {

    technicsStore.getUsesForCompType(basicInput.value.selectedCategory, currentType.value.id, text).then((r) => {
      suggestUse.value = r.data.data;
    })

  }, 800);
};


const updateSpec = () => {
  technicsStore.getCompById(route.params.id, route.params.technicId).then((r) => {
    let data = r.data.data;
    specifications.value = data.component.specifications;
    editSpec(null)
    showEditForm.value = false;
    v2$.value.$reset()
  }).then((r) => {
    storeSpecLoaded.value = false;
  })
}

const updateData = (compId, technicId) => {

  technicsStore.getCompById(compId, technicId).then((r) => {
    let data = r.data.data;
    id.value = data.component.id
    basicInput.value.selectedCategory = data.component.comp_category_id
    basicInput.value.inputComponentName = data.component.name;
    basicInput.value.inputComponentNameEng = data.component.name_eng;
    basicInput.value.inputComponentCode = data.component.code;

    data.component.attributes.forEach((item, index) => {
      attributeValues.value[item.attribute_code] = item.value;
    });

    specifications.value = data.component.specifications;
    currentType.value = data.component.type;

    specOptions.value.measures = data.options.measures;

    loadData.value = false;

    searchUse('');

  })

}

const detachSpec = () => {

  technicsStore.detachSpec({ids: checkboxIds.value}).then((r) => {
    updateSpec()
  });
}
</script>

<style scoped></style>