<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-2 gap-7 px-7 pt-7">
      <div class="col-span-1 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200"
               :class="{'bg-edit': entityStore.entity.id, 'bg-add': !entityStore.entity.id}">
            <div>
              <h1 class="text-3xl font-medium">Характеристики</h1>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow">
                  <div v-if="entityStore.entity">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.name" label="Название (RUS)"/>
                            <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.name_eng" label="Название (ENG)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.sort" label="Сортировка"/>
                            <p v-if="v$.sort.$error" class="mt-2 text-sm text-red-600"> {{ v$.sort.$errors[0].$message }} </p>
                          </div>
                        </div>
                        <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ entityStore.error }}
                        </div>
                      </form>
                    </div>
                    <div class="p-7 flex justify-end">
                      <button @click.prevent="saveEntity" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                        <span>Сохранить</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCoolTypeStore} from "../../stores/CoolTypeStore";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {required} from '../../utilits/custom-validators.js'

const entityStore = useCoolTypeStore();
entityStore.error = null;

const loadData = ref(true);

const loadDataInModal = ref(false);

// Первоначальные данные
const route = useRoute()
const router = useRouter()

if (route.params.id > 0) {
  entityStore.getEntityById(route.params.id).then(() => {
    loadData.value = false
  })
} else {
  entityStore.setEmptyEntity().then(() => {
    loadData.value = false;
  })
}

// Валидация
const rules = computed(() => ({
  name: {required},
  sort: {required},
}));

let state = reactive({
  name: '',
  sort: '',
});

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.sort = entityStore.entity.sort;
  }
});

const v$ = useVuelidate(rules, state)

// Работа с данными
const saveEntity = () => {
  entityStore.error = null;

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true;

    let formData = new FormData();

    for (let key in entityStore.entity) {
      formData.append(key, entityStore.entity[key]);
    }

    let isNew = entityStore.entity.id === undefined;

    entityStore.storeEntity(formData).then(() => {
      if (!entityStore.error && isNew) {
        router.push({name: 'ViscosityEdit', params: {id: entityStore.entity.id}})
      }

      loadData.value = false;
    });

    v$.value.$reset();
  }
}
</script>