<template>
  <input :id=id
         v-model="model"
         type="checkbox" value="true" name="role_checkbox"

         class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-blue-600 checked:bg-blue-700 focus:ring-blue-500 focus:ring-2">

  <label :for=id :class="{'text-red-900': isError()}" class="ml-2 text-sm font-medium text-gray-900">{{ label }}</label>
</template>

<script setup>
import {computed, toRefs} from 'vue'

const props = defineProps({
  label: String,
  id: {type: [Number, String]},
  modelValue: {type: [Boolean, Array]},
  v$: Object,
})

const {v$} = toRefs(props);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
    if (v$.value !== undefined) {
      v$.value.$touch();
    }
  },
});

const emit = defineEmits(['update:modelValue'])

const isError = () => {
  return v$.value !== undefined && v$.value.$error;
}
</script>