import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useSpecificationStore = defineStore('specificationStore', () => {
    const entities = ref([]);
    const entity = ref(null);
    const qualityGroups = ref([]);
    const group = ref([]);
    const segment = ref([]);
    const viscosity = ref([]);
    const qualities = ref([]);
    const pagination = ref(null);
    const error = ref(null);
    const isSortUpdated = ref(false);
    const isQualitiesSortUpdated = ref(false);

    const getDirectories = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/qualities/directories', config)
            .then((r) => {
                qualityGroups.value = r.data.data.qualityGroups;
                group.value = r.data.data.group;
                segment.value = r.data.data.segment;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getEntities = async (params = null, url = '/admin/qualities') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getEntityById = async (id) => {
        await axios.get('/admin/quality/' + id)
            .then((r) => {
                entity.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getQualitiesToSort = async (params = null, url = '/admin/qualities/sortQualities') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                qualities.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeEntity = async (data) => {
        await axios.post('/admin/quality', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const deleteEntities = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/admin/qualities', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const setEmptyEntity = async () => {
        entity.value = {
            name: '',
            group: '',
            standard: '',
            segment: '',
            priority: '',
            isDeleted: '',
        }
    };

    const resetEntity = async () => {
        entity.value = null;
    }

    const getViscosity = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/qualities/viscosity', config)
            .then((r) => {
                viscosity.value = r.data.data.viscosity;
            }).catch((e) => {
                console.log(e);
            })
    };

    const addViscosity = async (entity, data) => {
        let params = {
            entity_id: entity.id,
            ids: data,
        }

        await axios.post('/admin/qualities/addViscosity', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getEntityById(entity.id);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const deleteViscosity = async (entity, data) => {
        let config = {
            params: {
                entity_id: entity.id,
                ids: data,
            },
        }

        await axios.delete('/admin/qualities/viscosity', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getEntityById(entity.id);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const restoreEntities = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/admin/qualities/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const sendSortViscosity = async (data) => {
        let params = {
            sort: data
        }

        await axios.post('/admin/qualities/sortQvs', params)
            .then((r) => {
                let response = r.data;
                if (response.success) {
                    isSortUpdated.value = response.data.isSortUpdated
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const sendSortQualities = async (data) => {
        let params = {
            sort: data
        }

        await axios.post('/admin/qualities/sortQualities', params)
            .then((r) => {
                let response = r.data;
                if (response.success) {
                    isQualitiesSortUpdated.value = response.data.isSortUpdated
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    return {
        pagination,
        entities,
        entity,
        qualityGroups,
        group,
        segment,
        viscosity,
        qualities,
        error,
        isSortUpdated,
        isQualitiesSortUpdated,
        getDirectories,
        getViscosity,
        setEmptyEntity,
        getEntities,
        getEntityById,
        storeEntity,
        deleteEntities,
        resetEntity,
        addViscosity,
        deleteViscosity,
        restoreEntities,
        sendSortViscosity,
        getQualitiesToSort,
        sendSortQualities,
    }
})