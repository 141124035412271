import {defineStore} from 'pinia'
import {ref} from 'vue';
import axios from "axios";
import {useRoute, useRouter} from "vue-router";

export const ComponentCoverageFilterStore = defineStore("componentCoverageFilter", () => {
    const route = useRoute()
    const router = useRouter()

    const isLoaded = ref(true)
    const isLoadedReactive = ref(true)
    const activeParamFilter = ref({})

    const total = ref(0)
    const infoFilter = ref([])
    const technics = ref([])
    const fullInfoFilter = ref([])
    const reactiveFilter = ref([])
    const coverageFile = ref()
    const technicsFile = ref()
    const pagination = ref(null);

    const getDefaultFilters = (query = {}) => {
        activeParamFilter.value = {}
        Object.keys(query).forEach(key => {
            activeParamFilter.value[key] = [route.query[key]].flat(3)
        })
        getInfoCoverage(Object.assign(activeParamFilter.value))
        getDataReactiveFilter(activeParamFilter.value)
    }

    const getCoverageFile = async () => {
        activeParamFilter.value = {}

        Object.keys(route.query).forEach(key => {
            activeParamFilter.value[key] = [route.query[key]].flat(3)
        })

        await axios.get('/admin/coverage/downloadCoverage', {
            params: activeParamFilter.value,
        })
            .then((response) => {
                coverageFile.value = response?.data?.data || undefined
            }).catch((error) => {
                console.log(error);
            })
    }

    const getTechnicsFile = async () => {
        activeParamFilter.value = {}

        Object.keys(route.query).forEach(key => {
            activeParamFilter.value[key] = [route.query[key]].flat(3)
        })

        await axios.get('/admin/coverage/downloadTechnics', {
            params: activeParamFilter.value,
        })
            .then((response) => {
                technicsFile.value = response?.data?.data || undefined
            }).catch((error) => {
                console.log(error);
            })
    }

    const getInfoCoverage = async (param = {}) => {
        isLoaded.value = false
        await axios.get('/admin/coverage/data', {
            params: param,
        })
            .then((response) => {
                if (response.data?.data && response.data?.data?.list.length) {
                    total.value = response.data?.data?.total
                    infoFilter.value = response.data?.data.list
                    technics.value = response.data?.data.technics
                    pagination.value = response.data.data.pagination;
                    fullInfoFilter.value = response.data?.data.list.map(a => Object.assign({}, a));
                    infoFilter.value.map(el => {
                        return {...el}
                    })
                }
                else {
                    total.value = 0
                    infoFilter.value = []
                    fullInfoFilter.value = []
                }
            }).catch((error) => {
                total.value = 0
                infoFilter.value = []
                fullInfoFilter.value = []
                console.log(error);
            })
        isLoaded.value = true
    }

    const getTechnics = async (params = {}, url = '/admin/coverage/technics') => {
        activeParamFilter.value = {}

        Object.keys(route.query).forEach(key => {
            if (key != 'page') {
                activeParamFilter.value[key] = [route.query[key]].flat(3)
            }
        })

        await axios.get(url, {
            params: Object.assign(activeParamFilter.value, params),
        })
            .then((response) => {
                if (response.data?.data) {
                    technics.value = response.data?.data.technics
                    pagination.value = response.data.data.pagination;
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const getDataReactiveFilter = async (param = {}) => {
        isLoadedReactive.value = false
        await axios.get('/admin/coverage/filters', {
            params: param,
        })
            .then((response) => {
                reactiveFilter.value = response.data.data
            }).catch((error) => {
                reactiveFilter.value = []
                console.log(error);
            })
        isLoadedReactive.value = true
    }

    //Обнуление фильтра
    const resetFilter = () => {
        router.push({ query: {} })
        getDefaultFilters()
    }

    //Применение выбранных параметорв фильтра
    const applyParamFilter = (url = '/admin/coverage/data') => {
        const query = Object.assign({}, activeParamFilter.value)
        router.push({query: query})
        getInfoCoverage(query, url)
    }

    //Активные параметры реактивного фильтра
    const addParamReactiveFilter = (code = '' , selects = []) => {
        activeParamFilter.value[code] = selects
        let flag = false
        Object.keys(activeParamFilter.value).forEach(key => {
            if (key !== 'fuel' && flag) {
                delete  activeParamFilter.value[key]
            }
            if (key === code) flag = true
        })
        getDataReactiveFilter(activeParamFilter.value)
    }

    return {
        isLoaded,
        isLoadedReactive,
        infoFilter,
        technics,
        pagination,
        total,
        fullInfoFilter,
        reactiveFilter,
        activeParamFilter,
        coverageFile,
        technicsFile,
        resetFilter,
        getDefaultFilters,
        applyParamFilter,
        getDataReactiveFilter,
        addParamReactiveFilter,
        getCoverageFile,
        getTechnicsFile,
        getTechnics
    }
})