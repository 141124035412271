<template>
  <div v-if="coverageStore.technics" class="flex gap-4">
    <button @click="coverageTab = true" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">% Покрытия</button>
    <button @click="coverageTab = false" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">Техника</button>
    <div v-if="!coverageTab" class="flex items-center gap-4 ml-32">
      <div>
        <input type="checkbox" id="isCovered" v-model="isCovered"
               class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-blue-600 checked:bg-blue-700 focus:ring-blue-500 focus:ring-2"/>
        <label for="isCovered" class="ml-2 text-sm font-medium text-gray-900">Покрытые</label>
      </div>
      <div>
        <input type="checkbox" id="isNotCovered" v-model="isNotCovered"
               class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-blue-600 checked:bg-blue-700 focus:ring-blue-500 focus:ring-2"/>
        <label for="isNotCovered" class="ml-2 text-sm font-medium text-gray-900">Непокрытые</label>
      </div>
    </div>
  </div>

  <div v-if="coverageTab" class="bg-white rounded-lg overflow-hidden">
    <div class="grid grid-cols-5 bg-gray-200 text-[10px] px-8 py-4">
      <div class="col-span-1"></div>
      <div class="col-span-1">Количество модификаций</div>
      <div class="col-span-1">Количество автомобилей</div>
      <div class="col-span-1">Покрытие</div>
    </div>
    <div v-if="coverageStore.infoFilter.length" class="border border-gray-9">
      <div v-for="item in coverageStore.infoFilter" class="grid grid-cols-5 bg-white text-[10px] px-8 py-4 border-b border-b-gray-2 last:border-none !text-sm">
        <div class="col-span-1 flex items-center gap-2">
          <div class="w-2 h-2 rounded-full" :style="`background: ${item.color}`"></div>
          <div class="">{{item.name}}</div>
        </div>
        <div class="col-span-1">{{item.types_count}}</div>
        <div class="col-span-1">{{item.count}}</div>
        <div class="col-span-1">{{item?.percentage ? item?.percentage+'%' : '0%'}}</div>
        <div class="col-span-1">
          <svg @click="setActiveCheckbox(item.type, [item.name])" class="w-6 h-6 text-gray-800 dark:text-white cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path d="M13.5 2c-.178 0-.356.013-.492.022l-.074.005a1 1 0 0 0-.934.998V11a1 1 0 0 0 1 1h7.975a1 1 0 0 0 .998-.934l.005-.074A7.04 7.04 0 0 0 22 10.5 8.5 8.5 0 0 0 13.5 2Z"/>
            <path d="M11 6.025a1 1 0 0 0-1.065-.998 8.5 8.5 0 1 0 9.038 9.039A1 1 0 0 0 17.975 13H11V6.025Z"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <button @click="downloadCoverageFile">Скачать файл</button>
    </div>
  </div>

  <div v-if="!coverageTab" class="bg-white rounded-lg overflow-hidden">
    <div class="grid grid-cols-9 bg-gray-200 text-[10px] px-8 py-4">
      <div class="col-span-1">Страна</div>
      <div class="col-span-1">Марка</div>
      <div class="col-span-1">Модель</div>
      <div class="col-span-1">Модификация</div>
      <div class="col-span-1">Год</div>
      <div class="col-span-1">Топливо</div>
      <div class="col-span-1">Мощность</div>
      <div class="col-span-1">Количество</div>
      <div class="col-span-1">Покрыт</div>
    </div>
    <div class="border border-gray-9">
      <div v-for="item in technics" class="grid grid-cols-9 bg-white text-[10px] px-8 py-4 border-b border-b-gray-2 last:border-none !text-sm">
        <div class="col-span-1">{{item.country_name}}</div>
        <div class="col-span-1">{{item.make_name}}</div>
        <div class="col-span-1">{{item.model_name}}</div>
        <div class="col-span-1">{{item.type_name ? item.type_name : '&mdash;'}}</div>
        <div class="col-span-1">{{item.year ? item.year : '&mdash;'}}</div>
        <div class="col-span-1">{{item.fuel_name ? item.fuel_name : '&mdash;'}}</div>
        <div class="col-span-1">{{item.power ? item.power : '&mdash;'}}</div>
        <div class="col-span-1">{{item.count}}</div>
        <div class="col-span-1">{{item.covered ? 'Да' : 'Нет' }}</div>
      </div>
    </div>
    <div class="p-5 flex">
      <button @click="downloadTechnicsFile">Скачать файл</button>
      <div class="flex-col p-5 w-1/2">
        <nav v-if="coverageStore.pagination && coverageStore.pagination.lastPage > 1">
          <ul class="inline-flex -space-x-px">
            <li v-for="(link, index) of coverageStore.pagination.links">
              <a v-if="link.url" href="#"
                 @click.prevent="entityPaginate(link.url)"
                 :class="classInputPagination(index, link.active)"
                 class="px-3 py-2 border border-gray-300">
                <span v-html="link.label"></span>
              </a>
              <a v-else
                 :class="classInputPagination(index, link.active)"
                 class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                <span v-html="link.label"></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {AutoCoverageFilterStore} from "../../../stores/AutoCoverageFilterStore";
import {ref, watch, computed} from "vue";

const coverageStore = AutoCoverageFilterStore()
const isUpdate = computed(() => coverageStore.isLoaded);
const coverageTab = ref(true)
const isCovered = ref(true)
const isNotCovered = ref(true)
const technics = ref([])

watch([isCovered, isNotCovered], () => {
  technics.value = coverageStore.technics

  let params = {
    isCovered: isCovered.value,
    isNotCovered: isNotCovered.value,
  }

  coverageStore.getTechnics(params).then(() => {
    technics.value = coverageStore.technics
  });
})

watch(isUpdate, () => {
    coverageTab.value = true
    isCovered.value = true
    isNotCovered.value = true

    if (coverageStore.technics && coverageStore.technics.length) {
        technics.value = coverageStore.technics
    }
})

const entityPaginate = (url) => {
  if (url) {

    let params = {
      isCovered: isCovered.value,
      isNotCovered: isNotCovered.value,
    }

    coverageStore.getTechnics(params, url).then(() => {
      technics.value = coverageStore.technics
    });
  }
}

const setActiveCheckbox = (code = '', selects = []) => {
    coverageStore.addParamReactiveFilter(code, selects)
    coverageStore.applyParamFilter()
}

const downloadCoverageFile = () => {
    coverageStore.getCoverageFile().then(() => {
        const link = document.createElement('a');
        link.href = coverageStore.coverageFile;
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}

const downloadTechnicsFile = () => {
    coverageStore.getTechnicsFile().then(() => {
        const link = document.createElement('a');
        link.href = coverageStore.technicsFile;
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}

const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === coverageStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});

</script>

<style scoped>

</style>