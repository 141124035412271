import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';
import {useLogStore} from "./LogStore.js";

export const useQvsStore = defineStore("qvStore", () => {
    const qvs = ref([]);
    const qv = ref(null);
    const brands = ref([]);
    const products = ref([]);
    const productOptions = ref([]);
    const qvOptions = ref([]);
    const pagination = ref(null);
    const error = ref(null);
    const isSortUpdated = ref(false);

    const getDirectoriesOptions = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/admin/qvs/directories', config)
            .then((r) => {
                productOptions.value = r.data.data.products;
                qvOptions.value = r.data.data.qvs;
                brands.value = r.data.data.brands;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getQvs = async (params = null, url = '/admin/qvs') => {

        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                qvs.value = r.data.data.qvs;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getProducts = async (params) => {
        let config = {
            params : params,
        }

        await axios.get('/admin/qvs/products', config)
            .then((r) => {
                productOptions.value = r.data.data.products;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getQvsOptions = async (params) => {
        let config = {
            params : params,
        }

        await axios.get('/admin/qvs/options', config)
            .then((r) => {
                qvOptions.value = r.data.data.qvs;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getProductsByQvId = async (id, params) => {
        let config = {
            params : params,
        }

        return await axios.get('/admin/qvs/' + id + '/products', config) .then((r) => {
            products.value = r.data.data.products;
        }).catch((e) => {
            console.log(e);
        });
    }

    const sendSortProducts = async ($id, data) => {
        let params = {
            sort: data
        }

        await axios.post('/admin/qvs/' + $id + '/products/sort', params)
            .then((r) => {
                let response = r.data;
                if (response.success) {
                    isSortUpdated.value = response.data.isSortUpdated
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    return {
        qvs,
        qv,
        error,
        pagination,
        brands,
        products,
        productOptions,
        qvOptions,
        isSortUpdated,
        getQvs,
        getDirectoriesOptions,
        getProducts,
        getQvsOptions,
        getProductsByQvId,
        sendSortProducts
    }
})