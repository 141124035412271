<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-2 gap-7 px-7 pt-7">
      <div class="col-span-1 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200"
               :class="{'bg-edit': productStore.product.id, 'bg-add': !productStore.product.id}">
            <div>
              <h1 class="text-3xl font-medium">Характеристики</h1>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow">
                  <div v-if="productStore.product">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Изображение</label>
                            <template v-if="productStore.product.imageSrc">
                              <div class="relative inline-block bg-white p-4 mb-3">
                                <img :src="productStore.product.imageSrc" width="100" alt="">

                                <button @click.prevent="deleteImage" class="flex text-primary-500 absolute left-0 bottom-0 hover:bg-primary-700">
                                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                  </svg>
                                </button>
                              </div>
                            </template>
                            <drop-file v-model="productStore.product.imageNew"/>
                          </div>
                          <div>
                            <input-text v-model="productStore.product.name" label="Название"/>
                            <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                          </div>
                          <div>
                            <input-select-lite :v$="v$.brand" :options="brandOptions" v-model="productStore.product.brand" label="Бренд"/>
                            <p v-if="v$.brand.$error" class="mt-2 text-sm text-red-600"> {{ v$.brand.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text-area v-model="productStore.product.descriptionRu" label="Описание (RUS)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text-area v-model="productStore.product.descriptionEn" label="Описание (ENG)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text-area v-model="productStore.product.hintRu" label="Подсказка (RUS)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text-area v-model="productStore.product.hintEn" label="Подсказка (ENG)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="productStore.product.linkLubeRu" label="Ссылка на описание (RUS)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="productStore.product.linkLubeEn" label="Ссылка на описание (ENG)"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="productStore.product.linkShop" label='Ссылка для кнопки "Купить в интернет-магазине"'/>
                          </div>

                          <div class="sm:col-span-2">
                            <label class="block text-sm font-medium text-gray-900">Особенности продукта</label>
                            <p v-for="(m, i) of featuresList">
                              <button @click.prevent="deleteFeature(i)" class="text-red-400">X</button>
                              {{ m.name }}
                            </p>
                            <input-search
                                v-model="inputSearchFeature"
                                :suggestions="featuresOptions"
                                @keyup="searchFeatures()"
                                @selected="handlerSelectedFeatures">
                            </input-search>
                          </div>
                        </div>
                        <div v-if="productStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ productStore.error }}
                        </div>
                      </form>
                    </div>
                    <div class="flex justify-between">
                      <div v-if="route.params.id > 0" class="p-7 flex items-center space-x-4">
                        <router-link :to="{name: 'Marketings', query: {id: productStore.product.id, brand_id: productStore.product.brand}}"
                                     class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                          Маркет. позиц.
                        </router-link>
                        <router-link :to="{name: 'Qvs', query: {id: productStore.product.id, brand_id: productStore.product.brand}}"
                                     class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                          Технич. позиц.
                        </router-link>
                      </div>
                      <div v-else class="p-7 flex items-center space-x-4"></div>
                      <div class="p-7 flex justify-end">
                        <button @click.prevent="saveProduct" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                          <span>Сохранить</span>
                        </button>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="qualities" class="col-span-1 mb-4 xl:mb-2" v-if="productStore.product && productStore.product.id">
        <div class="mb-5 bg-white rounded-lg border border-gray-200">
          <div class="flex justify-between items-center p-7">
            <div>
              <h1 class="text-3xl font-medium">Спецификации</h1>
            </div>
            <div class="flex-shrink-0">
              <button @click.prevent="showAddQuality" type="button" class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1   border-blue-700 hover:bg-gray-100">&plus; Добавить спецификацию</button>
            </div>
          </div>

          <div v-if="productStore.product.qualities.length" class="border-t border-gray-200">
            <div class="overflow-auto max-h-[32rem] flex flex-col">
              <table class="min-w-full divide-y divide-gray-200 table-fixed">
                <thead class="bg-gray-100 sticky top-0">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input v-model="checkboxAllQualitiesSelected"
                               @click="qualitySelectAllCheckbox"
                               id="checkbox-all" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Название
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                </thead>
                <tbody class=" bg-white divide-y divide-gray-200">
                  <tr v-for="qualityItem of productStore.product.qualities" :key="qualityItem.id" class="transition-colors duration-4000 hover:bg-gray-100" :class="{'text-green-400': qualityItem.qv.quality.id === productStore.qualityAddId}">
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        <input v-model="qualityIds" :value="qualityItem.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="p-4">
                        <span>{{ qualityItem.qv.name }}</span>
                    </td>
                    <td class="text-right pr-2">
                      <router-link :to="{name: 'Specification', params: {id: qualityItem.qv.quality.id}}" class="py-2 px-3 text-sm font-medium text-center inline-block">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex w-full justify-between border-t">
              <div class="flex-col py-7 px-4">
                <template v-if="qualityIds.length">
                  <div>
                    <button @click.prevent="deleteQualities" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                      <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                      <span>Удалить</span>
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <span class="text-gray-500">Привязано</span> {{ productStore.product.qualities.length }}
                    <span class="ml-24 text-green-400 text-sm" v-if="isShowAddQualityMessage">Спецификация добавлена в общий список</span>
                    <span class="ml-24 text-red-400 text-sm" v-if="isShowDeleteQualityMessage">Спецификация удалена из общего списка</span>
                    <span class="ml-24 text-green-400 text-sm" v-if="isShowAddQualitiesMessageCreated">Спецификация создана и добавлена в список</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div id="add-quality" class="mb-5 bg-white rounded-lg border border-gray-200" v-show="isShowAddQualities">
          <div class="flex justify-between items-center p-7 relative">
            <div>
              <h1 class="text-3xl font-medium">Добавить спецификацию</h1>
            </div>

            <button type="button" @click.prevent="isShowAddQualities = false" class="bg-white rounded-md p-2 inline-flex items-center justify-center hover:text-gray-400 text-gray-500 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="sr-only">Close menu</span>
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div class="px-7 mb-3">
            <form @submit.prevent="searchQualities()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">Поиск</label>
              <div class="relative w-100">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="searchQuality"
                       @input="searchQualitiesTimeout"
                       type="text" id="top-bar-search"
                       class="border border-gray-200 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                       placeholder="Поиск по названию">
              </div>
            </form>
          </div>

          <div class="px-7 overflow-auto max-h-[32rem]">
            <div class="flex flex-col">
              <spinner v-if="isQualityLoading"></spinner>
              <table class="min-w-full table-fixed mb-7" v-if="!isQualityLoading">
                <tbody class="bg-white">
                  <tr v-for="qualityItem of productStore.qualities" :key="qualityItem.id" @click="toggleQualities(qualityItem.id)" class="border border-gray-200 cursor-pointer hover:bg-gray-100">
                    <td class="p-4">{{ qualityItem.name }}</td>
                    <td class="text-right pr-2">
                      <input v-model="addQualityIds" :value="qualityItem.id" aria-describedby="checkbox-1" type="checkbox"
                             class="opacity-0 overflow-hidden absolute w-px h-pxopacity-0 overflow-hidden absolute w-px h-px">

                      <div class="py-2 px-3 text-sm font-medium text-center inline-block">
                        <template v-if="addQualityIds.includes(qualityItem.id)">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.38807 12L11.3881 14L15.3881 10M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#31C48D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </template>
                        <template v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex w-full justify-between border-t border-b border-gray-200">
            <div class="flex-col p-7 w-1/2">
              <div>
                <span class="text-gray-500">Добавлено</span> {{ addQualityIds.length }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <modal v-if="showAddQualityDialog" @closeDialog="closeDialog" :load-data="loadDataInModal">
    <template #title>Создать новую спецификацию</template>
    <template #body>
      <spinner v-if="loadData"></spinner>
      <form v-if="!loadData && productStore.quality" action="#">
        <div class="grid gap-4 mb-4 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <input-text :v$="vQuality$.title" v-model="productStore.quality.title" label="Название" :placeholder="'Введите название спецификации'"/>
          </div>
          <div class="sm:col-span-2">
            <input-select-lite :v$="vQuality$.group" :options="qualityGroupOptions" v-model="productStore.quality.group" label="Группа" :placeholder="'Выберите группу'"/>
            <p v-if="vQuality$.group.$error" class="mt-2 text-sm text-red-600"> {{ vQuality$.group.$errors[0].$message }} </p>
          </div>
          <div class="sm:col-span-2">
            <input-select-lite :v$="vQuality$.viscosity" :options="viscosityOptions" v-model="productStore.quality.viscosity" label="Вязкость" :placeholder="'Выберите вязкость'"/>
            <p v-if="vQuality$.viscosity.$error" class="mt-2 text-sm text-red-600"> {{ vQuality$.viscosity.$errors[0].$message }} </p>
          </div>
        </div>

        <div v-if="productStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {{ productStore.error }}
        </div>

        <div class="flex justify-end border-t pt-8">
          <button @click.prevent="saveQuality" type="submit" class="flex px-8 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
            Сохранить
          </button>
        </div>
      </form>
    </template>
  </modal>

  <modal v-if="showAddViscosityDialog" @closeDialog="closeDialog" :load-data="loadDataInModalViscosity">
    <template #title>Создать новую вязкость</template>
    <template #body>
      <spinner v-if="loadData"></spinner>
      <form v-if="!loadData && productStore.viscosityModel" action="#">
        <div class="grid gap-4 mb-4 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <input-text :v$="vViscosity$.title" v-model="productStore.viscosityModel.title" label="Название" :placeholder="'Введите название вязкости'"/>
          </div>
        </div>

        <div v-if="productStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {{ productStore.error }}
        </div>

        <div class="flex justify-end border-t pt-8">
          <button @click.prevent="saveViscosity" type="submit" class="flex px-8 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
            Сохранить
          </button>
        </div>
      </form>
    </template>
  </modal>
</template>

<script setup>
import {useProductStore} from "../../stores/ProductStore.js";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch} from "vue";
import Modal from "../flowbite/modal.vue";
import InputText from "../flowbite/form/inputText.vue";
import InputTextArea from "../flowbite/form/inputTextArea.vue";
import DropFile from "../flowbite/form/dropFile.vue";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {required} from '../../utilits/custom-validators.js'
import {scrollToElement} from '../../utilits/functions.js'
import InputSearch from "../flowbite/form/inputSearch.vue";

const productStore = useProductStore();
productStore.error = null;

const loadData = ref(true);
const isQualityLoading = ref(false);

const showAddViscosityDialog = ref(false);
const loadDataInModalViscosity = ref(false);

const showAddQualityDialog = ref(false);
const loadDataInModal = ref(false);

const isShowAddQualities = ref(false);
const isShowAddQualityMessage = ref(false);
const isShowDeleteQualityMessage = ref(false);
const isShowAddQualitiesMessageCreated = ref(false);

const addQualityIds = ref([]);

const qualityIds = ref([]);
const checkboxAllQualitiesSelected = ref(false);
const inputSearchFeature = ref('');

const inputTextViscosity = ref('');

const searchQuality = ref('');

// Первоначальные данные
const route = useRoute()
const router = useRouter()

const brandOptions = ref([]);
const viscosityOptions = ref([]);
const viscosityOptionsSearch = ref([]);
const qualityGroupOptions = ref([]);

const featuresList = ref([]);
const featuresOptions = ref([]);
const searchSuggestFeatures = ref([]);

productStore.getDirectories().then(() => {
  brandOptions.value = [...brandOptions.value, ...productStore.brands];
  featuresOptions.value = [...featuresOptions.value, ...productStore.features];
  viscosityOptions.value = [...viscosityOptions.value, ...productStore.viscosity];
  viscosityOptionsSearch.value = viscosityOptions.value;
  qualityGroupOptions.value = [...qualityGroupOptions.value, ...productStore.qualityGroups];

  if (route.params.id > 0) {
    productStore.getProductById(route.params.id).then(() => {
      loadData.value = false

      productStore.product.features.forEach((feature) => {
        featuresList.value.push({
          id: feature.id,
          name: feature.name
        })
      })

      viscosityOptions.value.forEach((viscosityOption) => {
        if (productStore.product.viscosity === viscosityOption.id) {
          inputTextViscosity.value = viscosityOption.name
        }
      })
    })
  } else {
    productStore.setEmptyProduct().then(() => {
      loadData.value = false;
    })
  }
});

// Галочка выбрать всё
const qualitySelectAllCheckbox = () => {
  qualityIds.value = [];

  if (!checkboxAllQualitiesSelected.value) {
    productStore.product.qualities.forEach((quality, index) => {
      qualityIds.value.push(quality.id)
    });
  }
}

// Валидация
const rules = computed(() => ({
  name: {required},
  brand: {required},
}));

let state = reactive({
  name: '',
  brand: '',
  linkLubeRu: '',
  linkLubeEn: '',
  linkShop: '',
  priority: '',
});

watch(productStore, (productStore) => {
  if (productStore.product) {
    state.name = productStore.product.name;
    state.brand = productStore.product.brand;
    state.linkLubeRu = productStore.product.linkLubeRu;
    state.linkLubeEn = productStore.product.linkLubeEn;
    state.linkShop = productStore.product.linkShop;
    state.priority = productStore.product.priority;
  }
});

const v$ = useVuelidate(rules, state)

const rulesQuality = computed(() => ({
  title: {required},
  group: {required},
  viscosity: {required},
}));

let stateQuality = reactive({
  title: '',
  group: '',
  viscosity: '',
});

watch(productStore, (productStore) => {
  if (productStore.quality) {
    stateQuality.title = productStore.quality.title;
    stateQuality.group = productStore.quality.group;
    stateQuality.viscosity = productStore.quality.viscosity;
  }
});

const vQuality$ = useVuelidate(rulesQuality, stateQuality)

const rulesViscosity = computed(() => ({
  title: {required},
}));

let stateViscosity = reactive({
  title: '',
});

watch(productStore, (productStore) => {
  if (productStore.viscosityModel) {
    stateViscosity.title = productStore.viscosityModel.title;
  }
});

const vViscosity$ = useVuelidate(rulesViscosity, stateViscosity)

// Работа с данными
let timeoutViscosity = null;
watch(inputTextViscosity, (text) => {
  if (text) {
    viscosityOptionsSearch.value = viscosityOptions.value.filter((viscosityOption) => {
      return viscosityOption.name.toLowerCase().includes(text.toLowerCase())
    })
  } else {
    viscosityOptionsSearch.value = viscosityOptions.value
  }
})

const handlerSelectedViscosity = (id) => {
  stateQuality.viscosity = id
}

const saveProduct = () => {
  productStore.error = null;

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true;

    let formData = new FormData();

    productStore.product.features = []
    for (let key in featuresList.value) {
      productStore.product.features.push(featuresList.value[key].id)
    }

    for (let key in productStore.product) {
      formData.append(key, productStore.product[key]);
    }

    let isNew = productStore.product.id === undefined;

    productStore.storeProduct(formData).then(() => {
      if (!productStore.error && isNew) {
        router.push({name: 'Product', params: {id: productStore.product.id}})
      }

      inputSearchFeature.value = '';
      loadData.value = false;
    });

    v$.value.$reset();
  }
}

const deleteImage = () => {
  productStore.product.imageSrc = ''
}

const deleteQualities = () => {
  productStore.deleteQualities(productStore.product, qualityIds.value).then(() => {
    qualityIds.value = [];
    isShowAddQualityMessage.value = false
    isShowDeleteQualityMessage.value = true
  })
}

const toggleQualities = (id) => {
  productStore.toggleQualities(productStore.product, id).then(() => {

    if (addQualityIds.value.includes(id)) {
        addQualityIds.value = addQualityIds.value.filter(item => (item !== id))
        isShowAddQualityMessage.value = false
        isShowDeleteQualityMessage.value = true
    } else {
        addQualityIds.value.push(id)
        isShowAddQualityMessage.value = true
        isShowDeleteQualityMessage.value = false
    }

      qualityIds.value = [];

  })
}

let timeoutQV = null;
const searchQualitiesTimeout = (e) => {
    clearTimeout(timeoutQV);

    timeoutQV = setTimeout(() => {
        searchQualities()
    }, 800);
};

const searchQualities = () => {
  isQualityLoading.value = true;

  let params = {
    product_id: productStore.product.id,
    search: searchQuality.value,
  }

  productStore.getQualities(params).then(() => {

    isQualityLoading.value = false;
    addQualityIds.value.length = 0;

    productStore.qualities.forEach((item, index) => {
        if (item.isLinked) {
            addQualityIds.value.push(item.id);
        }
    })

  });
}

const createViscosity = () => {
  showAddViscosityDialog.value = true;
  productStore.setEmptyViscosity();
}

const saveViscosity = () => {
  productStore.error = null;

  vViscosity$.value.$validate()

  if (!vViscosity$.value.$error) {
    loadDataInModalViscosity.value = true;

    productStore.storeViscosity(productStore.product.id, productStore.viscosityModel)
        .then(() => {
          loadDataInModalViscosity.value = false;

          if (!productStore.error) {
            inputTextViscosity.value = productStore.viscosityModel.title;
            showAddViscosityDialog.value = false;
          }
        });

    vQuality$.value.$reset();
  }
}

const saveQuality = () => {
  productStore.error = null;

  vQuality$.value.$validate()

  if (!vQuality$.value.$error) {
    loadDataInModal.value = true;
    productStore.storeQuality(productStore.product.id, productStore.quality)
        .then(() => {
          loadDataInModal.value = false;

          if (!productStore.error) {
            showAddQualityDialog.value = false;
            isShowAddQualitiesMessageCreated.value = true;

            scrollToElement('qualities');
          }
        });

    vQuality$.value.$reset();
  }
}

const showAddQuality = () => {
  isShowAddQualities.value = true;

  setTimeout(() => {
    scrollToElement('add-quality')
  }, 100)
}

const deleteFeature = (index) => {
  featuresList.value.splice(index, 1)
}

const searchFeatures = () => {
  searchSuggestFeatures.value = []

  searchSuggestFeatures.value.forEach((feature) => {
    if (feature.name.toLowerCase().indexOf(inputSearchFeature.value.toLowerCase()) !== -1) {
      searchSuggestFeatures.value.push(feature);
    }
  })
}

const handlerSelectedFeatures = (id, name) => {
  var found = featuresList.value.find(el => el.id === id)

  if (!found) {
    featuresList.value.push({
      id: id,
      name: name
    })
  }
}

watch(featuresList.value, (el) => {
  productStore.product.features = [];

  el.forEach(function (item) {
    productStore.product.features.push(item.id)
  });
});

// Модальное окно
const closeDialog = () => {
  showAddViscosityDialog.value = false;
  vViscosity$.value.$reset();
  productStore.resetViscosity();

  showAddQualityDialog.value = false;
  vQuality$.value.$reset();
  productStore.resetQuality();

  productStore.error = null;
}
</script>