<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">Продукты</h1>
          </div>
          <div class="flex-shrink-0">
            <router-link :to="{name: 'Product', params: {id: 0}}" class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1   border-blue-700 hover:bg-gray-100">&plus; Добавить продукт</router-link>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-1/2">
            <form @submit.prevent="searchProduct()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">Поиск</label>
              <div class="relative mt-1 lg:w-96">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="search"
                       type="text" id="top-bar-search"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
                       placeholder="Поиск по названию">
              </div>
            </form>
          </div>
          <div class="flex-col p-7 w-1/2">
            <div class="flex w-full">
                <div class="flex-col w-1/2 mr-4">
                  <input-search
                          v-model="qv"
                          :suggestions="productStore.qvOptions"
                          @selected="handlerSelectedQv"
                          @keyup="searchQV"
                          placeholder="Спецификация"/>
                </div>
                <div class="flex-col w-1/2 mr-4">
                  <input-select-lite v-model="active" :options="activeOptions" :placeholder="'Активность'"/>
                </div>
                <div class="flex-col w-1/2">
                  <input-select-lite v-model="brand" :options="brandOptions" :placeholder="'Бренд'"/>
                </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input v-model="checkboxAllProductsSelected"
                               @click="selectAllCheckbox"
                               id="checkbox-all" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Изображение
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Наименование/Бренд
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Кол-во спецификаций
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Ссылка на описание
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      Ссылка на инт.-маг.
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="product of productStore.products" :key="product.id" :class="{'bg-neutral-100 opacity-50': product.isDeleted}" class="hover:bg-gray-100">
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        <input v-model="productIds" :value="product.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="p-4">
                      <img v-if="product.imageSrc" :src="product.imageSrc" width="74" alt="">
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ product.name }}</p>
                      <p class="text-sm text-gray-500">{{ product.brand }}</p>
                    </td>
                    <td class="p-4">{{ product.qualitiesCount }}</td>
                    <td class="p-4">{{ product.linkLubeRu ? 'есть' : 'нет' }}</td>
                    <td class="p-4">{{ product.linkShop ? 'есть' : 'нет' }}</td>
                    <td class="text-right">
                      <router-link :to="{name: 'Product', params: {id: product.id}}" class="py-2 px-3 text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !productStore.products.length" class="text-xl text-center p-7">Не найдено продуктов</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <template v-if="productIds.length">
              <div>
                <button @click.prevent="deleteProducts" v-if="!isRestoreProduct" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>Удалить</span>
                </button>
                <button @click.prevent="restoreProducts" v-if="isRestoreProduct" class="flex px-6 py-4 bg-blue-500 rounded-md text-white hover:bg-blue-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>Восстановить</span>
                </button>
              </div>
            </template>
            <template v-else>
              <div v-if="productStore.pagination">
                <span class="text-gray-500">Найдено</span> {{ productStore.pagination.total }}
              </div>
            </template>
          </div>
          <div class="flex-col p-7 w-1/2 text-right">
            <nav v-if="productStore.pagination && productStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of productStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="productsPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useProductStore} from "../../stores/ProductStore.js";
import Spinner from "../flowbite/spinner.vue";
import {ref, watch} from "vue";
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import InputSearch from "../flowbite/form/inputSearch.vue";
import {useRoute, useRouter} from 'vue-router'

const productStore = useProductStore();
const isRestoreProduct = ref(false);
const loadData = ref(true);
const checkboxAllProductsSelected = ref(false);
const brand = ref('');
const qv = ref('');
const qvId = ref('');
const active = ref('');
const page = ref('');
const productIds = ref([]);
const search = ref("");
const brandOptions = ref([]);
const activeOptions = ref([
  {id: 1, name: 'Активные'},
  {id: 0, name: 'Неактивные'}
]);

const route = useRoute()
const router = useRouter()

// Первоначальные данные
productStore.getDirectories().then(() => {
  brandOptions.value = [...brandOptions.value, ...productStore.brands];

  if (route.query.qvId) {
    qvId.value = route.query.qvId
    qv.value = productStore.qvOptions.find(elem => elem.id == qvId.value).name
  }

  page.value = route.query.page;
  search.value = route.query.search;
  active.value = route.query.active;
  brand.value = route.query.brand;

  watch([brand, active], () => {
    page.value = null;
    getProducts()
  })

  getProducts();
});

const getProducts = (url) => {
    loadData.value = true;

    let params = {
        search: search.value,
        page: page.value,
        filter: {
            active: active.value,
            brand: brand.value,
            qv_id: qvId.value,
        }
    }

    productStore.getProducts(params, url).then(() => {
        loadData.value = false;
        router.push({query: {
            page: productStore.pagination.currentPage,
            search: search.value,
            qvId: qvId.value,
            active: active.value,
            brand: brand.value
          }});
    });
}


// Галочка выбрать всё
const selectAllCheckbox = () => {
  productIds.value = [];

  if (!checkboxAllProductsSelected.value) {
    productStore.products.forEach((product, index) => {
      productIds.value.push(product.id)
    });
  }
}

// Работа с данными
const productsPaginate = (url) => {
  if (url) {
    page.value = null;
    getProducts(url);
  }
}

const deleteProducts = () => {
  productStore.deleteProducts(productIds.value).then(() => {
    productIds.value = [];
  })
}

const restoreProducts = () => {
  productStore.restoreProducts(productIds.value).then(() => {
    productIds.value = [];
  })
}

const searchProduct = () => {
  page.value = null;
  getProducts();
}

watch(qv, () => {
    if (qv.value == '') {
        qvId.value = '';
        getProducts();
    }
})

watch(productIds, () => {
  isRestoreProduct.value = false

  let ids = []

  productIds.value.forEach((productId) => {
    ids.push(productId)
  })

  productStore.products.forEach((product) => {
    if (ids.indexOf(product.id) !== -1 && product.isDeleted) {
      isRestoreProduct.value = true
    }
  })
})

const handlerSelectedQv = (id) => {
    qvId.value = id;
    getProducts();
}

let timeoutQV = null;
const searchQV = (text) => {
    clearTimeout(timeoutQV);

    let params = {
        search: text,
    }

    timeoutQV = setTimeout(() => {
        productStore.getQvsOptions(params)
    }, 400);
};

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === productStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>