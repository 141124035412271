<template>
  <div class="w-full p-6 border-b border-gray-2 text-xl text-black font-medium">
    <slot/>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>