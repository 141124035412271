<template>
  <div class="px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="mb-4 bg-gray-50 border border-gray-300 rounded-lg flex flex-col">
        <div class="h-[78vh]">
          <!-- Header -->
          <div class="flex justify-between items-center mb-4 p-2 border-b border-gray-300"
               :class="{'bg-edit': id, 'bg-add': !id}">
            <div>
              <h3 class="mb-2 text-xl font-bold text-gray-900"><span v-if="!id">Добавить</span><span v-else>Редактировать</span> модификацию</h3>
            </div>
          </div>
          <div class="p-2 h-full">
            <div class="">
              <input-select-default @change="searchBrands(); getManufacturers();" v-model="selectedCategory" :options="categories" label="Категория"/>
            </div>
            <div class="">
              <input-search
                  :v$="v$.inputTextBrand"
                  :disabled="!selectedCategory"
                  v-model="basicInput.inputTextBrand"
                  :suggestions="carBrands"
                  @keyup="searchBrands()"
                  :load="loadBrands"
                  :is-open-other="brandsFocus"
                  @click="() => {modelsFocus = false; brandsFocus = true}"
                  btn-new-label=""
                  @selected="handlerSelectedCarBrand"
                  :isShowSuggestionsOnClick="true"
                  label="Марка">
                <template v-if="authStore.hasRole('technic')" v-slot:addButton>
                  <a href="#" @click.prevent="createBrand" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">&plus;Новая марка</a>
                </template>
              </input-search>
            </div>

            <div class="grid gap-4 sm:grid-cols-2">
              <div>
                <input-search
                    :v$="v$.inputTextModel"
                    v-model="basicInput.inputTextModel"
                    :suggestions="models" btn-new-label=""
                    @selected="handlerSelectedModel"
                    :load="loadModels"
                    :is-open-other="modelsFocus"
                    @click="() => {brandsFocus = false; modelsFocus = true}"
                    @keyup="searchModel"
                    label="Модель"
                    :isShowSuggestionsOnClick="true"
                    :disabled="!basicInput.inputTextBrand">
                  <template v-if="authStore.hasRole('technic')" v-slot:addButton>
                    <a href="#" @click.prevent="createModel" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">&plus;Новая модель</a>
                  </template>
                </input-search>
              </div>

              <div>
                <div class="block mb-2 text-sm font-medium text-gray-900">Год выпуска модели с...по</div>
                <div class="grid gap-4 mb-4 sm:grid-cols-2">
                  <div>
                    <input-text v-model="inputModelYearStart" :year-input="true" disabled/>
                  </div>
                  <div>
                    <input-text v-model="inputModelYearEnd" :year-input="true" disabled placeholder="н.в."/>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid gap-4 sm:grid-cols-2 mb-2">
              <div class="mb-4">
                <div>
                  <input-text :v$="v$.inputType" v-model="basicInput.inputType" label="Название модификации (RUS)"/>
                </div>
                <div class="">
                    <div>
                        <input-text v-model="basicInput.inputTypeEng" label="Название модификации (ENG)" :disabled="matchRus"/>
                    </div>
                    <div>
                        <input id="matchRus" v-model="matchRus" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                        <label class="text-sm" for="matchRus"> Совпадает с русским</label>
                    </div>
                </div>

              </div>
              <div>
                <div class="block mb-2 text-sm font-medium text-gray-900">Год выпуска модификации с...по</div>
                <div class="grid gap-4 mb-4 sm:grid-cols-2">
                  <div>
                    <input-text v-model="inputYearStart" :year-input="true"/>
                  </div>
                  <div>
                    <input-text v-model="inputYearEnd" :disabled="toPresentTime" :year-input="true" placeholder="н.в."/>
                    <input id="toPresentTime" v-model="toPresentTime" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                    <label for="toPresentTime"> по настоящее время</label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <input-select-default v-model="driveType" :options="driveTypeOptions" label="Тип привода"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="authStore.hasRole('technic')" class="flex justify-end items-center p-2 border-t border-gray-300">
          <div>
            <button @click.prevent="save" type="submit"
                    class="text-white bg-blue-600 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Сохранить
              <spinner-mini v-if="loaded"></spinner-mini>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal v-if="showCreateBrandDialog" @closeDialog="closeDialogBrand" :load-data="loadDataBrand">
    <template #title>Создать новую марку</template>
    <template #body>
      <form v-if="!loadDataBrand" action="#">

        <div class="grid gap-4 mb-4 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <label for="file" class="block mb-2 text-sm font-medium text-gray-900">Логотип</label>
            <drop-file v-model="brandStore.entity.imageNew"/>
          </div>
          <div class="sm:col-span-2">
            <h3 v-if="manufacturesList.length" class="text-lg font-semibold">Заводы</h3>
            <p v-for="(m, i) of manufacturesList">
              <button @click.prevent="deleteManufacture(i)" class="text-red-400">X</button>
              {{ m.name }}
            </p>
          </div>
          <div class="sm:col-span-2">
            <input-search
              :v$="vBrand$.manufacturers"
              v-model="inputTextManufacture"
              :suggestions="searchSuggestManufacturers"
              @keyup="searchManufacturers()"
              @selected="handlerSelectedManufacturers"
              :clearAfterSelected=true
              label="Найдите и выберите завод">
            </input-search>
          </div>

          <div class="sm:col-span-2">
            <input-text :v$="vBrand$.name" v-model="brandStore.entity.name" label="Название"/>
          </div>
          <div v-if="brandError" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg sm:col-span-2" role="alert">
            {{ brandError }}
          </div>
        </div>


        <div class="flex justify-end border-t pt-8">
          <button @click.prevent="saveBrand" type="submit" class="flex px-8 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
            Сохранить
          </button>
        </div>
      </form>
    </template>
  </modal>
  <modal v-if="showCreateModelDialog" @closeDialog="closeDialogModel" :load-data="loadDataModel">
    <template #title>Создать новую модель</template>
    <template #body>
      <form v-if="!loadDataModel" action="#">

        <div class="grid gap-4 mb-4 sm:grid-cols-2">

          <input type="hidden" v-model="modelStore.entity.make">
          <div class="sm:col-span-2">
            <input-text :v$="vModel$.pure_name" v-model="modelStore.entity.pure_name" label="Название"/>
          </div>
          <div class="sm:col-span-2">
            <input-text v-model="modelStore.entity.code" label="Код кузова"/>
          </div>
          <div v-if="modelError" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg sm:col-span-2" role="alert">
            {{ modelError }}
          </div>
        </div>


        <div class="flex justify-end border-t pt-8">
          <button @click.prevent="saveModel" type="submit" class="flex px-8 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
            Сохранить
          </button>
        </div>
      </form>
    </template>
  </modal>
</template>

<script setup>
import {computed, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from 'vue-router'
import {useTechnicsStore} from "../../stores/TechnicsStore.js";
import InputSelectDefault from "../flowbite/form/inputSelectDefault.vue";
import InputSearch from "../flowbite/form/inputSearch.vue";
import InputText from "../flowbite/form/inputText.vue";
import SpinnerMini from "../flowbite/spinnerMini.vue";
import DropFile from "../flowbite/form/dropFile.vue";
import Modal from "../flowbite/modal.vue";
import {required} from '../../utilits/custom-validators.js'
import useVuelidate from "@vuelidate/core";
import {useMakeStore} from "../../stores/MakeStore.js";
import {useModelStore} from "../../stores/ModelStore.js";
import {useNewsStore} from "../../stores/NewsStore.js";
import {useAuthStore} from "../../stores/AuthStore.js";

const route = useRoute();
const router = useRouter()
const authStore = useAuthStore();
const technicsStore = useTechnicsStore();
const newsStore = useNewsStore();
const brandStore = useMakeStore();
const modelStore = useModelStore();
let loaded = ref(false);

let toPresentTime = ref(false);
let matchRus = ref(false);

let modelsFocus = ref(false),
    brandsFocus = ref(false)

//ID Модификации
let id = ref(null);

//Категории
let selectedCategory = ref(null);
const categories = ref([]);

//Марки
let selectedCarBrand = ref(null);
let carBrands = ref([]);

//Модели
let selectedModel = ref(null);
let models = ref([]);

//Модификации
let basicInput = ref({
  inputType: '',
  inputTypeEng: '',
  inputTextModel: '',
  inputTextBrand: ''
});

// Loader'ы
let loadModels = ref(false),
    loadBrands = ref(false)

let inputYearStart = ref('');
let inputYearEnd = ref('');
let inputModelYearStart = ref('');
let inputModelYearEnd = ref('');
let driveType = ref(null);
let driveTypeOptions = ref([]);

//Получаем категории
technicsStore.getCategories().then((r) => {
  categories.value = r.data.data;
})
technicsStore.getCatalogItems('drive_type', '').then((r) => {
  driveTypeOptions.value = r.data.data;
})

//Получаем заводы
const getManufacturers = () => {
  basicInput.value.inputTextBrand = ''
  basicInput.value.inputTextModel = ''
  technicsStore.getManufacturer(selectedCategory.value).then((r) => {
    suggestManufacturers.value = r.data.data;
    searchSuggestManufacturers.value = r.data.data;
  })
}

//Получаем брэнды
let timeoutBrand = null;

const searchBrands = () => {
  loadBrands.value = true
  clearTimeout(timeoutBrand);
  timeoutBrand = setTimeout(() => {
    if (selectedCategory.value) {
      technicsStore.getCarBrands(selectedCategory.value, basicInput.value.inputTextBrand).then((r) => {
        carBrands.value = r.data.data;
        loadBrands.value = false
      })
    }
  }, 800);
}

const handlerSelectedCarBrand = (id) => {
  selectedCarBrand.value = id;
  basicInput.value.inputTextModel = ''
  searchModel('');
}

//Получаем модели
let timeoutModel = null;


const searchModel = (text) => {
  clearTimeout(timeoutModel);
  loadModels.value = true
  timeoutModel = setTimeout(() => {
    if (selectedCarBrand.value) {
      technicsStore.getModelsByMake(selectedCarBrand.value, text).then((r) => {
        models.value = r.data.data;
        loadModels.value = false
      })
    }
  }, 800);
}

const handlerSelectedModel = (id, name, item) => {
  const selectedItem = {...item}

  selectedModel.value = id;
  inputModelYearStart = selectedItem.year_start;
  inputModelYearEnd = selectedItem.year_end;
}

//Сохраняем
const save = () => {

  v$.value.$validate()

  if (!v$.value.$error) {
    loaded.value = true;

    let data = {
      id: id.value,
      category_id: selectedCategory.value,
      name: basicInput.value.inputType,
      name_eng: basicInput.value.inputTypeEng,
      model_id: selectedModel.value,
      year_start: inputYearStart.value,
      year_end: inputYearEnd.value,
      drive_type: driveType.value,
    };

    technicsStore.storeType(data).then((r) => {
      id.value = r.data.data.id
      technicsStore.currentTechnic = r.data.data;
      technicsStore.currentTechnic.name = basicInput.value.inputType;
      newsStore.getNewsCount();
      loaded.value = false;
      router.push({name: 'Dashboard', query: {search: route.query.search, page: route.query.page}});
    })

  }
}

//Данные для редактирования

if (route.params.id) {
  technicsStore.getTypeById(route.params.id).then((r) => {
    let data = r.data.data;

    id.value = data.id
    basicInput.value.inputType = data.name;
    basicInput.value.inputTypeEng = data.name_eng;
    inputYearStart.value = data.year_start;
    inputYearEnd.value = data.year_end;
    inputModelYearStart.value = data.model.year_start;
    inputModelYearEnd.value = data.model.year_end;

    if (data.name === data.name_eng) {
        matchRus.value = true;
    }

    if (!data.year_end) {
      toPresentTime.value = true;
    }

    selectedCarBrand.value = data.make.id;
    basicInput.value.inputTextBrand = data.make.name;

    selectedModel.value = data.model.id;
    basicInput.value.inputTextModel = data.model.pure_name;

    selectedCategory.value = data.category_id;
    driveType.value = data.drive_type;
  })
}

watch(toPresentTime, (pt) => {
  if (!pt.value) {
    inputYearEnd.value = '';
  }
});

watch(matchRus, (chechbox) => {
  if (chechbox) {
      basicInput.value.inputTypeEng = basicInput.value.inputType;
  }
});

//Валидация
const rules = computed(() => ({
  inputType: {required},
  inputTextModel: {required},
  inputTextBrand: {required},
}));

const v$ = useVuelidate(rules, basicInput)

//Создание марки
let manufacturesList = ref([]);

const inputTextManufacture = ref(null);

let suggestManufacturers = ref([]);
let searchSuggestManufacturers = ref([]);

const searchManufacturers = () => {
  searchSuggestManufacturers.value = []

  suggestManufacturers.value.forEach((manufacturer) => {
    if (manufacturer.name.toLowerCase().indexOf(inputTextManufacture.value.toLowerCase()) !== -1) {
      searchSuggestManufacturers.value.push(manufacturer);
    }
  })
};

const handlerSelectedManufacturers = (id, name) => {
  manufacturesList.value.push({
    id: id,
    name: name
  })
};

const deleteManufacture = (index) => {
  manufacturesList.value.splice(index, 1);
};

let brandError = ref('')
const rulesBrand = computed(() => ({
  manufacturers: {required},
  name: {required},
}));

let stateBrand = reactive({
  manufacturers: '',
  name: '',
});

watch(manufacturesList.value, (ml) => {
  stateBrand.manufacturers = [];
  brandStore.entity.manufacturers = [];

  ml.forEach(function (item) {
    brandStore.entity.manufacturers.push(item.id)
    stateBrand.manufacturers.push(item.id)
  });
});

watch(brandStore, (brandStore) => {
  if (brandStore.entity) {
    stateBrand.name = brandStore.entity.name;
  }
});

const vBrand$ = useVuelidate(rulesBrand, stateBrand)

let showCreateBrandDialog = ref(false);
let loadDataBrand = ref(true);

const createBrand = () => {
  showCreateBrandDialog.value = true;

  brandStore.setEmptyEntity().then(() => {
    loadDataBrand.value = false;
  })
}

const saveBrand = () => {
  brandError.value = '';
  vBrand$.value.$validate()
  if (!vBrand$.value.$error) {
    loadDataBrand.value = true;

    let formData = new FormData();

    for (let key in brandStore.entity) {

      console.log(key, brandStore.entity[key]);

      if (brandStore.entity[key] instanceof Array) {
        brandStore.entity[key].forEach(function (value) {

          formData.append(key + '[]', value);
        })
      } else {
        formData.append(key, brandStore.entity[key]);
      }

    }

    brandStore.storeEntitySimple(formData).then((r) => {

      if (r.data.success) {
        let brand = r.data.data;
        basicInput.value.inputTextBrand = brand.name;
        selectedCarBrand.value = brand.id;
        showCreateBrandDialog.value = false;
        carBrands.value = [];
      } else {
        brandError.value = r.data.error.message
      }

      loadDataBrand.value = false;
    });

    vBrand$.value.$reset();
  }
}

const closeDialogBrand = () => {
  showCreateBrandDialog.value = false;
  manufacturesList.value = [];
}

// Создание модели
let modelError = ref('');

const rulesModel = computed(() => ({
  pure_name: {required},
  make: {required},
}));

let stateModel = reactive({
  pure_name: '',
  make: '',
  code: '',
  year_start: '',
  year_end: '',
});

watch(modelStore, (modelStore) => {
  if (modelStore.entity) {
    stateModel.pure_name = modelStore.entity.pure_name;
    stateModel.make = modelStore.entity.make;
    stateModel.code = modelStore.entity.code;
    stateModel.year_start = modelStore.entity.year_start;
    stateModel.year_end = modelStore.entity.year_end;
  }
});

const vModel$ = useVuelidate(rulesModel, stateModel)

let showCreateModelDialog = ref(false);
let loadDataModel = ref(true);

const createModel = () => {
  showCreateModelDialog.value = true;

  modelStore.setEmptyEntity().then(() => {
    modelStore.entity.make = selectedCarBrand.value;
    loadDataModel.value = false;
  })
}


const saveModel = () => {

  vModel$.value.$validate()
  modelError.value = '';

  if (!vModel$.value.$error) {
    loadDataModel.value = true;

    let formData = new FormData();

    for (let key in modelStore.entity) {
      formData.append(key, modelStore.entity[key]);
    }

    modelStore.storeEntitySimple(formData).then((r) => {

      if (r.data.success) {
        let model = r.data.data;

        basicInput.value.inputTextModel = model.name;
        selectedModel.value = model.id;
        showCreateModelDialog.value = false;
        models.value = [];

      } else {
        modelError.value = r.data.error.message
      }

      loadDataModel.value = false;
    });

    vModel$.value.$reset();
  }
}


const closeDialogModel = () => {
  showCreateModelDialog.value = false;
}

</script>

<style scoped></style>